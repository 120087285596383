import React from 'react'

interface AvatarProps {
    seed: string
    size: number
}

const generateColor = (seed: string): string => {
    let hash = 0
    for (let i = 0; i < seed.length; i++) {
        hash = seed.charCodeAt(i) + ((hash << 5) - hash)
    }

    const hue = ((hash % 360) + 360) % 360 // Ensure positive hue value
    const saturation = 70 + (hash % 30) // Vary saturation between 70 and 100
    const lightness = 50 + (hash % 20) // Vary lightness between 50 and 70

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`
}

const generateGradient = (seed: string): string => {
    const color1 = generateColor(seed)
    const color2 = generateColor(seed + 'salt') // Slightly different seed for the second color

    // Calculate the perceived brightness of the colors
    const brightness1 =
        0.299 * parseInt(color1.slice(4, 7)) +
        0.587 * parseInt(color1.slice(9, 12)) +
        0.114 * parseInt(color1.slice(14, 17))

    const brightness2 =
        0.299 * parseInt(color2.slice(4, 7)) +
        0.587 * parseInt(color2.slice(9, 12)) +
        0.114 * parseInt(color2.slice(14, 17))

    const adjustedColor = brightness1 > brightness2 ? color1 : color2
    const otherColor = brightness1 > brightness2 ? color2 : color1

    return `linear-gradient(45deg, ${adjustedColor}, ${otherColor})`
}

const SeededGradientAvatar: React.FC<AvatarProps> = ({ seed, size }) => {
    const gradient = generateGradient(seed)

    return (
        <div
            style={{
                width: size,
                height: size,
                borderRadius: '50%',
                background: gradient,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontWeight: 'bold',
                fontSize: size * 0.4,
            }}
        ></div>
    )
}

export default SeededGradientAvatar
