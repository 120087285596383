import * as React from 'react'
import { ResponsiveLine, Serie } from '@nivo/line'
import { formatLineGraphCount } from './formatGraph'
import * as styles from '../styles'
import * as CommonStyles from '../../common/CommonStyles'
import { Tooltip } from '@material-ui/core'
import { format } from 'date-fns'

const TotalEngagementGraph = ({ data, type = 'page' }) => {
    const userCount = formatLineGraphCount(data)
    return (
        <div className={styles.container} style={{ height: type === 'dash' ? '500px' : '50vh' }}>
            <ResponsiveLine
                data={userCount}
                margin={{
                    top: type === 'dash' ? 10 : 50,
                    right: type === 'dash' ? 30 : 50,
                    bottom: 80,
                    left: 60,
                }}
                enablePoints={false}
                xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day' }}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: false,
                    // reverse: false,
                }}
                axisLeft={{
                    legend: 'Users',
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}
                useMesh
                axisBottom={{
                    tickValues: 6,
                    format: (a) => format(a, type == 'dash' ? 'MM-yy' : 'PP'),
                }}
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: type === 'dash' ? 40 : 0,
                        translateY: type === 'dash' ? 55 : 70,
                        itemWidth: 197,
                        itemHeight: 25,
                        itemsSpacing: type === 'dash' ? -20 : 7,
                        symbolSize: 20,
                        symbolShape: 'circle',
                        itemDirection: 'left-to-right',
                        itemTextColor: '#777',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    )
}

export default TotalEngagementGraph
