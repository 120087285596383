import b from 'bss'
import * as CommonStyles from 'components/common/CommonStyles'

export const right = b`
 float:right
`.class
export const container = b`
`.class
export const modalContainer = b`
    display: flex;
    flex-direction: column;
`.class
export const left = b`
 float:left
`.class
export const activeItem = b`
    padding: 4px 12px; 
    clear:both;
    overflow: auto;
    border: 1px solid #111111;
    borderRadius: 3px;
    backgroundColor:#969565;
    marginBottom: 2px;
    cursor:pointer;
`.class

export const inactiveItem = b`
    padding: 4px 12px; 
    clear:both;
    overflow: auto;
    border: 1px solid #111111;
    borderRadius: 3px;
    backgroundColor:#ffb3ba;
    marginBottom: 2px;
    cursor:pointer;
`.class
