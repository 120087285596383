import * as React from 'react'
import * as gql from 'graphql/types'
import { useMutation, useQuery } from '@apollo/client'
import insights from 'graphql/queries/insights'
import invalidateCurrentInsight from 'graphql/mutations/invalidateCurrentInsight'
import * as css from '../User.styles'
import { Button, Checkbox } from '@chakra-ui/react'

type Props = {
    user: gql.User
}

const Insights = ({ user }: Props) => {
    const [showAll, setShowAll] = React.useState(false)
    const { loading, error, data, refetch } = useQuery(insights, {
        variables: { UserID: user.user_id, All: 'true' },
    })
    const [r] = useMutation(invalidateCurrentInsight)
    if (loading) return <></>
    if (error) {
        console.log(error.message)
        return <></>
    }
    const handleDelete = async (insight: gql.Insight) => {
        await r({
            variables: {
                UserID: user.user_id,
                Domain: insight.Domain,
            },
        })
        await refetch()
    }
    let insightData = data.insights
    if (!showAll && insightData) insightData = insightData.filter((a) => a.Current == 'true')

    return (
        <div className={css.container}>
            <div>
                <Checkbox
                    checked={showAll}
                    onChange={() => setShowAll(!showAll)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />{' '}
                Show all
            </div>
            <div>
                {insightData &&
                    insightData.map((a: gql.Insight) => {
                        return (
                            <div key={a.InsightID} className={css.container}>
                                <div className={css.rowLayout}>
                                    <strong>{a.Domain.toUpperCase()}</strong>
                                    <div>{  new Date(Date.parse(a.GeneratedAt.substring(0, 19)) + 36000000).toLocaleString()}</div>
                                    {a.Current == 'true' && (
                                        <Button onClick={() => handleDelete(a)}>Delete</Button>
                                    )}
                                    <a
                                        className={css.button}
                                        href={`/inspectResult?insightID=${a.InsightID}&userID=${a.UserID}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        RESULT
                                    </a>
                                    {a.LogGroup !== '' && (
                                        <a
                                            className={css.button}
                                            href={`/inspectlog?group=${a.LogGroup}&stream=${a.LogStream}&search=${a.ExecID}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            LOG
                                        </a>
                                    )}
                                    {a.Error && <div className={css.summaryContainer}>ERROR CODE: {a.Error}</div>}
                                    {/* {a.BillId && <div className={css.summaryContainer}>BillID: {a.BillId}</div>} */}
                                    {a.Summary && (
                                        <>
                                            <div className={css.summaryContainer}>Provider: {a.Summary.ProviderName}</div>  
                                            <div className={css.summaryContainer}>Status: {a.Summary.Status}</div>
                                            <div className={css.summaryContainer}>MaxSave: {a.Summary.MaxDollar}</div>
                                            <div className={css.summaryContainer}>MaxLeaf: {a.Summary.MaxLeaf}</div>
                                            <div className={css.summaryContainer}>Source: {a.Summary.Source}</div>

                                        </>
                                    )}
                                </div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

export default Insights
