import gql from 'graphql-tag'

export default gql`
    query superProducts {
        superProducts {
            ProductName
            Universe
            AgeDefaultType
            IsRated
            Category
            Usi
            ShortName
            LongName
            ID
            YodleeInstitutionName
        }
    }
`
