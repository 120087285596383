import { useMutation, useQuery } from '@apollo/client'
import featuresQuery from 'graphql/queries/features'
import setFeature from 'graphql/mutations/setFeature'
import hideFeature from 'graphql/mutations/hideFeature'
import * as React from 'react'
import * as gql from '../../graphql/types'
import { Table, TableHead, TableRow, TableCell, TableBody, Input, Switch } from '@material-ui/core'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../common/CommonStyles'
import userGroups from 'graphql/queries/userGroups'
import scheduler from 'graphql/queries/scheduler'
import { ChakraTable } from 'components/tableWithSearch'
import {
    Button,
    HStack,
    Modal,
    ModalContent,
    ModalOverlay,
    useDisclosure,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    FormControl,
    FormLabel,
    ModalFooter,
} from '@chakra-ui/react'

type ProcessedObject = {
    key: string
    description: string
} & {
    [key: string]: boolean
}

function flattenAndUnique(lists: string[][]): string[] {
    const uniqueStrings: Set<string> = new Set()

    for (const list of lists) {
        for (const item of list) {
            uniqueStrings.add(item)
        }
    }

    return Array.from(uniqueStrings)
}

function processObjects(objects: gql.FeatureSwitch[]): ProcessedObject[] {
    const uniqueMap: { [key: string]: string[] } = {}

    objects.forEach((i) => {
        if (uniqueMap[i.key]) {
            uniqueMap[i.key].push(i.UserGroup)
        } else {
            if (!!i.isHidden) uniqueMap[i.key] = [i.UserGroup, 'isHidden']
            else uniqueMap[i.key] = [i.UserGroup]
        }
    })

    // all unique groups
    const groupKeys = flattenAndUnique(Object.values(uniqueMap)).concat('isHidden')

    // return a list of objects with key and the groups as [group]: bool
    const result: ProcessedObject[] = []
    for (const key in uniqueMap) {
        const keys = objects.filter((x) => x.key === key)
        const description = keys.find((x) => x.key === key).description
        const obj = {
            key,
            description,
        } as ProcessedObject

        groupKeys.forEach((value) => {
            const bool = keys.find((x) => x.UserGroup === value)?.feature ?? false
            obj[value] = bool
        })

        result.push(obj)
    }

    return result
}

const Features = () => {
    const [newKey, setNewKey] = React.useState('')
    const [newDescription, setNewDescription] = React.useState('')
    const [featureItems, setFeatureItems] = React.useState([])
    const { loading, error, refetch } = useQuery(featuresQuery, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted(data) {
            const items = data.features.items
            setFeatureItems([...items])
        },
    })
    const userGroupsQuery = useQuery(userGroups)
    const scheduleQuery = useQuery(scheduler)
    const [mutateFeature] = useMutation(setFeature, {
        refetchQueries: ['features'],
    })
    const [h] = useMutation(hideFeature)
    const [showHidden, setShowHidden] = React.useState(false)
    const [showSchedules, setShowSchedules] = React.useState(false)

    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = React.useRef(null)

    if (userGroupsQuery.loading) return <div>Loading</div>
    if (error) return <div>{error}</div>
    if (userGroupsQuery.error) return <div>{userGroupsQuery.error}</div>

    const mainGroups = userGroupsQuery.data.userGroups.filter(
        (a) => a.Type == 'Group'
    ) as Array<gql.UserUserGroup>

    // map of featureKey => array of groups that have it on
    const features: gql.FeatureSwitch[] = [...featureItems]
        .filter((a) => (showHidden ? !!a.isHidden : !a.isHidden))
        .filter((a) => (showSchedules ? a.key.includes('Scheduled') : !a.key.includes('Scheduled')))

    const newItems = processObjects(features)
    const sortedData = newItems.slice().sort((a, b) => a.key.localeCompare(b.key))

    const items: Map<String, Array<string>> = new Map()
    features.forEach((i) => {
        if (items.get(i.key!)) {
            if (i.feature) items.get(i.key!)!.push(i.UserGroup!)
        } else {
            if (i.feature) items.set(i.key!, [i.UserGroup!])
            else items.set(i.key!, [])
        }
    })

    const handleChange = async (key, value, group, description) => {
        console.log(
            'updating ',
            key,
            ' to ',
            value,
            ' for ',
            group,
            " group, with '",
            description,
            "' as the description."
        )
        const vars = {
            UserGroup: group,
            key: key,
            feature: value,
            description: description ?? '',
        }
        await mutateFeature({ variables: vars })
        await refetch()
    }

    const hide = async (key, isHidden) => {
        const vars = {
            key: key,
            IsHidden: isHidden,
        }
        console.log(vars)
        await h({ variables: vars })
        await refetch()
    }

    const handleNewKey = async () => {
        setFeatureItems([])
        const vars = {
            UserGroup: 'staff',
            key: newKey,
            feature: false,
            description: newDescription,
        }
        await mutateFeature({ variables: vars }).then(() => {
            refetch()
            setNewKey('')
            alert('DONE!')
        })
    }

    return (
        <div>
            <ChakraTable
                data={sortedData}
                itemsPerPage={10}
                title="Feature Flags"
                subtitle="Keep track of whether app features are on or not."
                fieldsToSearch={['key']}
                badge={{ colorScheme: 'green', text: `${sortedData.length} Total` }}
                extra={
                    <HStack alignSelf="end">
                        <Button
                            marginRight={2}
                            colorScheme="gray"
                            size="sm"
                            variant="solid"
                            onClick={onOpen}
                        >
                            Add Feature
                        </Button>
                        <Button
                            marginRight={2}
                            colorScheme="gray"
                            size="sm"
                            variant="outline"
                            onClick={() => setShowHidden(!showHidden)}
                        >
                            {showHidden ? 'Hide Hidden' : 'Show Hidden'}
                        </Button>
                        <Button
                            marginLeft={2}
                            colorScheme="gray"
                            size="sm"
                            variant="outline"
                            onClick={() => setShowSchedules(!showSchedules)}
                        >
                            {showSchedules ? 'Hide Schedules' : 'Show Schedules'}
                        </Button>
                    </HStack>
                }
                columns={[
                    {
                        key: 'key',
                        type: 'double_text',
                        header: 'Feature Key',
                        calculateProps: (i) => ({
                            avatar: i.key,
                            title: i.key,
                            subtitle: i.description ?? '',
                        }),
                    },
                    {
                        key: 'other',
                        type: 'text',
                        header: showSchedules ? 'Next Run' : '',
                        format: (i) => {
                            if (!showSchedules) return ''
                            return scheduleQuery.data &&
                                scheduleQuery.data.scheduler.find((a) => a.Key == i.key)
                                ? scheduleQuery.data.scheduler.find((a) => a.Key == i.key).Count
                                : 'N/A'
                        },
                    },
                    {
                        key: 'general',
                        type: 'toggle',
                        header: 'General Users',
                        calculateProps: (i) => {
                            return {
                                checked: i.general,
                                onChange: () =>
                                    handleChange(i.key, !i.general, 'general', i.description),
                            }
                        },
                    },
                    {
                        key: 'staff',
                        type: 'toggle',
                        header: 'Staff Users',
                        calculateProps: (i) => ({
                            checked: i.staff,
                            onChange: () => handleChange(i.key, !i.staff, 'staff', i.description),
                        }),
                    },
                    {
                        key: 'CsBeta',
                        type: 'toggle',
                        header: 'CS Beta User',
                        calculateProps: (i) => ({
                            checked: i.CsBeta,
                            onChange: () => handleChange(i.key, !i.CsBeta, 'CsBeta', i.description),
                        }),
                    },
                    {
                        key: 'isHidden',
                        type: 'actions',
                        header: showHidden ? 'Show?' : 'Hide?',
                        calculateProps: (i) => [
                            {
                                onClick: () => hide(i.key, !showHidden),
                                icon: showHidden ? 'eye' : 'eyeoff',
                            },
                        ],
                    },
                ]}
            />
            <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add feature</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>Feature key</FormLabel>
                            <Input
                                ref={initialRef}
                                placeholder="Feature key"
                                onChange={(e) => setNewKey(e.target.value)}
                            />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Description</FormLabel>
                            <Input
                                placeholder="Description"
                                onChange={(e) => setNewDescription(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            colorScheme="blue"
                            mr={3}
                            onClick={() => {
                                handleNewKey()
                                onClose()
                            }}
                        >
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default Features
