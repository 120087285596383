import * as React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import users from 'graphql/queries/users'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../common/CommonStyles'
import userGroups from 'graphql/queries/userGroups'
import List from './List'
import { Button, Input, MenuItem, Select } from '@material-ui/core'
import userGroup from 'graphql/mutations/userGroup'

const UserGroups = () => {
    const [newName, setNewName] = React.useState('')
    const [newType, setNewType] = React.useState('Group')

    const { loading, error, data, refetch } = useQuery(userGroups)
    const [go, result] = useMutation(userGroup, {
        onCompleted: () => refetch(),
        onError: (e) => alert(e),
    })
    if (loading) return <div>Loading</div>
    if (error) return <div>{error}</div>

    const submit = () => {
        go({
            variables: {
                Action: 'create',
                Name: newName,
                Type: newType,
            },
        })
        setNewName('')
        setNewType('Group')
    }
    return (
        <div>
            <BiIcons.BiUserCircle size={35} style={{ display: 'inline-block' }} />
            <h1
                style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                className={CommonStyles.MainHeaderClass}
            >
                User Groups
            </h1>
            <Input
                value={newName}
                onChange={(t) => setNewName(t.target.value)}
                placeholder="name"
            />
            <Select
                value={newType}
                onChange={(t) => setNewType(t.target.value as string)}
                placeholder="name"
            >
                <MenuItem value={'Group'}>Group</MenuItem>
                <MenuItem value={'Club'}>Club</MenuItem>
                <MenuItem value={'Club'}>Partner</MenuItem>
                <MenuItem value={'Organisation'}>Organisation</MenuItem>
            </Select>
            <Button onClick={() => submit()}>Add!</Button>
            <List userGroups={data.userGroups} />
        </div>
    )
}

export default UserGroups
