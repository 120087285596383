import gql from 'graphql-tag'

export default gql`
    query dashboardAnalysis {
        dashboardAnalysis {
            UserAnalysis {
                Active {
                    Total
                    TotalUsersWithProviderAccount
                    TotalAccounts
                    ProviderAccounts {
                        Name
                        Count
                    }
                    TotalBalance
                    TotalNetWorh
                    UsersWithLoanCount
                }
                TenLogins {
                    Total
                    TotalUsersWithProviderAccount
                    TotalAccounts
                    ProviderAccounts {
                        Name
                        Count
                    }
                    TotalBalance
                    TotalNetWorh
                    UsersWithLoanCount
                }
                FifteenLogins {
                    Total
                    TotalUsersWithProviderAccount
                    TotalAccounts
                    ProviderAccounts {
                        Name
                        Count
                    }
                    TotalBalance
                    TotalNetWorh
                    UsersWithLoanCount
                }
                UploadedEnergyBill {
                    Total
                    TotalUsersWithProviderAccount
                    TotalAccounts
                    ProviderAccounts {
                        Name
                        Count
                    }
                    TotalBalance
                    TotalNetWorh
                    UsersWithLoanCount
                }
                PersSuper {
                    Total
                    TotalUsersWithProviderAccount
                    TotalAccounts
                    ProviderAccounts {
                        Name
                        Count
                    }
                    TotalBalance
                    TotalNetWorh
                    UsersWithLoanCount
                }
                LinkedBank {
                    Total
                    TotalUsersWithProviderAccount
                    TotalAccounts
                    ProviderAccounts {
                        Name
                        Count
                    }
                    TotalBalance
                    TotalNetWorh
                    UsersWithLoanCount
                }
                Total
            }

            NumberOfSwitches {
                Date
                UserInteractions
                EnergyUploads {
                    Total
                    GroupSplit {
                        Name
                        Count
                    }
                    UserGroupSplit {
                        Name
                        Count
                    }
                }
                SuperPers {
                    Total
                    GroupSplit {
                        Name
                        Count
                    }
                    UserGroupSplit {
                        Name
                        Count
                    }
                }
                TotalUsers {
                    Total
                    GroupSplit {
                        Name
                        Count
                    }
                    UserGroupSplit {
                        Name
                        Count
                    }
                }
                BankLink {
                    Total
                    GroupSplit {
                        Name
                        Count
                    }
                    UserGroupSplit {
                        Name
                        Count
                    }
                }
            }
        }
    }
`
