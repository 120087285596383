import gql from 'graphql-tag'

export default gql`
    query campaignDraftEvent($CampaignID: String!) {
        campaignDraftEvent(CampaignID: $CampaignID) {
            DateCreated
			DateUpdated
			CampaignID
			ID
			Type
			Status
			AudienceTotal
			PushTotal
			IsDraft
			PushedUserIDs
        }
    }
`
