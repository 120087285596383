import * as React from 'react'
import { Spinner as ChakraSpinner, SpinnerProps, Center } from '@chakra-ui/react'

interface Props extends SpinnerProps {
    h?: string
}

const Spinner = ({ h, ...props }: Props) => {
    return (
        <Center h={h ? h : '100vh'}>
            <ChakraSpinner size="xl" {...props} />
        </Center>
    )
}

export default Spinner
