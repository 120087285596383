import { Datum, Serie } from '@nivo/line'
import { CarbonAnalysisResult } from 'graphql/types'

const formatDate = (dateStr: string): Date => {
    return new Date(dateStr)
}

export const formatLineGraphCount = (data: CarbonAnalysisResult[]): Serie[] => {
    const percentage: Datum[] = []
    data.forEach((d) => {
        percentage.push({
            x: formatDate(d.Date),
            y: Number(d.UserEngagedCount),
        })
    })
    return [
        {
            id: 'Total User Engagement',
            data: percentage,
        },
    ]
}
