import { useMutation, useQuery } from '@apollo/client'
import { Box, Button, Checkbox, Modal, Popover, TextField } from '@material-ui/core'
import createRelease from 'graphql/mutations/createRelease'
import getReleases from 'graphql/queries/getReleases'
import getReleaseStatus from 'graphql/queries/getReleaseStatus'
import * as React from 'react'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../common/CommonStyles'
import * as styles from './styles'
import * as GraphTypes from 'graphql/types'
import { compareDesc, format, parseJSON } from 'date-fns'
import AppVersion from './AppVersion'

const Release = () => {
    const [modalVis, setModalVis] = React.useState(false)
    const [popOpen, setPopOpen] = React.useState(false)
    const [pullsPopOpen, setPullsPopOpen] = React.useState(false)
    const [releaseRepos, setReleaseRepos] = React.useState<string[]>([])
    const [releaseName, setReleaseName] = React.useState<string>('')
    const { loading: l, error: e, data: d } = useQuery(getReleases, { fetchPolicy: 'no-cache' })
    const { loading, error, data } = useQuery(getReleaseStatus, { fetchPolicy: 'no-cache' })
    const [mutation] = useMutation(createRelease)
    if (loading || l) return <div>Loading</div>
    if (error || e) return <div>failure {console.error(error || e)} </div>
    if (!data.getReleaseStatus) throw new Error('WTF')

    const releaseAll = () => {
        setReleaseRepos(
            data.getReleaseStatus
                .filter((a) => a.Commits && a.Commits.length > 0)
                .map((a) => a.RepoName),
        )
        setModalVis(true)
    }
    const release = (repo: string) => {
        setReleaseRepos([repo])
        setModalVis(true)
    }
    const submit = () => {
        mutation({
            variables: {
                name: releaseName,
                repos: releaseRepos.join(','),
            },
        })
        setModalVis(false)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    const releases = data.getReleaseStatus as GraphTypes.AcaciaCommitPackage[]
    const pulls = releases
        .filter((a) => a.Pulls)
        .map((a) => ({ Pulls: a.Pulls.map((p) => ({ ...p, RepoName: a.RepoName })) }))
        .flatMap((a) => a.Pulls)
    const validItems = releases.filter((a) => a.Commits && a.Commits.length > 0)

    const hasActiveChange = (a: GraphTypes.AcaciaCommitPackage): boolean => {
        return (
            a.Commits!.filter((a) => !a?.Name!.includes('[P]') && !a?.Name!.includes('[F')).length >
            0
        )
    }
    let arr = [...d.getReleases]
    arr.sort((a, b) => {
        const [amajor, aminor] = a.Number.split('.')
        const [bmajor, bminor] = b.Number.split('.')
        if (amajor != aminor) return Number(amajor) - Number(bmajor)
        else return Number(aminor) - Number(bminor)
    })
    console.log(arr)
    return (
        <div>
            <Modal open={modalVis} onClose={() => setModalVis(false)}>
                <Box sx={style}>
                    <h1 style={{ fontSize: '26px' }}>Action Release</h1>
                    <div>Release repos:</div>
                    <ul>
                        {releaseRepos.map((r) => (
                            <li>{r}</li>
                        ))}
                    </ul>
                    <br />
                    <div>
                        <span>Name: </span>
                        <TextField
                            value={releaseName}
                            onChange={(
                                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                            ) => setReleaseName(event.target.value)}
                        />
                    </div>
                    <div style={{ float: 'left' }}>
                        <Button onClick={() => submit()} style={{ backgroundColor: 'lightgreen' }}>
                            LETS GO DUDE!
                        </Button>
                        <Button
                            onClick={() => setModalVis(false)}
                            style={{ backgroundColor: '#FFCCCB' }}
                        >
                            NAH!
                        </Button>
                    </div>
                </Box>
            </Modal>
            <Popover open={popOpen} onClose={() => setPopOpen(false)}>
                {arr
                    .sort((p, n) => compareDesc(new Date(p.Date), new Date(n.Date)))
                    .map((a, i) => (
                        <div
                            key={a.Number + a.Date + i}
                            style={{
                                backgroundColor: '#eee',
                                padding: '10px 8px',
                                borderBottom: '1px solid #ddd',
                                marginBottom: '8px',
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        color: 'green',
                                        fontSize: '26px',
                                        border: '1px solid #333',
                                        padding: '3px',
                                        borderRadius: '5px',
                                        float: 'left',
                                    }}
                                >
                                    {a.Number}
                                </div>
                                <div
                                    style={{
                                        float: 'left',
                                        marginLeft: '5px',
                                        fontSize: '22px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {a.Description}
                                </div>
                            </div>
                            <div style={{ clear: 'both' }}>
                                {format(new Date(a.Date), 'PPPppp')}
                            </div>
                            <div>{a.Repos}</div>
                        </div>
                    ))}
            </Popover>
            <Popover open={pullsPopOpen} onClose={() => setPullsPopOpen(false)}>
                <div style={{ backgroundColor: '#fff', padding: '10px 8px' }}>
                    {pulls.map((a) => (
                        <a
                            key={a.Name}
                            rel="noreferrer"
                            target="_blank"
                            href={a.URL}
                            style={{
                                backgroundColor: '#eee',
                                display: 'block',
                                overflow: 'auto',
                                padding: '10px 8px',
                                borderBottom: '1px solid #ddd',
                                marginBottom: '8px',
                            }}
                        >
                            <div style={{ fontWeight: 500, fontSize: '22px' }}>{a.Name}</div>
                            <div style={{ fontSize: '20px' }}>{a.RepoName}</div>
                            <div style={{ fontWeight: 300, fontSize: '16px' }}>{a.Author}</div>
                        </a>
                    ))}
                </div>
            </Popover>
            <div>
                <BiIcons.BiWindow size={35} style={{ display: 'inline-block' }} />
                <h1
                    style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                    className={CommonStyles.MainHeaderClass}
                >
                    Releases
                </h1>
                <Button onClick={() => setPopOpen(true)}>SEE PREVIOUS</Button>
                {pulls.length && (
                    <Button onClick={() => setPullsPopOpen(true)}>Pulls ({pulls.length})</Button>
                )}
                <AppVersion />
            </div>
            <div>
                {releaseRepos.length ? (
                    <Button
                        style={{ backgroundColor: '#ed749b' }}
                        onClick={() => setModalVis(true)}
                    >
                        Release Selected
                    </Button>
                ) : (
                    <Button style={{ backgroundColor: '#ed749b' }} onClick={() => releaseAll()}>
                        Release All
                    </Button>
                )}
                <div>
                    {validItems.map((a) => (
                        <div className={hasActiveChange(a) ? styles.activeRow : styles.passiveRow}>
                            <div className={styles.info}>
                                <div className={styles.leftInfo}>
                                    <p className={styles.header}>
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={`https://github.com/acaciamoney/${a.RepoName}/compare/${a.LastTag}...master`}
                                        >
                                            {a.RepoName}
                                        </a>
                                    </p>
                                    <p className={styles.subheader}>{a.LastTag}</p>
                                </div>
                                {a.Commits && (
                                    <div className={styles.rightInfo}>
                                        <Checkbox
                                            value={releaseRepos.includes('a.RepoName')}
                                            onChange={(event, checked) =>
                                                setReleaseRepos(
                                                    checked
                                                        ? [...releaseRepos, a.RepoName]
                                                        : releaseRepos.filter(
                                                              (val) => val !== a.RepoName,
                                                          ),
                                                )
                                            }
                                        />
                                        {!releaseRepos.length && (
                                            <Button
                                                style={{ backgroundColor: '#ed749b' }}
                                                onClick={() => release(a.RepoName)}
                                            >
                                                Release
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div>
                                {a.Commits &&
                                    a.Commits.sort((p, n) =>
                                        compareDesc(parseJSON(p.Time), parseJSON(n.Time)),
                                    ).map((aa) => (
                                        <div className={styles.subrow}>
                                            <div>
                                                <strong>{aa.Message}</strong>
                                                <span> - {aa.Name} </span>
                                            </div>
                                            <div style={{ color: '#666' }}>
                                                {format(parseJSON(aa.Time), 'PPPppp')}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Release
