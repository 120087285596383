import {
    Menu,
    MenuDivider,
    MenuItem,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Text,
    useColorModeValue,
    useColorMode,
} from '@chakra-ui/react'
import * as React from 'react'
import { useAuth0 } from '../auth/react-auth0-wrapper'
import { AccountSwitcherButton } from './AccountSwitcherButton'

export const AccountSwitcher = () => {
    const { toggleColorMode } = useColorMode()
    const text = useColorModeValue('dark', 'light')
    const { user, logout } = useAuth0()

    return (
        <Menu>
            <AccountSwitcherButton />
            <MenuList shadow="lg" py="4" color={useColorModeValue('gray.600', 'gray.200')} px="3">
                <Text fontWeight="medium" mb="2">
                    {user?.email}
                </Text>
                <MenuOptionGroup defaultValue="chakra-ui">
                    <MenuItemOption value="chakra-ui" fontWeight="semibold" rounded="md">
                        Staff Mode
                    </MenuItemOption>
                    <MenuItemOption value="careerlyft" fontWeight="semibold" rounded="md">
                        Admin Mode
                    </MenuItemOption>
                </MenuOptionGroup>
                <MenuDivider />
                <MenuItem rounded="md">Workspace settings</MenuItem>
                <MenuItem rounded="md" onClick={toggleColorMode}>
                    Swith to {text} mode
                </MenuItem>
                <MenuDivider />
                <MenuItem rounded="md" onClick={() => logout()}>
                    Logout
                </MenuItem>
            </MenuList>
        </Menu>
    )
}
