export const getSampleRates = `
[
	{"controlLoad1": 
		{
			"Rate":   "0.197",
			"Value":  "0",
			"Qty":    "246",
			"Charge": "48.44"
		}
	},
	{"pk": 
		{
			"Rate":   "0.263",
			"Value":  "0",
			"Qty":    "467",
			"Charge": "122.63"
		}
	},
	{"pkStep1": 
		{
			"Rate":   "0.263",
			"Value":  "0",
			"Qty":    "1324",
			"Charge": "347.68"
		}
	},
	{"supply": 
		{
			"Rate":   "1.091",
			"Value":  "0",
			"Qty":    "37",
			"Charge": "40.37"
		}
	}
]
`