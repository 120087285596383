import * as React from 'react'
import { useQuery } from '@apollo/client'
import merchants from 'graphql/queries/merchants'
import merchantTotals from 'graphql/queries/merchantTotals'

import customMerchantBuckets from 'graphql/queries/customMerchantBucket'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import { TableSortLabel } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
})

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

const Merchants = () => {
    const {
        loading: merchantsLoading,
        error: merchantsError,
        data: merchantsData,
    } = useQuery(merchants)
    const { loading: TLoading, error: TError, data: TData } = useQuery(merchantTotals)
    const {
        loading: customMerchantBucketsLoading,
        error: customMerchantBucketsError,
        data: customMerchantBucketsData,
    } = useQuery(customMerchantBuckets)
    console.log({ merchantsError, TError, customMerchantBucketsError })
    if (
        merchantsLoading ||
        merchantsError ||
        customMerchantBucketsLoading ||
        customMerchantBucketsError ||
        TLoading
    )
        return <div>Loading</div>

    const ms = merchantsData.merchants.items
        .map((m) => {
            const spendList = TData.merchantTotals.filter((a) => a.MerchantID === m.merchant_id)
            const total = spendList.length === 0 ? 0 : spendList[0].Total
            const count = spendList.length === 0 ? 0 : spendList[0].Count
            return {
                merchant_id: m.merchant_id,
                // customBucket: m. customBucket,
                // acaciaBucket: m.acaciaBucket,
                categoryBucket: m.categoryBucket,
                category: m.data.category.anzsic.group.title,
                userCustomisations: customMerchantBucketsData.customMerchantBucket.items.filter(
                    (c) => c.merchant_id === m.merchant_id,
                ),
                totalSpend: total,
                countSpend: count,
            }
        })
        .sort((a, b) => {
            return a.merchant_id.localeCompare(b.merchant_id)
        })
        .sort((a, b) => {
            return a.totalSpend - b.totalSpend
        })
    return <CollapsibleTable rows={ms} />
}
function Row({ row, index }) {
    const [open, setOpen] = React.useState(false)
    const classes = useRowStyles()

    return (
        <React.Fragment>
            <TableRow
                hover
                style={{ backgroundColor: index % 2 === 1 ? 'white' : 'rgba(52, 89, 191, 0.05)' }}
                className={classes.root}
            >
                <TableCell>
                    {row.userCustomisations.length > 0 ? (
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    ) : (
                        <div />
                    )}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.merchant_id}
                </TableCell>
                <TableCell align="right">{row.totalSpend}</TableCell>
                <TableCell align="right">{row.countSpend}</TableCell>
                <TableCell align="right">{row.userCustomisations.length}</TableCell>
                {/* <TableCell align="right">{row.acaciaBucket}</TableCell> */}
                <TableCell align="right">{row.category}</TableCell>
                {/* <TableCell align="right">{row.categoryBucket}</TableCell> */}
            </TableRow>
            <TableRow style={{ backgroundColor: 'rgba(104, 191, 89, 0.25)' }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>User ID</TableCell>
                                        <TableCell>has bucket to</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.userCustomisations.map((uc) => (
                                        <TableRow key={uc.UserID}>
                                            <TableCell component="th" scope="row">
                                                <Link to={`/users/${uc.UserID}`}>{uc.UserID}</Link>
                                            </TableCell>
                                            <TableCell>{uc.bucket}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

const headCells = [
    {
        id: 'merchant',
        numeric: false,
        disableSort: false,
        disablePadding: true,
        label: 'Merchant (ID)',
    },
    {
        id: 'totalSpend',
        numeric: false,
        disableSort: false,
        disablePadding: true,
        label: 'totalSpend',
    },

    {
        id: 'countSpend',
        numeric: false,
        disableSort: false,
        disablePadding: true,
        label: 'countSpend',
    },

    {
        id: 'customBucket',
        numeric: true,
        disableSort: true,
        disablePadding: false,
        label: 'Custom Bucket',
    },
    // { id: 'acaciaBucket', numeric: true, disableSort: false, disablePadding: false, label: 'Acacia Bucket' },
    {
        id: 'category',
        numeric: true,
        disableSort: false,
        disablePadding: false,
        label: 'Category (Basiq/Govt)',
    },
    // { id: 'categoryBucket', numeric: true, disableSort: false, disablePadding: false, label: 'Category Bucket' },
]

function CollapsibleTable({ rows }) {
    const classes = useRowStyles()
    const [name, setName] = React.useState('')
    const [hasCustomOnly, setHasCustomOnly] = React.useState(false)

    const [order, setOrder] = React.useState<'asc' | 'desc'>('asc')
    const [orderBy, setOrderBy] = React.useState('merchant')
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(100)

    const handleHasCustomOnly = (event) => {
        setHasCustomOnly(event.target.checked)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }
    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property)
    }

    const handleChange = (event) => {
        setName(event.target.value)
    }

    const filteredRows = rows
        .filter((x) => {
            if (name === '') return true
            if (x.merchant_id.toLowerCase().includes(name.toLowerCase())) return true
            if (!!x.acaciaBucket && x.acaciaBucket.toLowerCase().includes(name.toLowerCase()))
                return true
            if (!!x.acaciaBucket && x.category.toLowerCase().includes(name.toLowerCase()))
                return true
            if (!!x.categoryBucket && x.categoryBucket.toLowerCase().includes(name.toLowerCase()))
                return true
            return false
        })
        .filter((x) => !hasCustomOnly || (hasCustomOnly && x.userCustomisations.length > 0))

    return (
        <TableContainer style={{ maxHeight: '87vh' }} component={Paper}>
            <TextField id="standard-name" label="Search" value={name} onChange={handleChange} />
            <Checkbox
                checked={hasCustomOnly}
                onChange={handleHasCustomOnly}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            Only show Merchants with changes
            <TablePagination
                rowsPerPageOptions={[20, 50, 100, rows.length]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Table stickyHeader aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        {headCells.map((headCell) => (
                            <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                {headCell.disableSort === false ? (
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc'
                                                    ? 'sorted descending'
                                                    : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                ) : (
                                    headCell.label
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stableSort(filteredRows, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            return <Row key={row.name} index={index} row={row} />
                        })}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[20, 50, 100, rows.length]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    )
}

export default Merchants
