import gql from 'graphql-tag'
import CarbonResultsFragment from '../fragments/carbonResults/carbonResults'

export default gql`
    ${CarbonResultsFragment}
    query (
        $userID0: String
        $userID1: String
        $userID2: String
        $userID3: String
        $userID4: String
        $userID5: String
        $userID6: String
        $userID7: String
        $userID8: String
        $userID9: String
    ) {
        userID0: carbonResults(adminUserID: $userID0, all: true) {
            ...CarbonResultsFragment
        }
        userID1: carbonResults(adminUserID: $userID1, all: true) {
            ...CarbonResultsFragment
        }
        userID2: carbonResults(adminUserID: $userID2, all: true) {
            ...CarbonResultsFragment
        }
        userID3: carbonResults(adminUserID: $userID3, all: true) {
            ...CarbonResultsFragment
        }
        userID4: carbonResults(adminUserID: $userID4, all: true) {
            ...CarbonResultsFragment
        }
        userID5: carbonResults(adminUserID: $userID5, all: true) {
            ...CarbonResultsFragment
        }
        userID6: carbonResults(adminUserID: $userID6, all: true) {
            ...CarbonResultsFragment
        }
        userID7: carbonResults(adminUserID: $userID7, all: true) {
            ...CarbonResultsFragment
        }
        userID8: carbonResults(adminUserID: $userID8, all: true) {
            ...CarbonResultsFragment
        }
        userID9: carbonResults(adminUserID: $userID9, all: true) {
            ...CarbonResultsFragment
        }
    }
`
