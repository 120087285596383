import { gql } from '@apollo/client'

export default gql`
    mutation updateProductActive($institutionCode: String!, $productID: String!, $value: String!) {
        updateProductActive(
            institutionCode: $institutionCode
            productID: $productID
            value: $value
        ) {
            productID
            institutionCode
        }
    }
`
