import b from 'bss'
import * as CommonStyles from 'components/common/CommonStyles'

export const activeRow = b`
    clear:both;
    overflow:auto; 
    margin: 8px 0; 
    background-color: #f4f6f8;
    border: 1px solid #333;
    padding:8px 4px;
    backgroundColor: #FADBD8
`.class

export const passiveRow = b`
    clear:both;
    overflow:auto; 
    margin: 8px 0; 
    background-color: #f4f6f8;
    border: 1px solid #333;
    padding:8px 4px;
    backgroundColor: #A9DFBF
`.class

export const subrow = b`
    clear:both;
    overflow:auto; 
    margin: 2px 0; 
    background-color: #fff;
    border: 1px solid #333;
    padding:8px 4px;
`.class

export const header = b`
    font-size: 30px;
    font-weight:bold;
`.class

export const subheader = b`
    font-size: 20px;
`.class

export const leftInfo = b`
    float:left;
`.class

export const rightInfo = b`
    float:left;
    margin-top:5px;
    margin-left: 40px;
`.class

export const info = b`
    clear:both;
`.class
