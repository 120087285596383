import * as React from 'react'
import MaterialTable, { Icons } from 'material-table'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { tableIcons } from '../../common/MaterialIcons'
import { row } from 'components/events/styles'

const InstitutionList = ({ items }) => {
    let InstitutionsData = items.map((a) => {
        return {
            Name: a.name,
            LastSync: a.lastSync,
            Type: a.type,
            SubType: a.subType,
            SyncError: a.syncError,
            LastSyncResult: a.lastSyncResult,
            Active: a.active,
            SyncRuns: a.SyncRuns,
        }
    })
    console.log(items)
    return (
        <MaterialTable
            icons={tableIcons as Icons}
            title="Institutions"
            columns={[
                {
                    title: 'LastSyncResult',
                    field: 'LastSyncResult',
                    render: (data) => <></>,
                    hidden: true,
                },
                { title: 'Name', field: 'Name' },
                { title: 'Type', field: 'Type' },
                { title: 'SubType', field: 'SubType' },
                {
                    title: 'Last Sync',
                    field: 'LastSync',
                },
                { title: 'Sync Error', field: 'SyncError' },
                { title: 'SyncRuns', field: 'SyncRuns', render: () => null },
            ]}
            data={InstitutionsData}
            options={{
                sorting: true,
                filtering: true,
                search: true,
                pageSize: 50,
            }}
            // onRowClick={(event, rowData, togglePanel) => rowData?.SyncError=="Partial success" ? togglePanel() : console.log("no") }
            detailPanel={(rowData) => {
                if (!rowData.SyncRuns) return <div>NO RUNS YET!</div>
                else
                    return (
                        <div>
                            {rowData.SyncRuns.map((item) => {
                                return (
                                    <div>
                                        <a
                                            href={`/inspectlog?group=${item.LogGroup}&stream=${item.LogStream}&search=${item.ID}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {item.ID} - {item.Date} - {item.Status}: {item.Error}
                                        </a>
                                    </div>
                                )
                            })}
                        </div>
                    )
            }}
        />
    )
}

export default InstitutionList
