import gql from 'graphql-tag'

export default gql`
    query userGroups {
        userGroups {
            ID
            Type
            Name
        }
    }
`
