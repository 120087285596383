import { EnergyAnalysisSwitchesDashboard } from 'components/analysis/graphs/dashboard/energy/switches'
import { EnergyAnalysisTotalDashboard } from 'components/analysis/graphs/dashboard/energy/total'
import { EnergyAnalysisUploadsDashboard } from 'components/analysis/graphs/dashboard/energy/uploads'
import { InsightDashboard } from 'components/analysis/graphs/dashboard/insights/engagement'
import { LendingEngagementDashboard } from 'components/analysis/graphs/dashboard/lending/engagement'
import { SuperEngagementDashboard } from 'components/analysis/graphs/dashboard/super/engagement'
import SwitchesGraph from 'components/analysis/graphs/dashboard/switches'
import SwitchPercentageGraph from 'components/analysis/graphs/dashboard/switchPercentage'
import UserInteractionsGraph from 'components/analysis/graphs/dashboard/userInteractions'
import Spinner from 'components/common/Spinner'
import { formatRFC3339, subMonths } from 'date-fns'
import adminInsights from 'graphql/queries/adminInsights'
import dashboardAnalysis from 'graphql/queries/dashboardAnalysis'
import lendingAnalysis from 'graphql/queries/lendingAnalysis'
import superEngagement from 'graphql/queries/superEngagement'
import switchesOverTimeDocument from 'graphql/queries/switchesOverTime'
import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Box } from '@chakra-ui/react'

const mo6 = subMonths(new Date(), 6)
const startDate = formatRFC3339(mo6)
const endDate = formatRFC3339(new Date())

const Dashboard = () => {
    const { loading: daLoading, error: daError, data: daData } = useQuery(dashboardAnalysis)
    const {
        loading: energyLoading,
        error: energyError,
        data: energyData,
    } = useQuery(switchesOverTimeDocument, {
        variables: {
            startDate,
            endDate,
        },
    })
    const {
        loading: lendingLoading,
        error: lendingError,
        data: lendingData,
    } = useQuery(lendingAnalysis, {
        variables: {
            startDate,
            endDate,
        },
    })
    const {
        loading: superLoading,
        error: superError,
        data: superData,
    } = useQuery(superEngagement, {
        variables: {
            startDate,
            endDate,
        },
    })
    // const {
    //     loading: insightsLoading,
    //     error: insightsError,
    //     data: insightsData,
    // } = useQuery(adminInsights)

    if (daError || energyError || superError || lendingError) {
        console.error(daError)
        console.error(energyError)
        console.error(superError)
        console.error(lendingError)
        // return <div>error</div>
    }
    return (
        <Box borderWidth="4px" borderStyle="dashed" rounded="md">
            {daLoading ? (
                <Spinner h="550px" />
            ) : daError ? (
                <div>Dashboard Analysis error</div>
            ) : (
                <Row>
                    <SwitchPercentageGraph data={daData.dashboardAnalysis.NumberOfSwitches} />
                    <SwitchesGraph data={daData.dashboardAnalysis.NumberOfSwitches} />
                    <UserInteractionsGraph data={daData.dashboardAnalysis.NumberOfSwitches} />
                </Row>
            )}
            {energyLoading ? (
                <Spinner h="550px" />
            ) : energyError ? (
                <div>Energy Analysis error</div>
            ) : (
                <Row>
                    <EnergyAnalysisUploadsDashboard data={energyData.energyAnalysis} />
                    {/* <EnergyAnalysisSwitchesDashboard data={energyData.energyAnalysis} /> */}
                    <EnergyAnalysisTotalDashboard data={energyData.energyAnalysis} />
                </Row>
            )}
            {lendingLoading ? (
                <Spinner h="550px" />
            ) : lendingError ? (
                <div>Lending Analysis error</div>
            ) : (
                <Row>
                    <LendingEngagementDashboard data={lendingData.lendingAnalysis} />
                </Row>
            )}
            {superLoading ? (
                <Spinner h="550px" />
            ) : superError ? (
                <div>Super Analysis error</div>
            ) : (
                <Row>
                    <SuperEngagementDashboard data={superData.superAnalysis} />
                </Row>
            )}

            {/* {(insightsLoading || !insightsData) ? (
                <Spinner h="550px" />
            ) : (
                <Row>
                    <InsightDashboard data={insightsData} />
                </Row>
            )} */}
        </Box>
    )
}

const Row = ({ children }) => {
    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 33.3%)', gap: '0px' }}>
            {children}
        </div>
    )
}

export default Dashboard
