import gql from 'graphql-tag'

export default gql`
    query scheduler {
        scheduler {
            Key
            Count
        }
    }
`
