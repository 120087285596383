import { gql } from '@apollo/client'

export default gql`
    mutation createProductUpdate(
        $institutionCodeProductID: String!
        $updatedKey: String!
        $updatedValue: String!
        $state: String!
        $comments: String!
        $uneditedValue: String!
    ) {
        createProductUpdate(
            institutionCodeProductID: $institutionCodeProductID
            updatedKey: $updatedKey
            updatedValue: $updatedValue
            state: $state
            comments: $comments
            uneditedValue: $uneditedValue
        ) {
            institutionCodeProductID
            updatedKey
            updatedValue
            uneditedValue
            comments
            dateCreated
            dateUpdated
            state
        }
    }
`
