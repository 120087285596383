import gql from 'graphql-tag'

export default gql`
    query carbonResults($all: Boolean, $adminUserID: String) {
        carbonResults(all: $all, adminUserID: $adminUserID) {
            ResultID
            UserID
            GeneratedAt
            Domain
            TTL
            Emissions
            Calculations
            Current
        }
    }
`
