import processFailedAccount from 'graphql/mutations/processFailedAccount'
import yodleeAccountType from 'graphql/mutations/yodleeAccountType'
import Ignored from 'graphql/queries/ignoredProducts'
import pendingAccounts from 'graphql/queries/pendingAccounts'
import superProducts from 'graphql/queries/superProducts'
import * as GraphTypes from 'graphql/types'
import * as React from 'react'
import * as BiIcons from 'react-icons/bi'
import { Link } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'
import {
    Button,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core'

import * as CommonStyles from '../../common/CommonStyles'

const PendingAccounts = () => {
    const { loading, error, data, refetch } = useQuery(pendingAccounts, { fetchPolicy: 'no-cache' })
    const { loading: l, error: e, data: d } = useQuery(Ignored, { fetchPolicy: 'no-cache' })
    const [process, b] = useMutation(processFailedAccount)
    const {
        loading: ll,
        error: ee,
        data: dd,
    } = useQuery(superProducts, { fetchPolicy: 'no-cache' })
    //     loading: l,
    //     error: e,
    //     data: d,
    //     refetch,
    // } = useQuery(Ignored, { fetchPolicy: 'no-cache' })
    const [submitYodleeAccountType] = useMutation(yodleeAccountType)
    const [unmappableFilter, setUnmappableFilter] = React.useState(false)
    if (loading || l || ll) return <div>Loading</div>
    if (error || e) return <div>failure {console.error(error)} </div>
    const found: string[] = []
    const ignored = d.ignoredProducts.items.map((a) => a.productDescription)
    const cookt = ['Members Equity Bank', 'Macquarie Bank', 'NAB Internet']
    // const ignored = d.ignoredProducts.items.map((a) => a.productDescription)

    const accounts = unmappableFilter
        ? (data.pendingAccounts.items as GraphTypes.Account[]).filter((a) => a.UnableToMap)
        : (data.pendingAccounts.items as GraphTypes.Account[])
              .filter((a) => a.ProviderAccountID !== '')
              .filter((a) => {
                  const type = a.customType ? a.customType : a!.class!.type!
                  return type === 'savings' || type === 'transaction' || type == 'super_annuation'
              })
              .filter((a) => !a.UnableToMap)
              // .filter(a => dd.superProducts.filter(p => p.YodleeInstitutionName == a.businessName).length == 0)
              .filter((a) => !cookt.includes(a.businessName!))
              .filter((a) => !ignored.includes(a.class.product))

    const handleChange = async (YodleeBusinessName, YodleeProductName, Type) => {
        console.log(YodleeBusinessName, YodleeProductName, Type)
        await submitYodleeAccountType({
            variables: {
                YodleeProductName: YodleeProductName,
                YodleeBusinessName: YodleeBusinessName,
                Type: Type,
            },
        })
        await refetch()
    }

    const unMappableAccount = async (userID: string, accountID: string) => {
        await process({
            variables: {
                Action: 'UnableToMapAccount',
                AccountID: accountID,
                UserID: userID,
            },
        })
        refetch()
    }

    return (
        <div>
            <BiIcons.BiWater size={35} style={{ display: 'inline-block' }} />
            <h1
                style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                className={CommonStyles.MainHeaderClass}
            >
                Pending Maps
            </h1>
            <br />
            <Button
                color={unmappableFilter ? 'secondary' : 'default'}
                onClick={() => setUnmappableFilter(!unmappableFilter)}
            >
                Show unmappable
            </Button>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>UserID</TableCell>
                        <TableCell>Yodlee Type</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Business</TableCell>
                        <TableCell>Product</TableCell>
                        <TableCell>Unmappable</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {accounts.map((row) => {
                        let productType = row.customType ? row.customType : row.class!.type
                        if (productType == 'brokerage_margin' || productType == 'individual') {
                            productType = 'investment'
                        }
                        if (!row.user) return <></>
                        return (
                            <TableRow>
                                <TableCell>
                                    <Link to={`/users/${row.user!.user_id}`}>
                                        {row.user!.user_id}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    {productType} ({row.class!.type})
                                </TableCell>
                                <TableCell>
                                    <Select
                                        labelId="pick-type"
                                        id="pick-type"
                                        value={
                                            row.customType !== ''
                                                ? row.customType
                                                : row!.class!.type!
                                        }
                                        onChange={(event) =>
                                            handleChange(
                                                row.businessName,
                                                row.class!.product,
                                                event.target.value,
                                            )
                                        }
                                    >
                                        <MenuItem value="other">Other</MenuItem>
                                        <MenuItem value="savings">Savings</MenuItem>
                                        <MenuItem value="transaction">Transaction</MenuItem>
                                        <MenuItem value="super_annuation">Super</MenuItem>
                                        <MenuItem value="mortgage">Mortgage</MenuItem>
                                        <MenuItem value="credit">Credit Card</MenuItem>
                                        <MenuItem value="investment">Investment</MenuItem>
                                    </Select>
                                </TableCell>
                                <TableCell>{row.businessName!}</TableCell>
                                <TableCell>{row.class!.product!}</TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() =>
                                            unMappableAccount(row.user!.user_id!, row.id)
                                        }
                                    >
                                        Unmappable
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </div>
    )
}

export default PendingAccounts
