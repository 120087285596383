import * as React from 'react'
import { Datum, ResponsiveLine, Serie } from '@nivo/line'
import * as CommonStyles from '../../../../common/CommonStyles'
import { format } from 'date-fns'
import * as GraphTypes from 'graphql/types'

type Props = {
    data: GraphTypes.SwitchesResult[]
}

const Graph = ({ data }: Props) => {
    const pointSize = 8
    const tickRotation = 32
    const tickPadding = 4

    const bankingResults: Datum[] = []
    const superResults: Datum[] = []
    const esResults: Datum[] = []
    const oneUIResults: Datum[] = []

    data.forEach((d) => {
        bankingResults.push({
            x: d.Date!,
            y: (Number(d.BankLink?.Total) / Number(d.TotalUsers?.Total)) * 100,
        })
        esResults.push({
            x: d.Date!,
            y: (Number(d.EnergyUploads?.Total) / Number(d.TotalUsers?.Total)) * 100,
        })
        superResults.push({
            x: d.Date!,
            y: (Number(d.SuperPers?.Total) / Number(d.TotalUsers?.Total)) * 100,
        })
        oneUIResults.push({
            x: d.Date!,
            y: (Number(d.UserInteractions![1]) / Number(d.TotalUsers?.Total)) * 100,
        })
    })

    const graphData: Serie[] = [
        {
            data: bankingResults,
            id: 'Savings - Users who have linked a bank',
        },
        {
            data: esResults,
            id: 'Energy - Users who have uploaded a bill',
        },
        {
            data: superResults,
            id: 'Super - Users who have personalised super',
        },
        {
            data: oneUIResults,
            id: 'Overall - At least one user interaction',
        },
    ]
    return (
        <div className={CommonStyles.DashGraphContainer}>
            <ResponsiveLine
                data={graphData}
                margin={{ top: 75, right: 40, bottom: 35, left: 40 }}
                enablePoints={true}
                pointSize={pointSize}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: false,
                    // reverse: false,
                }}
                tooltip={(slice) => {
                    const otherData = graphData
                        .filter((x) => x.id !== slice.point.serieId)
                        .map((x) => ({
                            ...x.data.find(
                                (y) => y.x?.toString() === slice.point.data.x.toString(),
                            ),
                            id: x.id,
                        }))

                    return (
                        <div
                            style={{
                                borderWidth: 1,
                                borderColor: 'rgba(0, 0, 0, 0.4)',
                                borderRadius: '8px',
                                backgroundColor: 'white',
                                padding: '5px 10px',
                                display: 'grid',
                                position: 'absolute',
                                width: '440px',
                                top: 12,
                                left: 12,
                            }}
                        >
                            <span
                                style={{
                                    color: 'rgba(0,0,0,0.85)',
                                }}
                            >
                                {format(new Date(slice.point.data.x), 'PPP')}
                            </span>
                            <div
                                key={slice.point.id}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <svg
                                    style={{
                                        marginRight: 4,
                                        height: '10px',
                                        width: '10px',
                                    }}
                                    viewBox="0 0 10 10"
                                >
                                    <circle cx="50%" cy="50%" r="4" fill={`${slice.point.color}`} />
                                </svg>
                                {Number(slice.point.data.y).toFixed(2)}% of {slice.point.serieId}
                            </div>
                            {otherData.map((d) => (
                                <div
                                    key={d.id}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginRight: 8,
                                    }}
                                >
                                    <svg
                                        style={{ height: '10px', width: '10px', marginRight: 4 }}
                                        viewBox="0 0 10 10"
                                    >
                                        {/* <circle
                                            cx="50%"
                                            cy="50%"
                                            r="4"
                                            fill={`${slice.point.color}`}
                                        /> */}
                                    </svg>
                                    {Number(d.y).toFixed(2)}% of {d.id}
                                </div>
                            ))}
                        </div>
                    )
                }}
                legends={[
                    {
                        anchor: 'top-right',
                        direction: 'column',
                        // justify: true,
                        translateX: 0,
                        translateY: -75,
                        itemsSpacing: 0,
                        itemDirection: 'right-to-left',
                        itemWidth: 80,
                        itemHeight: 16,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
                axisLeft={{
                    legend: '% out of Total Users that have engaged',
                    legendPosition: 'middle',
                    legendOffset: -32,
                }}
                useMesh
                axisBottom={{
                    tickValues: '4',
                    format: (a) => format(new Date(a), 'MM-yy'),
                    tickRotation,
                    tickPadding,
                }}
            />
        </div>
    )
}

export default Graph
