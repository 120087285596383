import { formatRFC3339, subMonths } from 'date-fns'
import switchesOverTimeDocument from 'graphql/queries/switchesOverTime'
import * as React from 'react'
import * as BiIcons from 'react-icons/bi'

import { useQuery } from '@apollo/client'
import { Button, Grid, MenuItem, Select, Typography } from '@material-ui/core'

import * as styles from '../../../common/CommonStyles'
import EnergyAnalysisPercentageSubmit from './energyAnalysisPercentageSubmit'
import EnergyAnalysisPercentageUpload from './energyAnalysisPercentageUpload'

const periods = ['1 Month', '3 Months', '6 Months', '12 Months', 'All Time']
const mo1 = subMonths(new Date(), 1)
const mo3 = subMonths(new Date(), 3)
const mo6 = subMonths(new Date(), 6)
const mo12 = subMonths(new Date(), 12)
const at = new Date('2020-01-01T00:00:00')
const endDate = formatRFC3339(new Date())

export const EnergyAnalysis = () => {
    // return ''
    const [period, setPeriod] = React.useState(periods[1])
    const dateToUs =
        period === '1 Month'
            ? mo1
            : period === '3 Months'
            ? mo3
            : period === '6 Months'
            ? mo6
            : period === '12 Months'
            ? mo12
            : at
    const startDate = formatRFC3339(dateToUs)
    const { loading, error, data } = useQuery(switchesOverTimeDocument, {
        fetchPolicy: 'cache-first',
        variables: {
            startDate,
            endDate,
        },
    })
    const [view, setView] = React.useState('total')
    if (loading && !data) return <div>Loading</div>
    if (error) return <div>{console.error(error)}error</div>
    return (
        <div>
            <BiIcons.BiAbacus size={35} style={{ display: 'inline-block' }} />
            <h1
                style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                className={styles.MainHeaderClass}
            >
                Energy Analysis
            </h1>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                        <Button
                            style={{
                                border: view === 'total' ? '1px solid rgba(225, 66, 153, 0.6)' : '',
                                marginRight: 4,
                            }}
                            color={view === 'total' ? 'secondary' : 'default'}
                            onClick={() => setView('total')}
                        >
                            Total Users
                        </Button>
                        <Button
                            style={{
                                border:
                                    view === 'usergroup' ? '1px solid rgba(225, 66, 153, 0.6)' : '',
                                marginRight: 4,
                            }}
                            color={view === 'usergroup' ? 'secondary' : 'default'}
                            onClick={() => setView('usergroup')}
                        >
                            By UserGroup
                        </Button>
                        <Button
                            style={{
                                border: view === 'group' ? '1px solid rgba(225, 66, 153, 0.6)' : '',
                                marginRight: 4,
                            }}
                            color={view === 'group' ? 'secondary' : 'default'}
                            onClick={() => setView('group')}
                        >
                            By Group
                        </Button>
                    </Grid>
                </Typography>
                <div style={{ justifySelf: 'end' }}>
                    <Select
                        labelId="pick-type"
                        id="pick-type"
                        value={period}
                        onChange={(event) => setPeriod(event.target.value as string)}
                    >
                        {periods.map((p) => (
                            <MenuItem value={p}>{p}</MenuItem>
                        ))}
                    </Select>
                </div>
            </div>
            <div>
                <div style={{ margin: '0 48px' }}>
                    {/* <EnergyAnalysisPercentageSubmit
                        period={period}
                        data={data.energyAnalysis}
                        view={view}
                    /> */}
                    <EnergyAnalysisPercentageUpload
                        period={period}
                        data={data.energyAnalysis}
                        view={view}
                    />
                </div>
            </div>
        </div>
    )
}
