import * as React from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import users from 'graphql/queries/users'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../common/CommonStyles'
import NgoLandingContent from './content'
import ngoAnalysis from 'graphql/queries/ngoAnalysis'
import { MenuItem, Select } from '@material-ui/core'
import userGroups from 'graphql/queries/userGroups'
import { AuthContext } from 'App'
import NgoLandingSummaryTable from './summary'

const NgoLanding = () => {
    const [q, result] = useLazyQuery(ngoAnalysis)
    const [ngoCode, setNgoCode] = React.useState<null | string>(null)
    const { loading, error, data } = useQuery(userGroups)
    const auth = React.useContext(AuthContext)

    React.useEffect(() => {
        if (ngoCode)
            q({
                variables: {
                    NgoCode: ngoCode,
                },
            })
    }, [ngoCode])
    if (loading) return <div>Loading</div>
    if (error) return <div>{error}</div>
    if (result.loading) return <div>Loading</div>
    if (result.error) return <div>{result.error}</div>

    const ngos = data.userGroups.filter((a) => a.Type === 'Organisation')
    return (
        <div>
            <BiIcons.BiUserCircle size={35} style={{ display: 'inline-block' }} />
            <h1
                style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                className={CommonStyles.MainHeaderClass}
            >
                Channel Analysis
            </h1>
            <div style={{ float: 'right' }}>
                <Select onChange={(e) => setNgoCode(e.target.value as string)} value={ngoCode}>
                    {ngos.map((p, i) => (
                        <MenuItem key={p.Name + i} value={p.Name}>{p.Name}</MenuItem>
                    ))}
                </Select>
            </div>
            {result.data ? (
                <NgoLandingContent
                    ngoName={ngos.find((a) => a.Name == ngoCode)!.Name}
                    data={result.data.ngoAnalysis}
                />
            ) : (
                <NgoLandingSummaryTable />
            )}
        </div>
    )
}

export default NgoLanding
