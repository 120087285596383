import gql from 'graphql-tag'

export default gql`
    query merchants {
        merchants {
            items {
                # type
                # class
                # direction
                merchant_id
                data {
                    merchant {
                        businessName
                    }
                    # location {
                    #   suburb
                    # }
                    category {
                        anzsic {
                            # division {
                            #   code
                            #   title
                            # }
                            # subdivision {
                            #   code
                            #   title
                            # }
                            group {
                                # code
                                title
                            }
                            # class {
                            #   code
                            #   title
                            # }
                        }
                    }
                }
                # links {
                #   logoMaster
                #   logoThumb
                # }
                # customBucket
                # acaciaBucket
                # categoryBucket
            }
        }
    }
`
