import * as React from 'react'
import MaterialTable, { Icons } from 'material-table'
import { tableIcons } from 'components/common/MaterialIcons'

const List = ({ userGroups }) => {
    console.log(userGroups)

    let thisdata = userGroups.map((a) => {
        return {
            Name: a.Name,
            Type: a.Type,
        }
    })
    return (
        <MaterialTable
            icons={tableIcons as Icons}
            title={''}
            columns={[
                { title: 'Name', field: 'Name' },
                { title: 'Type', field: 'Type' },
            ]}
            data={thisdata}
            options={{
                showTitle: false,
                sorting: true,
                filtering: true,
                search: true,
                pageSize: 50,
            }}
        />
    )
}

export default List
