import gql from 'graphql-tag'

export default gql`
    query getReleases {
        getReleases {
            ReleaseID
            Description
            Date
            Repos
            Number
        }
    }
`
