import gql from 'graphql-tag'

export default gql`
    query institutions {
        institutions {
            items {
                institutionCode
                cdrUrl
                lastSync
                name
                subType
                type
                syncError

                issData {
                    carbonInitative
                    policy
                    sustainableEnergy
                    score
                }
                SyncRuns {
                    ID
                    Date
                    Status
                    Error
                    LogGroup
                    LogStream
                }
            }
        }
    }
`
