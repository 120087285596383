import * as React from 'react'
import { TableHead, Table, TableRow, TableCell, TableBody, Button } from '@material-ui/core'
import { convertFromUnixTime } from './utils'
import { Box, Spinner } from '@chakra-ui/react'
import * as GraphTypes from '../../../graphql/types'
interface Props {
    products: GraphTypes.Product[]
    updates: GraphTypes.ProductUpdate[]
    handleEditOpen: (type: 'Eligibility' | 'DepositRates') => (row: any) => void
    loading: boolean
}

const RenderTable = ({ products, updates, handleEditOpen, loading }: Props) => {
    const merged: any = products!
        .map((p) => {
            const found = updates.filter(
                (u) => u.institutionCodeProductID === `${p.institutionCode}+${p.productID}`,
            )
            return { ...p, productUpdate: found }
        })
        .sort((p) => {
            if (p.productUpdate.length > 0) return -1
            return 1
        })
        .map((p) => {
            const found = updates.filter(
                (u) => u.institutionCodeProductID === `${p.institutionCode}+${p.productID}`,
            )
            if (found) {
                if (found.length === 2) return { ...p, productUpdate: found }
                if (found.length === 1) {
                    const missingKey =
                        found[0].updatedKey === 'DepositRates' ? 'Eligibility' : 'DepositRates'
                    return {
                        ...p,
                        productUpdate: found.concat({
                            updatedKey: missingKey,
                            state: 'NEW',
                            institutionCodeProductID: null,
                            updatedValue: null,
                        }),
                    }
                }
            }
            return {
                ...p,
                productUpdate: ['DepositRates', 'Eligibility'].map((x) => ({
                    state: 'NEW',
                    updatedKey: x,
                })),
            }
        })

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>InstitutionCode</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Has updated</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Created Date</TableCell>
                    <TableCell>Updated Date</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            {loading ? (
                <Box pl="4" pt="4">
                    <Spinner />
                </Box>
            ) : (
                <TableBody>
                    {merged.map((row) => {
                        return (
                            <TableRow key={row.name}>
                                <TableCell>
                                    {row.Institution?.name} {row.institutionCodeProductID}
                                </TableCell>
                                {/* <TableCell>{row.productID}</TableCell> */}
                                <TableCell>{row.name}</TableCell>
                                <TableCell>
                                    {row.productUpdate?.map((pu, i) => (
                                        <div key={row.name + pu.updatedKey + i}>
                                            {pu.updatedKey}
                                            <br />
                                        </div>
                                    ))}
                                </TableCell>
                                <TableCell>
                                    {row.productUpdate?.map((pu, i) => (
                                        <div key={row.name + pu.state + i}>
                                            {pu.state}
                                            <br />
                                        </div>
                                    ))}
                                </TableCell>
                                <TableCell>
                                    {row.productUpdate?.map((pu, i) => (
                                        <div key={row.name + pu.dateCreated + i}>
                                            {convertFromUnixTime(pu.dateCreated)}
                                            <br />
                                        </div>
                                    ))}
                                </TableCell>
                                <TableCell>
                                    {row.productUpdate?.map((pu, i) => (
                                        <div key={row.name + pu.dateUpdated + i}>
                                            {convertFromUnixTime(pu.dateUpdated)}
                                            <br />
                                        </div>
                                    ))}
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEditOpen('DepositRates')(row)}>
                                        Edit Deposit Rate
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEditOpen('Eligibility')(row)}>
                                        Edit Eligibility
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            )}
        </Table>
    )
}

export default RenderTable
