import * as React from 'react'
import { useQuery } from '@apollo/client'
import ngoAnalysis from 'graphql/queries/ngoAnalysis'
import { TableHead, Table, TableRow, TableCell, TableBody, Tooltip } from '@material-ui/core'

const NgoLandingSummaryTable = () => {
    const result = useQuery(ngoAnalysis)
    if (result.loading) return <div>Loading</div>
    if (result.error) return <div>{result.error}</div>
    if (!result.data.ngoAnalysis.SummaryAnalysis) return <div>No Summary data found!</div>
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Org Name</TableCell>
                    <TableCell>Emailed</TableCell>
                    <TableCell>Users</TableCell>
                    <TableCell>App Login</TableCell>
                    <TableCell>Nav to insight</TableCell>
                    <TableCell>Succesful PDF Uploaded (latest attempt)</TableCell>
                    <TableCell>Failed PDF Uploaded (latest attempt)</TableCell>
                    <TableCell>Click out to provider</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {result.data.ngoAnalysis.SummaryAnalysis.map((a, i) => {
                    return (
                        <TableRow key={a.Name + i}>
                            <TableCell>{a.Name}</TableCell>
                            <TableCell>N/A</TableCell>
                            <TableCell>{a.TotalUsers}</TableCell>
                            <TableCell>
                                {a.TotalNonMicrositeUsers} (
                                {(a.TotalNonMicrositeUsers / a.TotalUsers) * 100}%)
                            </TableCell>
                            {/* <TableCell>{a.TotalEnergyInsightOpenUsers} ({a.TotalEnergyInsightOpenUsers / a.TotalUsers * 100}%)</TableCell> */}
                            <TableCell>
                                <div>
                                    {a.TotalEnergyInsightOpenUsers} (
                                    <Tooltip title="All Users">
                                        <span>
                                            {(
                                                (a.TotalEnergyInsightOpenUsers / a.TotalUsers) *
                                                100
                                            ).toFixed(1)}
                                            %
                                        </span>
                                    </Tooltip>
                                    /{' '}
                                    <Tooltip title="App Users">
                                        <span>
                                            {(
                                                (a.TotalEnergyInsightOpenUsers /
                                                    a.TotalNonMicrositeUsers) *
                                                100
                                            ).toFixed(1)}
                                            %
                                        </span>
                                    </Tooltip>
                                    )
                                </div>
                            </TableCell>
                            <TableCell>
                                <div>
                                    {a.TotalBillUploadUsers} (
                                    <Tooltip title="All Users">
                                        <span>
                                            {(
                                                (a.TotalBillUploadUsers / a.TotalUsers) *
                                                100
                                            ).toFixed(1)}
                                            %
                                        </span>
                                    </Tooltip>{' '}
                                    /{' '}
                                    <Tooltip title="App Users">
                                        <span>
                                            {(
                                                (a.TotalBillUploadUsers /
                                                    a.TotalNonMicrositeUsers) *
                                                100
                                            ).toFixed(1)}
                                            %
                                        </span>
                                    </Tooltip>
                                    )
                                </div>
                            </TableCell>
                            <TableCell>
                                <div>
                                    {a.TotalBillUploadFailureUsers} (
                                    <Tooltip title="All Users">
                                        <span>
                                            {(
                                                (a.TotalBillUploadFailureUsers / a.TotalUsers) *
                                                100
                                            ).toFixed(1)}
                                            %
                                        </span>
                                    </Tooltip>{' '}
                                    /{' '}
                                    <Tooltip title="App Users">
                                        <span>
                                            {(
                                                (a.TotalBillUploadFailureUsers /
                                                    a.TotalNonMicrositeUsers) *
                                                100
                                            ).toFixed(1)}
                                            %
                                        </span>
                                    </Tooltip>
                                    )
                                </div>
                            </TableCell>
                            <TableCell>
                                <div>
                                    {a.TotalEnergySwitchUsers} (
                                    <Tooltip title="All Users">
                                        <span>
                                            {(
                                                (a.TotalEnergySwitchUsers / a.TotalUsers) *
                                                100
                                            ).toFixed(1)}
                                            %
                                        </span>
                                    </Tooltip>{' '}
                                    /{' '}
                                    <Tooltip title="App Users">
                                        <span>
                                            {(
                                                (a.TotalEnergySwitchUsers /
                                                    a.TotalNonMicrositeUsers) *
                                                100
                                            ).toFixed(1)}
                                            %
                                        </span>
                                    </Tooltip>
                                    )
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}

export default NgoLandingSummaryTable
