import * as React from 'react'
import { useQuery } from '@apollo/client'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../common/CommonStyles'
import carbonAnalysis from 'graphql/queries/carbonAnalysis'
import Content from './content'
import { MenuItem, Select } from '@material-ui/core'
import userGroups from 'graphql/queries/userGroups'

const CarbonAnalysis = () => {
    const [group, setGroup] = React.useState('All')

    const { loading, error, data } = useQuery(userGroups)
    if (loading) return <div>Loading</div>
    if (error) return <div>{error}</div>

    return (
        <div>
            <BiIcons.BiUserCircle size={35} style={{ display: 'inline-block' }} />
            <h1
                style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                className={CommonStyles.MainHeaderClass}
            >
                Carbon Analysis
            </h1>
            <div>
                <span>Group: </span>
                <Select onChange={(e) => setGroup(e.target.value as string)} value={group}>
                    <MenuItem value="All">All</MenuItem>
                    {data.userGroups.map((a) => {
                        return (
                            <MenuItem value={a.Name}>
                                {a.Type} - {a.Name}
                            </MenuItem>
                        )
                    })}
                </Select>
            </div>
            <Content group={group == 'All' ? null : group} />
        </div>
    )
}

export default CarbonAnalysis
