import * as React from 'react'
import * as styles from '../../../../common/CommonStyles'
import { subMonths } from 'date-fns'
import SuperUserEngagementGraph from '../../super/superUserEngagementGraph'

const mo6 = subMonths(new Date(), 6)

export const SuperEngagementDashboard = ({ data }) => {
    return (
        <div className={styles.SmallGraphBox}>
            <h3 className={styles.DashHeaderClass}>Super - Pre-switch Engagement</h3>
            <SuperUserEngagementGraph
                title="total"
                selection="SuperEngagementCount"
                type="dash"
                period={mo6}
                data={data}
                view="total"
            />
        </div>
    )
}
