import gql from 'graphql-tag'

export default gql`
    query switchUsers($UserID: String) {
        switchUsers(UserID: $UserID) {
            ID
            UserID
            Type
            Description
            Email
            Status
            Date
            History {
                Content
                Date
                UserID
            }
            Action
            ActionDate
            Provider
        }
    }
`
