import gql from 'graphql-tag'

export default gql`
    query productUpdates {
        productUpdates {
            institutionCodeProductID
            updatedKey
            updatedValue
            state
            comments
            uneditedValue
            dateCreated
            dateUpdated
        }
    }
`
