import gql from 'graphql-tag'

export default gql`
    query getPushes {
        getPushes {
            UserID
            ID
            Type
            Status
            Message
            Navigation
            DateCreated
            DateSent
            DateOpened
            Title
            CampaignID
        }
    }
`
