import * as React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import * as styles from '../../../styles'
import * as CommonStyles from '../../../../common/CommonStyles'
import { Tooltip } from '@material-ui/core'
import { format } from 'date-fns'
import * as GraphTypes from 'graphql/types'

type Props = {
    data: GraphTypes.SwitchesResult[]
}

const Graph = ({ data }: Props) => {
    const tickRotation = 32
    const tickPadding = 4

    const results: any[] = []

    // const getValueAsPercentage = (users: number, date: string): number => {
    //     const total = data.filter(a => a.Date === date)[0].TotalUsers?.Total
    //     console.log(total)
    //     return Math.round(users / Number(total) * 100)
    // }

    data.forEach((d) => {
        results.push({
            Date: d.Date!,
            None: d.UserInteractions![0],
            One: d.UserInteractions![1],
            Two: d.UserInteractions![2],
            Three: d.UserInteractions![3],
        })
    })

    return (
        <div className={CommonStyles.DashGraphContainer}>
            <ResponsiveBar
                data={results}
                enableLabel={false}
                indexBy="Date"
                groupMode="grouped"
                tooltipLabel={(d) => `${d.id} - ${format(new Date(d.indexValue), 'PPP')}`}
                keys={['None', 'One', 'Two', 'Three']}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                margin={{ top: 10, right: 45, bottom: 35, left: 40 }}
                label={(d) => `${d.id as string}`}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top-right',
                        direction: 'column',
                        translateX: 30,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'right-to-left',
                        itemWidth: 80,
                        itemHeight: 16,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
                axisBottom={{
                    tickValues: 4,
                    format: (a) => format(new Date(a), 'MM-yy'),
                    tickRotation,
                    tickPadding,
                }}
                axisLeft={{
                    legend: 'Total Users that have interacted with a feature',
                    legendPosition: 'middle',
                    legendOffset: -34,
                }}
            />
        </div>
    )
}

export default Graph
