// Display Name in the App
// Also key used to get company logos

export enum INSTITUTIONS {
    // BANKS
    AMP = 'AMP',
    ANZ = 'ANZ Bank',
    ADELAIDE_BANK = 'Adelaide Bank',
    BANK_AUSTRALIA = 'Bank Australia',
    AMERICAN_EXPRESS = 'American Express',
    BANK_OF_MELBOURNE = 'Bank of Melbourne',
    BANK_OF_SOUTH_AUSTRALIA = 'Bank of South Australia',
    BANK_OF_QUEENSLAND = 'Bank of Queensland',
    BANKWEST = 'Bank West',
    BENDIGO_BANK = 'Bendigo Bank',
    BT_PANORAMA = 'BT Panorama',
    CBA = 'Commonwealth Bank',
    CITI = 'Citi',
    CUA = 'CUA',
    GREAT_SOUTHERN_BANK = 'Great Southern Bank',
    HERITAGE_BANK = 'Heritage Bank',
    HSBC = 'HSBC',
    ING = 'ING Bank',
    MACQUARIE_BANK = 'Macquarie Bank',
    ME_BANK = 'ME Bank',
    MOVE_BANK = 'MOVE Bank',
    MYSTATE_BANK = 'MyState Bank',
    NAB = 'National Australia Bank',
    NEWCASTLE_PERMANENT = 'Newcastle Permanent Building Society',
    QBANK = 'QBANK',
    QUDOS_BANK = 'Qudos Bank',
    RABOBANK = 'Rabobank',
    RACQ = 'RACQ Bank',
    SUNCORP = 'Suncorp',
    ST_GEORGE = 'St. George',
    WESTPAC = 'Westpac',
    UBANK = 'UBank',
    VOLT = 'Volt Bank',
    VIRGIN = 'Virgin Money',
    WAW_CREDIT_UNION = 'WAW Credit Union',

    // SUPER
    AMG_SUPER = 'AMG Super',
    AUSTRALIAN_CATHOLIC_SUPER = 'Australian Catholic Super',
    AUSTRALIANSUPER = 'AustralianSuper',
    AUSTRALIAN_RETIREMENT_TRUST = 'Australian Retirement Trust',
    AUS_ETHICAL = 'Australian Ethical Super',
    AWARE_SUPER = 'Aware Super',
    CARESUPER = 'CareSuper',
    CATHOLIC_SUPER = 'Catholic Super', // different to Australian Catholic Super
    CBUS = 'CBUS',
    EQUIP_MYFUTURE = 'Equip MyFuture',
    ELEVATE_SUPER = 'Elevate Super',
    ENERGY_SUPER = 'Energy Super',
    FIRST_SUPER = 'First Super',
    HESTA = 'HESTA',
    HOSTPLUS = 'Hostplus',
    LGIA_SUPER = 'LGIAsuper',
    INTRUST_SUPER = 'Intrust Super',
    PRIME_SUPER = 'Prime Super',
    PLUM_SUPER = 'Plum Super',
    NGS_SUPER = 'NGS Super',
    QSUPER = 'QSuper',
    SMART_MONDAY = 'smartMonday by Aon DIRECT',
    SPIRIT_SUPER = 'Spirit Super',
    SUNSUPER = 'Sunsuper',
    REST_SUPER = 'Rest Super',
    UNISUPER = 'UniSuper',
    VICSUPER = 'VicSuper',
    VISION_SUPER = 'Vision Super',

    // ENERGY
    FIRST_ENERGY = '1st Energy',
    AGL = 'AGL',
    ALINTA_ENERGY = 'Alinta Energy',
    AMBER_ELECTRIC = 'Amber Electric',
    ARC_ENERGY_GROUP = 'Arc Energy Group',
    BRIGHTE_ENERGY = 'Brighte Energy',
    CIRCULAR_ENERGY = 'Circular Energy',
    COVAU = 'CovaU',
    DIAMOND_ENERGY = 'Diamond Energy',
    DISCOVER_ENERGY = 'Discover Energy',
    DODO_POWER_AND_GAS = 'Dodo Power & Gas',
    ELYSIAN_ENERGY = 'Elysian Energy',
    ENERGY_LOCALS = 'Energy Locals',
    ENERGYAUSTRALIA = 'EnergyAustralia',
    ENOVA_ENERGY = 'Enova Energy',
    FUTURE_X_POWER = 'Future X Power',
    GLO_BIRD = 'Glo Bird',
    GLOW_POWER = 'Glow Power',
    KOGAN_ENERGY = 'Kogan Energy',
    MOJO_POWER = 'Mojo Power',
    MOMENTUM_ENERGY = 'Momentum Energy',
    NECTR = 'Nectr',
    OVO_ENERGY = 'OVO Energy',
    ORIGIN_ENERGY = 'Origin Energy',
    PEOPLE_ENERGY = 'People Energy',
    POWERCLUB = 'Powerclub',
    POWERDIRECT = 'Powerdirect',
    POWERSHOP = 'Powershop',
    QENERGY = 'QEnergy',
    RADIAN_ENERGY = 'Radian Energy',
    REAMPED_ENERGY = 'ReAmped Energy',
    RED_ENERGY = 'Red Energy',
    SIMPLE_ENERGY = 'Simply Energy',
    SOCIAL_ENERGY = 'Social Energy',
    SONNEN = 'Sonnen',
    SUMO = 'Sumo',
    TANGO = 'Tango',

    // REWARD
    BETACARBON = 'BetaCarbon',
}
