import * as React from 'react'
import * as GraphTypes from 'graphql/types'
import * as styles from '../../../../common/CommonStyles'
import * as BiIcons from 'react-icons/bi'
import Graph from './graph'

type Props = {
    data: GraphTypes.SwitchesResult[]
}

const UserInteractionsGraph = ({ data }: Props) => {
    return (
        <div className={styles.SmallGraphBox}>
            <h1 className={styles.DashHeaderClass}>USER INTERACTIONS</h1>
            <Graph data={data} />
        </div>
    )
}

export default UserInteractionsGraph
