import * as React from 'react'
import {
    Badge,
    Box,
    Button,
    ButtonGroup,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Text,
    ThemingProps,
    useBreakpointValue,
} from '@chakra-ui/react'
import { FiSearch } from 'react-icons/fi'
import { MemberTable, Column, RowData } from './MemberTable'
import { debounce } from '@material-ui/core'

interface TableProps {
    columns: Column[]
    data: RowData[]
    title: string
    fieldsToSearch: string[]
    itemsPerPage?: number
    asyncFetchOnTableUpdate?: (row: RowData[]) => Promise<any> | void
    subtitle?: string
    badge?: {
        colorScheme: ThemingProps['colorScheme']
        text: string
    }
    extra?: JSX.Element
}

type TableData<T extends RowData> = T[]

function searchTable<T extends RowData>(
    searchTerm: string,
    tableData: TableData<T>,
    columns: Column[],
    fieldsToSearch: string[]
): TableData<T> {
    if (!searchTerm) return tableData
    const lowerSearchTerm = searchTerm.toLowerCase()
    return tableData.filter((row) => {
        return fieldsToSearch.some((field) => {
            if (!row[field]) return false
            const value = row[field].toString().toLowerCase()
            const column = columns.filter((x) => x.key === field)
            const formatter = column[0]?.format ?? (() => '')
            const formattedValue = formatter(row).toLowerCase()
            return value.includes(lowerSearchTerm) || formattedValue.includes(lowerSearchTerm)
        })
    })
}

export const ChakraTable = ({
    data,
    title = 'Members',
    subtitle,
    columns,
    itemsPerPage = 20,
    fieldsToSearch = [],
    badge,
    asyncFetchOnTableUpdate,
    extra,
}: TableProps) => {
    const isMobile = useBreakpointValue({ base: true, md: false })
    const [searchTerm, setSearchTerm] = React.useState('')
    const [currentPage, setCurrentPage] = React.useState(1)

    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    const onChange = (event) => {
        setSearchTerm(event ?? '')
    }

    const debouncedChangeHandler = React.useMemo(() => {
        const changeHandler = (event) => {
            onChange(event)
            setCurrentPage(1) // Reset page when searching
        }

        return debounce(changeHandler, 500)
    }, [])

    const filteredData = searchTable(searchTerm, data, columns, fieldsToSearch)
    const totalPages = Math.ceil(filteredData.length / itemsPerPage)
    const slicedData = filteredData.slice(startIndex, endIndex)

    const handlePageChange = (page: number) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page)
        }
    }

    React.useEffect(() => {
        if (asyncFetchOnTableUpdate) {
            asyncFetchOnTableUpdate(slicedData)
        }
    }, [searchTerm, currentPage])

    return (
        <Box
            bg="bg.surface"
            boxShadow={{ base: 'none', md: 'sm' }}
            borderRadius={{ base: 'none', md: 'lg' }}
        >
            <Stack spacing="5">
                <Box px={{ base: '4', md: '6' }} pt="5">
                    <Stack
                        direction={{ base: 'column', md: 'row' }}
                        display="flex"
                        justify="space-between"
                    >
                        <HStack>
                            <div>
                                <HStack>
                                    <Text fontSize="18px" fontWeight="medium">
                                        {title}
                                    </Text>
                                    {badge ? (
                                        <Badge
                                            borderRadius={16}
                                            paddingX="8px"
                                            paddingY="2px"
                                            colorScheme={badge.colorScheme}
                                            fontWeight="medium"
                                            fontSize="12px"
                                            textTransform="none"
                                        >
                                            {badge.text}
                                        </Badge>
                                    ) : null}
                                </HStack>
                                {subtitle ? (
                                    <Text fontSize="14px" fontWeight="normal" color="#667085">
                                        {subtitle}
                                    </Text>
                                ) : null}
                            </div>
                            {extra ?? null}
                        </HStack>
                        <InputGroup maxW="xs">
                            <InputLeftElement pointerEvents="none">
                                <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                            </InputLeftElement>
                            <Input
                                onChange={(e) => debouncedChangeHandler(e.target.value)}
                                placeholder="Search"
                            />
                        </InputGroup>
                    </Stack>
                </Box>
                <Box overflowX="auto">
                    <MemberTable data={slicedData} columns={columns} />
                </Box>
                <Box px={{ base: '4', md: '6' }} pb="5">
                    <HStack spacing="3" justify="space-between">
                        <ButtonGroup
                            spacing="3"
                            justifyContent="space-between"
                            width={{ base: 'full', md: 'auto' }}
                            variant="secondary"
                        >
                            <Button
                                border="1px solid #d0d5dd"
                                onClick={() => handlePageChange(currentPage - 1)}
                            >
                                <Text fontWeight="medium" fontSize="14px" color="#344054">
                                    {'<- '} Previous
                                </Text>
                            </Button>
                        </ButtonGroup>
                        <Text color="fg.muted" textStyle="sm">
                            Showing page {totalPages === 0 ? 0 : currentPage} of {totalPages}
                        </Text>
                        <ButtonGroup
                            spacing="3"
                            justifyContent="space-between"
                            width={{ base: 'full', md: 'auto' }}
                            variant="secondary"
                        >
                            <Button
                                border="1px solid #d0d5dd"
                                onClick={() => handlePageChange(currentPage + 1)}
                            >
                                <Text fontWeight="medium" fontSize="14px" color="#344054">
                                    Next {' ->'}
                                </Text>
                            </Button>
                        </ButtonGroup>
                    </HStack>
                </Box>
            </Stack>
        </Box>
    )
}
