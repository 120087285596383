import * as React from 'react'
import { useQuery } from '@apollo/client'
import {
    TableHead,
    Table,
    TableRow,
    TableCell,
    TableBody,
    MenuItem,
    Select,
    Button,
} from '@material-ui/core'
import * as GraphTypes from 'graphql/types'
import { Link } from 'react-router-dom'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../../common/CommonStyles'
import providerAccounts from 'graphql/queries/providerAccounts'

const FailedAccounts = () => {
    const { loading, error, data } = useQuery(providerAccounts, { fetchPolicy: 'no-cache' })
    const [showAll, setShowAll] = React.useState(false)

    if (loading) return <div>Loading</div>
    if (error) return <div>failure {console.error(error)} </div>
    const found: string[] = []
    // const ignored = d.ignoredProducts.items.map((a) => a.productDescription)
    const accounts = (data.providerAccounts as GraphTypes.ProviderAccounts[])
        .filter((a) => a.Status !== 'SUCCESS')
        .filter((a) => !showAll && a.AdditionalStatus !== 'INCORRECT_CREDENTIALS')
        .filter((a) => !showAll && a.AdditionalStatus !== 'ACCOUNT_LOCKED')
        .filter((a) => !showAll && a.AdditionalStatus !== 'ADDL_AUTHENTICATION_REQUIRED')
        .filter((a) => !showAll && a.AdditionalStatus !== 'INVALID_ADDL_INFO_PROVIDED')
        .filter((a) => !showAll && a.AdditionalStatus !== 'USER_ACTION_NEEDED_AT_SITE')
        .filter((a) => !showAll && a.AdditionalStatus !== 'INCORRECT_CREDENTIALS')
        .filter((a) => !showAll && a.AdditionalStatus !== 'INCORRECT_CREDENTIALS')

    return (
        <div>
            <BiIcons.BiWater size={35} style={{ display: 'inline-block' }} />
            <h1
                style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                className={CommonStyles.MainHeaderClass}
            >
                Failed Accounts
            </h1>
            <Button onClick={() => setShowAll(!showAll)}>
                Show{showAll ? 'User required changes' : 'All'}
            </Button>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>UserID</TableCell>
                        <TableCell>Institution</TableCell>
                        <TableCell>ProviderAccountID</TableCell>
                        <TableCell>ProviderID</TableCell>
                        <TableCell>Last Sync Attempt</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Consecutive Failures</TableCell>
                        <TableCell>Message</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {accounts.map((row) => {
                        return (
                            <TableRow>
                                <TableCell>
                                    <Link to={`/users/${row.UserID}`}>{row.UserID}</Link>
                                </TableCell>
                                <TableCell>{row.ProviderName}</TableCell>
                                <TableCell>{row.ProviderAccountID}</TableCell>
                                <TableCell>{row.ProviderID}</TableCell>
                                <TableCell>{row.LastUpdateAttempt}</TableCell>
                                <TableCell>{row.Status}</TableCell>
                                <TableCell>{row.ConsecutiveFailures}</TableCell>
                                <TableCell>{row.AdditionalStatus}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </div>
    )
}

export default FailedAccounts
