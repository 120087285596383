import gql from 'graphql-tag'

export default gql`
    query ngoAnalysis($NgoCode: String) {
        ngoAnalysis(NgoCode: $NgoCode) {
            Users {
                user_id
                yodlee_user_id
                avarni_user_id
                uno_user_id
                email
                lastLogin
                loginCount
                PushToken
                group
                version
                UserHasConsented
                userGroup
            }
            Switches {
                ID
                Type
                Description
                Status
                Date
            }
            Redeems {
                ID
                Partner
                NewAmountAUD
                CurrentAmount
                Value
                Status
                DateStarted
                DateUpdated
            }
            CurrentInsights {
                Domain
                UserID
                Current
                GeneratedAt
            }
            SummaryAnalysis {
                Name
                TotalUsers
                TotalNonMicrositeUsers
                TotalEnergyInsightOpenUsers
                TotalBillUploadUsers
                TotalBillUploadFailureUsers
                TotalEnergySwitchUsers
            }
        }
    }
`
