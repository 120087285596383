import * as React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import institutions from 'graphql/queries/institutions'
import InstitutionList from './InstitutionList'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../../common/CommonStyles'
import cdrSync from 'graphql/mutations/cdrSync'
import { Button } from '@chakra-ui/react'
import ProductImport from './productImport'

const Users = () => {
    const { loading, error, data } = useQuery(institutions)
    const [runSync] = useMutation(cdrSync, {
        onCompleted: (msg) => {
            console.log(msg)
            setSyncUrl(msg.cdrSync)
        },
    })
    const [syncUrl, setSyncUrl] = React.useState('')
    if (loading) return <div>Loading</div>
    if (error) return <div>{error}</div>
    return (
        <div>
            <div>
                <BiIcons.BiLayer size={35} style={{ display: 'inline-block' }} />
                <h1
                    style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                    className={CommonStyles.MainHeaderClass}
                >
                    Institution Status
                </h1>
            </div>
            <div>
                {syncUrl == '' ? (
                    <Button onClick={() => runSync()}>Run CDR Sync</Button>
                ) : (
                    <div>
                        <a href={syncUrl} target="_blank" rel="noreferrer">
                            GO TO EXECUTION!
                        </a>
                    </div>
                )}
            </div>
            <ProductImport />
            <InstitutionList items={data.institutions.items} />
        </div>
    )
}

export default Users
