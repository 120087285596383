import { Logo } from 'components/common/Logo'
import React from 'react'
import * as BiIcons from 'react-icons/bi'
import { Link } from 'react-router-dom'

import {
    Box,
    BoxProps,
    Center,
    Flex,
    Img,
    Stack,
    useColorModeValue as mode,
} from '@chakra-ui/react'

import { AccountSwitcher } from '../common/AccountSwitcher'
import { NavGroup } from '../common/NavGroup'
import { NavItem } from '../common/NavItem'
import { AuthContext } from 'App'
import { getLogoUrl } from './logos'

export default function NgoSidebar({ children }: BoxProps) {
    const auth = React.useContext(AuthContext)

    const ngoName = auth.token!['http://acacia.com/orgadmin'].org
    return (
        <Box height="100vh" overflow="hidden" position="relative" zIndex="1">
            <Flex h="full" id="app-container">
                <Box w="64" bg="gray.900" color="white" fontSize="sm">
                    <Flex h="full" direction="column" px="4" py="4">
                        <Link to="/">
                            <Center>
                                <Img src={getLogoUrl(ngoName)} />
                            </Center>
                        </Link>
                        <Stack spacing="8" flex="1" overflow="auto" pt="4">
                            <Stack spacing="1">
                                <NavItem to="/" icon={<BiIcons.BiArch />} label="Dashboard" />
                            </Stack>
                            <NavGroup label="Prototypes">
                                <NavItem
                                    to="/widgets/carbon"
                                    icon={<BiIcons.BiToggleLeft />}
                                    label="Carbon widget"
                                />
                                <NavItem
                                    to="/widgets/energy"
                                    icon={<BiIcons.BiToggleLeft />}
                                    label="Energy widget"
                                />
                            </NavGroup>
                        </Stack>
                        <Box>
                            <Stack spacing="1">
                                <AccountSwitcher />
                            </Stack>
                        </Box>
                    </Flex>
                </Box>
                <Box bg={mode('white', 'gray.800')} flex="1" p="6" overflow="scroll">
                    {children}
                </Box>
            </Flex>
        </Box>
    )
}
