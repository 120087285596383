import { Datum, Serie } from '@nivo/line'

export type ConnectionResultDate = {
    Date: string
    UserCount: UserSplitCount
    ConnectionCount: UserSplitCount
}

type UserSplitCount = {
    Total: string
    GroupSplit: [UserGroup]
}

type UserGroup = {
    Name: string
    Count: string
}
const formatDate = (dateStr: string): Date => {
    return new Date(dateStr)
}

export const formatLineGraphTotalsAsPercentage = (
    data: ConnectionResultDate[],
    field: string,
    view: string,
): Serie[] => {
    if (view == 'total') {
        const percentage: Datum[] = []
        data.forEach((d) => {
            percentage.push({
                x: formatDate(d.Date),
                y: (Number(d[field].Total) / Number(d.UserCount.Total)) * 100,
            })
        })
        return [
            {
                id: field + 'Percentage',
                data: percentage,
            },
        ]
    } else {
        const results: Serie[] = []
        data.forEach((d) => {
            ;(d[field].GroupSplit as [UserGroup]).forEach((group) => {
                const seriesForThisGroup = results.filter((a) => a.id == group.Name)
                if (seriesForThisGroup.length) {
                    seriesForThisGroup[0].data.push({
                        x: formatDate(d.Date),
                        y:
                            (Number(group.Count) / Number(getUserCountForGroup(d, group.Name))) *
                            100,
                    })
                } else {
                    results.push({
                        data: [
                            {
                                x: formatDate(d.Date),
                                y:
                                    (Number(group.Count) /
                                        Number(getUserCountForGroup(d, group.Name))) *
                                    100,
                            },
                        ],
                        id: group.Name,
                    })
                }
            })
        })
        return results
    }
}

const getUserCountForGroup = (dateResult: ConnectionResultDate, group: string): number => {
    const r = dateResult.UserCount.GroupSplit.filter((s) => s.Name == group)
    if (!r.length) throw new Error('User group not found')
    return Number(r[0].Count)
}
