import gql from 'graphql-tag'

export default gql`
    query energyAnalysis($startDate: String, $endDate: String) {
        energyAnalysis(startDate: $startDate, endDate: $endDate) {
            Date
            UserCount {
                Total
                GroupSplit {
                    Name
                    Count
                }
                UserGroupSplit {
                    Name
                    Count
                }
            }
            SubmitCount {
                Total
                UserGroupSplit {
                    Name
                    Count
                }
                GroupSplit {
                    Name
                    Count
                }
            }
            UploadCount {
                Total
                UserGroupSplit {
                    Name
                    Count
                }
                GroupSplit {
                    Name
                    Count
                }
            }
        }
    }
`
