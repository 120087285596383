import gql from 'graphql-tag'

export default gql`
    mutation setFeature(
        $key: String!
        $feature: Boolean!
        $UserGroup: String!
        $description: String!
    ) {
        setFeature(key: $key, feature: $feature, UserGroup: $UserGroup, description: $description)
    }
`
