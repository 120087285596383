import gql from 'graphql-tag'

export default gql`
    query accounts($userID: String) {
        accounts(userID: $userID) {
            items {
                productId
                institutionCode
                balance
                accountNo
                name
                businessName
                currency
                balance
                availableFunds
                product {
                    name
                }
                institution {
                    name
                    institutionCode
                }
                class {
                    product
                    type
                }
                customType
                ProviderAccountID
                ignoreMapping
                superProductID
                superProduct {
                    LongName
                }
                IsTestAccount
            }
        }
    }
`
