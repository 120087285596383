import gql from 'graphql-tag'

export default gql`
    query getReleaseStatus {
        getReleaseStatus {
            RepoName
            LastTag
            Commits {
                Message
                Name
                Email
                Time
            }
            Pulls {
                Name
                Author
                URL
            }
        }
    }
`
