// @ts-nocheck
import React from 'react'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider, InMemoryCache, ApolloClient, from, createHttpLink } from '@apollo/client'
import { createAuthLink, AuthOptions, AUTH_TYPE } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

import { useAuth0 } from './components/auth/react-auth0-wrapper'
import appSyncConfig from './config/aws-exports'

import LoginScreen from './components/auth/LoginScreen'
import Sidebar from './components/core/Sidebar'
import Routes from './components/core/Routes'
import theme from './theme'
import Spinner from 'components/common/Spinner'
import { IdToken } from '@auth0/auth0-spa-js'
import NgoSidebar from 'components/coreNgo/Sidebar'
import NgoRoutes from 'components/coreNgo/Routes'

function App() {
    return (
        <div className="App">
            <ChakraProvider theme={theme} resetCSS>
                <ColorModeScript initialColorMode={theme.config.initialColorMode} />
                <AppSetup />
            </ChakraProvider>
        </div>
    )
}

export type ClaimsType = {
    'http://acacia.com/orgadmin'?: string
}

type AuthContextType = {
    token?: IdToken
}

export const AuthContext = React.createContext<AuthContextType>({})

function AppSetup() {
    const { getIdTokenClaims, getTokenSilently, loading, isAuthenticated, token, logout } =
        useAuth0()
    React.useMemo(async () => {
        if (!loading && !isAuthenticated) {
            const t = await getTokenSilently()
            //console.log(t)
        }
    }, [loading])
    if (loading) {
        return <Spinner />
    }

    // AppSync Config with Authentication
    const url = appSyncConfig.graphqlEndpoint
    const region = appSyncConfig.region

    const auth: AuthOptions = {
        type: AUTH_TYPE.OPENID_CONNECT,
        jwtToken: async (): Promise<string> => {
            const token = (await getIdTokenClaims()) || ''
            return token.__raw
        },
    }

    // ApolloClient with AppSync Config
    const httpLink = createHttpLink({ uri: url })

    const link = from([
        createAuthLink({ url, region, auth }),
        createSubscriptionHandshakeLink(url, httpLink),
    ])

    // Required for type resolution

    const apolloClient = new ApolloClient({
        link,
        cache: new InMemoryCache(),
        connectToDevTools: true,
    })
    if (isAuthenticated) {
        if (
            !(token as any)['http://acacia.com/admin'] &&
            !(token as any)['http://acacia.com/orgadmin'] &&
            !(token as any)['http://acacia.com/staff']
        ) {
            // logout()
            return (
                <div>
                    <div>NO ACCESS</div>
                    <button onClick={() => logout()}>Logout</button>
                </div>
            )
        }
        return (
            <AuthContext.Provider value={{ token: token }}>
                <ApolloProvider client={apolloClient}>
                    <BrowserRouter>
                        {(token as any)['http://acacia.com/orgadmin'] &&
                        (token as any)['http://acacia.com/orgadmin'].org ? (
                            <NgoSidebar>
                                <NgoRoutes />
                            </NgoSidebar>
                        ) : (
                            <Sidebar>
                                <Routes />
                            </Sidebar>
                        )}
                    </BrowserRouter>
                </ApolloProvider>
            </AuthContext.Provider>
        )
    } else return <LoginScreen />
}

export default App
