import { gql } from '@apollo/client'

export default gql`
    mutation processFailedAccount(
        $UserID: String
        $Action: String!
        $AccountID: String
        $ProviderID: String
    ) {
        processFailedAccount(
            UserID: $UserID
            Action: $Action
            AccountID: $AccountID
            ProviderID: $ProviderID
        )
    }
`
