import * as React from 'react'
import * as gql from 'graphql/types'
import TotalEngagementGraph from './totalEngagementGraph'
import EngagementByDomain from './engagementByDomain'
import ScoreByDomain from './scoreByDomain'
import { useQuery } from '@apollo/client'
import carbonAnalysis from 'graphql/queries/carbonAnalysis'

type Props = {
    group: string | null
}

const Content = ({ group }: Props) => {
    const result = useQuery(carbonAnalysis, {
        variables: {
            group: group,
        },
    })
    if (result.loading) return <div>Loading</div>
    if (result.error) return <div>{result.error}</div>

    return (
        <div>
            <h1>Total user engagement - (users who have set up at least 1 domain)</h1>
            <TotalEngagementGraph data={result.data.carbonAnalysis} />
            <h1>Engagement by domain</h1>
            <EngagementByDomain data={result.data.carbonAnalysis} />
            <h1>Average score by domain</h1>
            <ScoreByDomain data={result.data.carbonAnalysis} />
        </div>
    )
}

export default Content
