import gql from 'graphql-tag'

export default gql`
    query regos {
        regos {
            items {
                group
                email
                timestamp
            }
        }
    }
`
