import * as React from 'react'
import { useQuery } from '@apollo/client'

import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../common/CommonStyles'
import { MenuItem, Select } from '@material-ui/core'
import partners from 'graphql/queries/partners'
import MaterialTable, { Icons } from 'material-table'
import { tableIcons } from 'components/common/MaterialIcons'
import { useHistory } from 'react-router-dom'


const Partners = () => {
    const q = useQuery(partners)
    const history = useHistory();

    if (q.loading) return <div>Loading</div>
    if (q.error) return <div>{q.error}</div>
    let data = q.data.partners.map((a) => {
        const total = a.AnalysisCache && a.AnalysisCache.find(a => a.Type == "CalcTotalUsers")?.Data
        return {
            PartnerID: a.ID,
            Name: a.Name,
            CalcTotalUsers: total,
            CalcTotalLoggedInUsers: a.AnalysisCache && a.AnalysisCache.find(a => a.Type == "CalcTotalLoggedInUsers")?.Data,
            CalcCsCompletion:  a.AnalysisCache && (a.AnalysisCache.find(a => a.Type == "CalcCsCompletion")?.Data / total) * 100,
        }
    })

    return (
        <div>
            <BiIcons.BiUserCircle size={35} style={{ display: 'inline-block' }} />
            <h1
                style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                className={CommonStyles.MainHeaderClass}
            >
                B2B Partners
            </h1>
            <div>
            <MaterialTable
                icons={tableIcons as Icons}
                title={''}
                columns={[
                    {
                        title: 'PartnerID',
                        field: 'PartnerID',
                    },
                    { title: 'Name', field: 'Name' },
                    { title: 'Total Users', field: 'CalcTotalUsers' },
                    {
                        title: 'Total Logged In',
                        field: 'CalcTotalLoggedInUsers',
                    },
                    { title: 'Carbon Completion', field: 'CalcCsCompletion' }
                ]}
                data={data}
                options={{
                    showTitle: false,
                    sorting: true,
                    filtering: true,
                    search: true,
                    pageSize: 50,
                }}
                onRowClick={(event, rowData: any) => {
                    history.push(`/partners/${rowData.PartnerID}`)
                }}
            />
            </div>
        </div>
    )
}

export default Partners
