import gql from 'graphql-tag'

export default gql`
    mutation yodleeAccountType(
        $YodleeProductName: String!
        $YodleeBusinessName: String!
        $Type: String!
    ) {
        yodleeAccountType(
            YodleeProductName: $YodleeProductName
            YodleeBusinessName: $YodleeBusinessName
            Type: $Type
        )
    }
`
