import { useMutation, useQuery } from '@apollo/client'
import features from 'graphql/queries/features'
import setFeature from 'graphql/mutations/setFeature'
import * as React from 'react'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../common/CommonStyles'

const WhiteLabel = () => {
    return (
        <div>
            <BiIcons.BiWindow size={35} style={{ display: 'inline-block' }} />
            <h1
                style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                className={CommonStyles.MainHeaderClass}
            >
                Whitelabel UIs
            </h1>
        </div>
    )
}

export default WhiteLabel
