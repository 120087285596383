import { useQuery } from '@apollo/client'
import inspectLog from 'graphql/queries/inspectLog'
import * as React from 'react'
import { useLocation } from 'react-router-dom'

function useQS() {
    const { search } = useLocation()
    console.log(search)
    return React.useMemo(() => new URLSearchParams(search), [search])
}

const InspectLog = () => {
    let query = useQS()

    const group = query.get('group')
    const search = query.get('search')
    const stream = query.get('stream')
    const result = useQuery(inspectLog, {
        variables: {
            LogGroup: group,
            LogStream: stream,
            Search: search,
        },
    })
    if (result.loading) return <div>LOADING</div>
    if (result.error) return <div>{result.error}</div>

    return (
        <div style={{ border: '1px solid #eee' }}>
            <h1 style={{ width: '100%', background: 'rgba(0,0,0, 0.3)', padding: '3px' }}>
                {group} - {stream} - {search}
            </h1>
            {result.data.inspectLog.split('\n').map((a) => {
                return <div style={{ fontSize: '13px' }}>{a}</div>
            })}
        </div>
    )
}

export default InspectLog
