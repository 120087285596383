import * as React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import CarbonWidget from './carbon'
import EnergyWidget from './energy'

const Widgets = () => {
    return (
        <div>
            {/* <div>
                <div className={styles.links}>
                    <Link to={`/cdr`} className={styles.link}>
                        Home
                    </Link>
                    <Link to={`/cdr/new`} className={styles.link}>
                        New
                    </Link>
                    <Link to={`/cdr/pending`} className={styles.link}>
                        Pending
                    </Link>
                    <Link to={`/cdr/ignored`} className={styles.link}>
                        Ignored
                    </Link>
                    <Link to={`/cdr/institutions`} className={styles.link}>
                        Institutions
                    </Link>
                    <Link to={`/cdr/updates`} className={styles.link}>
                        Updates
                    </Link>
                    <Link to={`/cdr/maps`} className={styles.link}>
                        Maps
                    </Link>
                </div>
            </div> */}
            <Switch>
                <Route path={`/widgets/carbon`} exact component={CarbonWidget} />
                <Route path={`/widgets/energy`} exact component={EnergyWidget} />
            </Switch>
        </div>
    )
}

export default Widgets
