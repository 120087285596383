import { Datum, Serie } from '@nivo/line'
import { CarbonAnalysisResult } from 'graphql/types'

const formatDate = (dateStr: string): Date => {
    return new Date(dateStr)
}

export const formatLineGraphCount = (data: CarbonAnalysisResult[]): Serie[] => {
    if (!data[0].DomainEngagement) throw new Error('no domains')
    const output: Serie[] = []
    const domains = data[0].DomainEngagement.map((a) => a!.Domain!)
    domains.forEach((a) => {
        const results: Datum[] = []
        data.forEach((d) => {
            results.push({
                x: formatDate(d.Date),
                y: Number(d.DomainEngagement!.find((b) => b!.Domain == a)!.Count),
            })
        })
        output.push({
            data: results,
            id: a,
        })
    })

    return output
}
