import gql from 'graphql-tag'

export default gql`
    query usersAnalysis($startDate: String, $endDate: String) {
        usersAnalysis(startDate: $startDate, endDate: $endDate) {
            Date
            Total {
                Total
                GroupSplit {
                    Name
                    Count
                }
            }
            Active {
                Total
                GroupSplit {
                    Name
                    Count
                }
            }
        }
    }
`
