import gql from 'graphql-tag'

export default gql`
    query customMerchantBucket {
        customMerchantBucket {
            items {
                UserID
                merchant_id
                bucket
            }
        }
    }
`
