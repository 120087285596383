import { ASX } from './asx'
import { INSTITUTIONS } from './enums'

export const prettyInstitutionName = (name: string): string =>
    ({
        // ANZ
        ANZ: INSTITUTIONS.ANZ,
        'Australia and New Zealand Banking Group (ANZ)': INSTITUTIONS.ANZ,
        'Australia and New Zealand Banking Group Ltd': INSTITUTIONS.ANZ,
        'Australia and New Zealand Banking Group Limited': INSTITUTIONS.ANZ,
        'AMG Personal Super': INSTITUTIONS.AMG_SUPER,
        'Australian Catholic Super & Ret Fund': INSTITUTIONS.AUSTRALIAN_CATHOLIC_SUPER,
        'Aware Super (ex FSS)': INSTITUTIONS.AWARE_SUPER,
        'Aware Super (ex First State Super)': INSTITUTIONS.AWARE_SUPER,
        'BT panorama': INSTITUTIONS.BT_PANORAMA,
        // BOQ
        'Bank of Queensland (BOQ)': INSTITUTIONS.BANK_OF_QUEENSLAND,
        'Bank of Queensland': INSTITUTIONS.BANK_OF_QUEENSLAND,
        'Bank Of Queensland': INSTITUTIONS.BANK_OF_QUEENSLAND,
        BankWest: INSTITUTIONS.BANKWEST,
        // CBA
        CBA: INSTITUTIONS.CBA,
        'Commonwealth Bank of Australia (CBA)': INSTITUTIONS.CBA,
        'Commonwealth Bank of Australia': INSTITUTIONS.CBA,
        // CITI
        Citibank: INSTITUTIONS.CITI,
        // ING
        'ING Bank (Australia) Limited (trading as ING Direct)': INSTITUTIONS.ING,
        ING: INSTITUTIONS.ING,
        'Intrust Select': INSTITUTIONS.INTRUST_SUPER,
        'LGIAsuper Accumulation': INSTITUTIONS.LGIA_SUPER,
        Macquarie: INSTITUTIONS.MACQUARIE_BANK,
        'MOVE - People Driven Banking': INSTITUTIONS.MOVE_BANK,
        // NAB
        'National Australia Bank (NAB)': INSTITUTIONS.NAB,
        'National Australia Bank Ltd': INSTITUTIONS.NAB,
        NAB: INSTITUTIONS.NAB,
        'Plum Super Personal': INSTITUTIONS.PLUM_SUPER,
        'Spirit Super (Default A)': INSTITUTIONS.SPIRIT_SUPER,
        'Sunsuper for life Super-savings account': INSTITUTIONS.SUNSUPER,
        StGeorge: INSTITUTIONS.ST_GEORGE,
        'UniSuper Personal Account': INSTITUTIONS.UNISUPER,
        'VicSuper FutureSaver': INSTITUTIONS.VICSUPER,
        'Vision Super Personal': INSTITUTIONS.VISION_SUPER,
        'Virgin Money Australia': INSTITUTIONS.VIRGIN,
        'ART - QSuper - Accumulation': INSTITUTIONS.QSUPER,
        // WESTPAC
        'Westpac Banking Corporation': INSTITUTIONS.WESTPAC,
        'Westpac Banking Corporation (Westpac)': INSTITUTIONS.WESTPAC,
        WBC: INSTITUTIONS.WESTPAC,
        betacarbon: INSTITUTIONS.BETACARBON,
    }[name] || name)

export const prettyProductName = (name: string): string =>
    ({
        'AustralianSuper Balanced': 'Balanced',
        'SPACESHIP Voyager Universe': 'Universe Portfolio',
        'GROWTH SAVER': 'Growth Saver',
    }[name] || name)

export const prettyPortfolioName = (name: string, fund: string) => {
    // If there is a fund provided, replace string, else return name of portfolio
    if (fund) return name.replace(fund + ' ', '')
    else {
        return name
    }
}

export const symbolToBusinessName = (symbol: string): string => {
    const match = ASX.filter((x) => x.ticker.toLowerCase() === symbol.toLowerCase())
    if (match.length > 0) {
        return match[0].name
    } else {
        return symbol
    }
}

export const parseUnoCodesToLenderNames = (symbol: string) =>
    ({
        '864': '86 400',
        ADB: 'Adelaide Bank',
        ADC: 'Australian Military Bank',
        AMO: 'AMO Group',
        AMP: 'AMP',
        AMS: 'AMS',
        ANZ: 'ANZ',
        ARA: 'Arab Bank Australia',
        ASE: 'Aussie',
        AXA: 'AXA',
        BAY: 'Auswide Bank',
        BAU: 'Bank Australia',
        BBL: 'Bendigo Bank',
        BCU: 'bcu',
        BCY: 'Delphi Bank',
        BLU: 'Bluestone',
        BMM: 'Better Mortgage Management',
        BOM: 'Bank of Melbourne',
        BOS: 'Bank of Sydney',
        BPB: 'B&E Personal Banking',
        BQL: 'Bank Of Queensland',
        BSA: 'Bank of South Australia',
        BVC: 'BankVic',
        BWT: 'BankWest',
        BYB: 'Beyond Bank',
        CAP: 'The Capricornian',
        CBA: 'CBA',
        CBL: 'Challenge Bank',
        CCU: 'Coastline Credit Union',
        CFC: 'Community First CU',
        CLS: 'Click Loans',
        CTI: 'Citibank',
        CTM: 'Catalyst Money',
        CUA: 'CUA',
        DBL: 'Defence Bank',
        ECU: 'Select Encompass Credit Union',
        ESF: 'Easy Street Fin Services',
        FCU: 'FCCS Credit Union',
        FMB: 'Firefighters Mutual Bank',
        FOC: 'First Option Credit Union',
        FRL: 'Freedom Lend',
        FRM: 'Firstmac',
        GCB: 'G&C Mutual Bank',
        GCU: 'Gateway Credit Union',
        HBA: 'HSBC',
        HBS: 'Heritage',
        HCC: 'Holiday Coast CU',
        HCU: 'Horizon Credit Union',
        HLL: 'Home loans Ltd',
        HNU: 'Hunter United',
        HPB: 'Health Professional Bank',
        HSD: 'Homeside',
        HSF: 'Homestar Finance',
        HUM: 'Hume Bank',
        ICU: 'Intech Credit Union',
        ILW: 'Illawarra CU NSW',
        IMB: 'IMB',
        IMR: 'iMortgage',
        ING: 'ING',
        LCA: 'loans.com.au',
        LIB: 'Liberty',
        LTR: 'La Trobe',
        MAC: 'The Mac',
        MAS: 'Mortgage Asset Services',
        MCQ: 'Macquarie Credit Union',
        MCU: 'My Credit Union',
        MEB: 'ME Bank',
        MET: 'Suncorp Metway',
        MHO: 'Mortgage House',
        MOV: 'MOVE - People Driven Banking',
        MQB: 'Macquarie',
        MST: 'MyState',
        NAB: 'NAB',
        NEW: 'Newcastle Permanent',
        Other: 'Other',
        PCC: `People's Choice Credit Union`,
        PMG: 'Pacific Mortgage Group',
        PNB: 'P&N Bank',
        POC: 'Police Bank',
        PPR: 'Pepper Money',
        QBK: 'QBANK',
        QCU: 'Queenslanders CU',
        QMB: 'QT Mutual Bank',
        QML: 'Qudos Bank',
        RAB: 'Regional Australia Bank',
        RAM: 'RAMS',
        RCK: 'The Rock',
        RHL: 'Reduce Home Loans',
        RMC: 'RESI Mortgage Corp',
        RZD: 'RedZed',
        SCP: 'Suncorp',
        SCU: 'SCU',
        SNX: 'Southern Cross Credit Union',
        SOA: 'SERVICE ONE Alliance Bank',
        STC: 'State Custodians',
        STG: 'StGeorge',
        TMB: 'Teachers Mutual Bank',
        TMC: 'Transport Mutual Credit Union',
        TMT: 'The Mutual',
        UBN: 'UBank',
        UNI: 'UniBank',
        VMN: 'Virgin Money',
        VTM: 'Victoria Teachers Mutual Bank',
        WBC: 'Westpac',
        YBR: 'Yellow Brick Road',
    }[symbol] || symbol)
