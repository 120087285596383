import { gql } from '@apollo/client'

export default gql`
    fragment AccountFragment on Account {
        # id
        user_id
        acaciaAccountStatus
        # productId
        # institutionCode
        # accountNo
        name
        # balance
        businessName
        # product {
        #     name
        # }
        institution {
            name
            institutionCode
        }
        class {
            product
            type
        }
        customType
        # superProductID
        superProduct {
            # LongName
            ProductName
            ShortName
        }
    }
`
