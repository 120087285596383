import { useQuery } from '@apollo/client'
import adminInsightResult from 'graphql/queries/adminInsightResult'
import inspectLog from 'graphql/queries/inspectLog'
import * as React from 'react'
import ReactJson from 'react-json-view'
import { useLocation } from 'react-router-dom'

function useQS() {
    const { search } = useLocation()
    console.log(search)
    return React.useMemo(() => new URLSearchParams(search), [search])
}

const InspectResult = () => {
    let query = useQS()

    const UserID = query.get('userID')
    const InsightID = query.get('insightID')
    const result = useQuery(adminInsightResult, {
        variables: {
            UserID: UserID,
            InsightID: InsightID,
        },
    })
    if (result.loading) return <div>LOADING</div>
    if (result.error) return <div>{result.error}</div>

    const obj = JSON.parse(result.data.adminInsightResult)
    return (
        <div style={{ border: '1px solid #eee' }}>
            <h1 style={{ width: '100%', background: 'rgba(0,0,0, 0.3)', padding: '3px' }}>
                {UserID} - {InsightID}
            </h1>
            <ReactJson src={obj} />
        </div>
    )
}

export default InspectResult
