import { ThemeTypings } from '@chakra-ui/styled-system/dist/types'

export const chakraColorSchemes: ThemeTypings['colorSchemes'][] = [
    //     "whiteAlpha",
    //     "blackAlpha",
    'blue',
    'red',
    'orange',
    'green',
    'purple',
    'cyan',
    'pink',
    'teal',
    'gray',
    'yellow',
    'linkedin',
    'facebook',
    'messenger',
    'whatsapp',
    'twitter',
    'telegram',
]

export class UniqueStringCounter {
    private stringToNumberMap: Map<string, number> = new Map()
    private currentNumber: number = 0

    getUniqueNumber(inputString: string): number {
        if (this.stringToNumberMap.has(inputString)) {
            return this.stringToNumberMap.get(inputString)!
        }

        const uniqueNumber = this.currentNumber++
        this.stringToNumberMap.set(inputString, uniqueNumber)
        return uniqueNumber
    }
}
