import * as React from 'react'
import { useMutation } from '@apollo/client'

import importProducts from 'graphql/mutations/importProducts'
import { Button } from '@material-ui/core'

const ProductImport = () => {
    const [run] = useMutation(importProducts, {
        onCompleted: (msg) => {
            alert('DONE! ' + msg)
        },
        onError: (msg) => {
            alert('ERROR: ' + msg)
        },
    })
    return (
        <div>
            <Button onClick={() => run()}>Product Import</Button>
        </div>
    )
}

export default ProductImport
