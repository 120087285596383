import { Datum, Serie } from '@nivo/line'
import { CarbonAnalysisResult } from 'graphql/types'

const formatDate = (dateStr: string): Date => {
    return new Date(dateStr)
}

export const formatLineGraphCount = (data: CarbonAnalysisResult[]): Serie[] => {
    if (!data[0].DomainEngagement) throw new Error('no domains')
    const output: Serie[] = []
    const domains = data[0].DomainEngagement.map((a) => a!.Domain!)
    domains.forEach((a) => {
        const results: Datum[] = []

        data.forEach((d) => {
            const v = d.DomainEngagement!.find((b) => b!.Domain == a)!

            var avg
            if (v.Count == '0') avg = 0
            else avg = Number(v.Score) / Number(v.Count)
            results.push({
                x: formatDate(d.Date),
                y: avg,
            })
        })
        output.push({
            data: results,
            id: a,
        })
    })
    return output
}
