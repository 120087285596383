import * as React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import mappings from 'graphql/queries/mappings'
import {
    TableHead,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Select,
    MenuItem,
    Button,
    Checkbox,
} from '@material-ui/core'
import yodleeAccountTypes from 'graphql/queries/yodleeAccountTypes'
import accounts from 'graphql/queries/accounts'
import * as BiIcons from 'react-icons/bi'
import { Account, YodleeAccountType } from 'graphql/types'
import * as CommonStyles from '../../common/CommonStyles'
import yodleeAccountType from 'graphql/mutations/yodleeAccountType'

const AccountTypes = () => {
    const { loading, error, data, refetch } = useQuery(accounts, { fetchPolicy: 'no-cache' })
    const {
        loading: loadingA,
        error: errorA,
        data: dataA,
        refetch: refetchA,
    } = useQuery(yodleeAccountTypes, { fetchPolicy: 'no-cache' })

    const [showAll, setShowAll] = React.useState(false)
    const [submitYodleeAccountType] = useMutation(yodleeAccountType)
    if (loading || loadingA) return <div>Loading</div>
    if (error || errorA) return <div>failure {console.error(error || errorA)} </div>

    const handleChange = async (YodleeBusinessName, YodleeProductName, Type) => {
        console.log(YodleeBusinessName, YodleeProductName, Type)
        await submitYodleeAccountType({
            variables: {
                YodleeProductName: YodleeProductName,
                YodleeBusinessName: YodleeBusinessName,
                Type: Type,
            },
        })
        await refetch()
        await refetchA()
    }

    const found: string[] = []
    const allAccounts = data.accounts.items as Account[]
    const allMaps = dataA.yodleeAccountTypes as YodleeAccountType[]
    const filteredMaps = allMaps.filter((a) => showAll || !a.Type || a.Type === 'other')
    const products = allMaps.map((a) => a.YodleeBusinessName + '_' + a.YodleeProductName)
    let items = allAccounts.filter((a) => a.class?.type == 'other')
    items.forEach((a) => {
        if (!products.includes(a.businessName + '_' + a.class?.product)) {
            const b: YodleeAccountType = {
                YodleeBusinessName: a.businessName,
                YodleeProductName: a.class?.product,
                Type: 'other',
            }
            products.push(a.businessName + '_' + a.class?.product)
            filteredMaps.push(b)
        }
    })
    return (
        <div>
            <div>
                <BiIcons.BiBaseball size={35} style={{ display: 'inline-block' }} />
                <h1
                    style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                    className={CommonStyles.MainHeaderClass}
                >
                    Account Type Maps
                </h1>
            </div>
            <div>
                <Checkbox
                    checked={showAll}
                    onChange={() => setShowAll(!showAll)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />{' '}
                Show all changes
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Yodlee Product Name</TableCell>
                        <TableCell>Yodlee Business Name</TableCell>
                        <TableCell>Type</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredMaps.map((a) => {
                        return (
                            <TableRow>
                                <TableCell>{a.YodleeProductName}</TableCell>
                                <TableCell>{a.YodleeBusinessName}</TableCell>
                                <TableCell>
                                    <Select
                                        labelId="pick-type"
                                        id="pick-type"
                                        value={a.Type}
                                        onChange={(event) =>
                                            handleChange(
                                                a.YodleeBusinessName,
                                                a.YodleeProductName,
                                                event.target.value,
                                            )
                                        }
                                    >
                                        <MenuItem value="other">Other</MenuItem>
                                        <MenuItem value="savings">Savings</MenuItem>
                                        <MenuItem value="transaction">Transaction</MenuItem>
                                        <MenuItem value="super_annuation">Super</MenuItem>
                                        <MenuItem value="mortgage">Mortgage</MenuItem>
                                        <MenuItem value="credit">Credit Card</MenuItem>
                                        <MenuItem value="investment">Investment</MenuItem>
                                    </Select>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </div>
    )
}

export default AccountTypes
