import gql from 'graphql-tag'

export default gql`
    query carbonAnalysis($startDate: String, $endDate: String, $group: String) {
        carbonAnalysis(startDate: $startDate, endDate: $endDate, group: $group) {
            Date
            UserEngagedCount
            DomainEngagement {
                Score
                Count
                Domain
            }
        }
    }
`
