import { Button,ButtonBase,Dialog, DialogContent, Input, Menu, MenuItem, Modal, Paper, TextareaAutosize } from '@material-ui/core'
import * as React from 'react'
import * as styles from './styles'
import { Insight } from 'graphql/types'
import { ButtonSpinner, Textarea } from '@chakra-ui/react'
import { useLazyQuery, useMutation } from '@apollo/client'
import mutateInsight from 'graphql/mutations/mutateInsight'
import manualCompareDocument from 'graphql/mutations/manualCompare'
import adminInsightResult from 'graphql/queries/adminInsightResult'
import { Label } from '@material-ui/icons'
import { getSampleRates } from './sampleRates'

 type Props = {
    InsightDetails: any
    refetch: any
 }

const InsightDetails = ({InsightDetails, refetch}: Props) => {
    
    const [manualCompareModal, setManualCompareModal] = React.useState<undefined | Insight>(undefined)
    const [currentBillRates, setCurrentBillRates] = React.useState<string>("")
    const [nmi, setNmi] = React.useState<string>("")
    const [institutionCode, setInstitutionCode] = React.useState<string>("")
    const uniqueBills = new Map()
    let billNumber = 1
    InsightDetails.insights.forEach((v) => {
        if (!uniqueBills.has(v.BillHash)) {
            uniqueBills.set(v.BillHash, billNumber++)
        }
    })
    const [updateInsight]  = useMutation(mutateInsight)
    const [manualCompareApi]  = useMutation(manualCompareDocument)
    const getInsightResult = useLazyQuery(adminInsightResult)
    const [anchorEl, setAnchorEl] = React.useState<any>({});
    const open = (InsightID) => Boolean(anchorEl[InsightID]);
    const handleClick = (InsightID: string, event: React.MouseEvent<HTMLButtonElement>) => {
        const el = {...anchorEl }
        el[InsightID] = event.currentTarget
        setAnchorEl(el);
    };

    const handleClose = (ins: Insight, Status: string) => {

        if (Status === "ManualCompareModal") {
            setManualCompareModal(ins)
            return
        }
        setManualCompareModal(undefined)

        if (Status === "ManualCompare") {
            manualCompareApi({
                variables: {
                    Nmi: nmi,
                    UserID: ins.UserID,
                    CurrentBillRates: currentBillRates,
                    InstitutionCode: institutionCode
                }
            })
        }

        updateInsight({
            variables: {
                UserID: ins.UserID,
                InsightID: ins.InsightID,
                Action: "status",
                Data: Status
            }
        })
        refetch()
        const el = {...anchorEl }
        el[ins.InsightID] = null
        setAnchorEl(el);
    };
    const getBill = async (insight: Insight) => {
        console.log(insight)
        await getInsightResult[0]({
            variables: {
                UserID: insight.UserID,
                InsightID: "Bill:"+insight.BillId
            }
        })
    };
    const addNotes = async (insight: Insight, notes: string) => {
        await updateInsight({
            variables: {
                UserID: insight.UserID,
                InsightID: insight.InsightID,
                Action: "notes",
                Data: notes
            }
        })
        refetch()
    }
    React.useEffect(() => {
        if (getInsightResult[1].data) downloadPDF(getInsightResult[1].data.adminInsightResult)
    }, [getInsightResult[1].data])

    const downloadPDF = (pdf) => {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "acaciaBillDownload.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    return (
        <div style={{margin: "13px 8px"}}>
            <div>
                <div className={styles.row}>
                    { InsightDetails.insights.map((insight: Insight) => {
                        const thisInsight = insight
                        const badStatus = thisInsight.Status === "Escalate" || thisInsight.Status === "Error" || (thisInsight.Status === "" && thisInsight.Error !== "")
                        return (
                            <>
                                <div key={thisInsight.InsightID} className={styles.subrow}>

                                    <ButtonBase className={styles.header}  onClick={() => getBill(thisInsight)}>
                                        <div>Bill: #{uniqueBills.get(thisInsight.BillHash)}</div>
                                        
                                    </ButtonBase>
                                    <ButtonBase style={badStatus ? {backgroundColor: "#FFCCCB"} : {backgroundColor: "#90EE90"}}
                                        className={styles.header} onClick={(e) => handleClick(thisInsight.InsightID, e)}>
                                        {thisInsight.Status === "" ? thisInsight.Error === "" ? "OK" : "Error" : thisInsight.Status}
                                    </ButtonBase>
                                    <Modal 
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        open={!!manualCompareModal} 
                                        onClose={() => setManualCompareModal(undefined)}
                                    >
                                        <Paper style={{ width: '80%', height: '80%', overflowY: 'scroll', padding: "20px" }} elevation={3}>
                                            <div><span>Nmi: </span><Input placeholder="NMI" value={nmi} onChange={(e) => setNmi(e.currentTarget.value)} /></div>
                                            <div><span>InstitutionCode: </span><Input placeholder="InstitutionCode" value={institutionCode} onChange={(e) => setInstitutionCode(e.currentTarget.value)} /></div>
                                            
                                            <div>
                                                <Textarea 
                                                    style={{ height: '700px', overflowY: 'scroll', padding: "5px" }}
                                                    placeholder={getSampleRates}
                                                    value={currentBillRates} 
                                                    onChange={(e) => setCurrentBillRates(e.currentTarget.value)} 
                                                />
                                            </div>
                                            <Button onClick={() => handleClose(thisInsight, "ManualCompare")}>Submit</Button>
                                        </Paper>
                                    </Modal>
                                    <Menu
                                        id={thisInsight.InsightID}
                                        anchorEl={anchorEl[thisInsight.InsightID]}
                                        open={open(thisInsight.InsightID)}
                                        MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                        }}
                                        onClose={() => {
                                            setAnchorEl({});
                                        }}
                                    >
                                        <MenuItem onClick={() => handleClose(thisInsight, "OK")}>OK</MenuItem>
                                        <MenuItem onClick={() => handleClose(thisInsight, "Ignore")}>Ignore</MenuItem>
                                        <MenuItem onClick={() => handleClose(thisInsight, "Error")}>Error</MenuItem>
                                        <MenuItem onClick={() => handleClose(thisInsight, "Escalate")}>Escalate</MenuItem>
                                        <MenuItem onClick={() => handleClose(thisInsight, "ManualCompareModal")}>ManualCompare</MenuItem>
                                        <MenuItem onClick={() => handleClose(thisInsight, "Fixed")}>Fixed</MenuItem>
                                        <MenuItem onClick={() => handleClose(thisInsight, "FixedSendInsight")}>FixedSendInsight</MenuItem>
                                        <MenuItem onClick={() => handleClose(thisInsight, "FixedReset")}>FixedReset</MenuItem>
                                    </Menu>
                                    <div className={styles.info}>
                                        <div>
                                            <strong>{new Date(insight.GeneratedAt).toLocaleString()}</strong>
                                            <br />
                                            {insight.Summary?.ProviderName}
                                            <br />
                                            {insight.Error}
                                            <br />
                                            InsightID: {insight.InsightID}
                                            <br />
                                            BillID: {insight.BillId}

                                        </div>
                                    </div>

                                    <div className={styles.info}>
                                        {insight.Notes.split("\n").map((v) => {
                                            return <p key={v}>{v}</p>
                                        })}
                                    </div>
                                    <Textarea 
                                        placeholder='Type new notes into this textarea and then blur (click anywhere or press tab) to save' 
                                        className={styles.textInput} 
                                        onBlur={(e) => { void addNotes(thisInsight, e.target.value)}} 
                                    />
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default InsightDetails
