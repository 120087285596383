import * as React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import mappings from 'graphql/queries/mappings'
import {
    TableHead,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Select,
    MenuItem,
    Button,
} from '@material-ui/core'
import productUpdatesDocument from 'graphql/queries/productUpdates'
import accounts from 'graphql/queries/accounts'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../../common/CommonStyles'
import superProducts from 'graphql/queries/superProducts'
import yodleeAccountType from 'graphql/mutations/yodleeAccountType'

const ProductMappings = () => {
    const { loading, error, data, refetch } = useQuery(mappings, { fetchPolicy: 'no-cache' })
    const { loading: l, error: e, data: d } = useQuery(superProducts, { fetchPolicy: 'no-cache' })
    const {
        loading: puLoading,
        error: puError,
        data: puData,
    } = useQuery(productUpdatesDocument, { fetchPolicy: 'no-cache' })
    const [submitYodleeAccountType] = useMutation(yodleeAccountType)
    const { loading: loadingA, error: errorA, data: dataA } = useQuery(accounts)
    const [approvedFilter, setApprovedFilter] = React.useState(false)
    if (loading || puLoading || loadingA || l) return <div>Loading</div>
    if (error || puError || errorA || e) return <div>failure {console.error(error)} </div>
    const found: string[] = []

    let maps = data.mappings.items as any
    let superMaps = d.superProducts.filter((p) => p.YodleeInstitutionName) as any

    if (approvedFilter) {
        maps = maps.filter((row) => {
            const update = puData.productUpdates.items.filter((a) => {
                const [inst, pid] = a.institutionCodeProductID.split('+')
                return (
                    inst === row.Institution.institutionCode &&
                    pid === row.CdrProductID &&
                    a.updatedKey === 'DepositRates'
                )
            })
            if (
                update.length === 0 ||
                (update[0].state !== 'APPROVED' && update[0].state !== 'EDITED')
            )
                return true
            return false
        })
    }
    return (
        <div>
            <div>
                <BiIcons.BiDialpadAlt size={35} style={{ display: 'inline-block' }} />
                <h1
                    style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                    className={CommonStyles.MainHeaderClass}
                >
                    Current Maps
                </h1>
            </div>
            <Button
                color={approvedFilter ? 'secondary' : 'default'}
                onClick={() => setApprovedFilter(!approvedFilter)}
            >
                Show unapproved
            </Button>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Yodlee Product Name</TableCell>
                        <TableCell>Institution</TableCell>
                        <TableCell>Yod type</TableCell>
                        <TableCell>Current type</TableCell>
                        <TableCell>CDR Product Name</TableCell>
                        <TableCell># Accounts</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {maps.map((row) => {
                        //console.log({ row })
                        const update = puData.productUpdates.items.filter((a) => {
                            const [inst, pid] = a.institutionCodeProductID.split('+')
                            return (
                                inst === row.Institution.institutionCode &&
                                pid === row.CdrProductID &&
                                a.updatedKey === 'DepositRates'
                            )
                        })
                        const acc = dataA.accounts.items.filter(
                            (a) =>
                                a.institutionCode === row.Institution?.institutionCode &&
                                a.productId === row.CdrProductID,
                        )
                        if (!acc.length) {
                            console.log(
                                'product map failed for ' +
                                    row.Institution?.institutionCode +
                                    ' / ' +
                                    row.CdrProductID,
                            )
                            return <></>
                        }
                        return (
                            <TableRow>
                                <TableCell>
                                    {row.YodleeProductName}
                                    {(update.length === 0 ||
                                        (update[0].state !== 'APPROVED' &&
                                            update[0].state !== 'EDITED')) && (
                                        <p>
                                            <a href="/pupdates">Product is not approved!!!</a>
                                        </p>
                                    )}
                                </TableCell>
                                <TableCell>{row.Institution?.name}</TableCell>
                                {/* <TableCell>{row.class && row.class!.type!}</TableCell>
                                <TableCell>{row.customType ? row.customType : (row.class && row.class!.type!)}</TableCell> */}
                                <TableCell>{row.Product?.name}</TableCell>
                                <TableCell>
                                    {acc[0].customType ? acc[0].customType : acc[0].class!.type!}
                                </TableCell>
                                <TableCell>{row.Product?.name}</TableCell>
                                <TableCell>{acc.length}</TableCell>
                            </TableRow>
                        )
                    })}

                    {superMaps.map((row) => {
                        return (
                            <TableRow>
                                <TableCell>{row.YodleeInstitutionName}</TableCell>
                                <TableCell>Super Product</TableCell>
                                <TableCell>{row.ProductName}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </div>
    )
}

export default ProductMappings
