export const acaciaIntersection = [
    {
        institutionCode: 'wes',
        productID: 'SavLife',
        name: 'Westpac Life',
    },
    {
        institutionCode: 'ing',
        productID: 'b7bb9da4-3913-484c-8d2d-3fc99b9b4a72',
        name: 'Savings Maximiser',
    },
    {
        institutionCode: 'bau',
        productID: 'Product103',
        name: 'mySaver',
    },
    {
        institutionCode: 'amp',
        productID: 'AMP_SAVER',
        name: 'AMP Saver Account',
    },
    {
        institutionCode: 'ben',
        productID: 'cb6f3716-4e76-4e5b-b79d-0720756a21ac',
        name: 'Bendigo Reward Saver',
    },
    {
        institutionCode: 'cit',
        productID: 'AUGCB410AUD',
        name: 'Citibank Online Saver',
    },
    {
        institutionCode: 'sun',
        productID: 'GS',
        name: 'Growth Saver Account',
    },
    {
        institutionCode: 'anz',
        productID: 'e9a3b4fe-240c-388e-2282-42db8f570e63',
        name: 'ANZ Progress Saver',
    },
    {
        institutionCode: 'rab',
        productID: 'f5079e11-c89f-43bf-bd15-91df6602699d',
        name: 'PremiumSaver',
    },
    {
        institutionCode: 'nab',
        productID: 'a97fad3e-9d44-4b4e-8279-3722edefae52',
        name: 'NAB iSaver',
    },
    {
        institutionCode: 'cba',
        productID: '72f214498c7f47a98fe59b794e7c01ab',
        name: 'GoalSaver',
    },
]

// finder top20 $5000
// missing macquarie, great southern bank, hsbc, defence bank
export const top20Codes = [
    // Bank of Queensland - Fast Track Starter Account (14 to 24 years)
    {
        institutionCode: 'boq',
        productID: '692426A9-74ED-4528-849F-F098C4839385',
    },
    // Westpac - Life (18-29 years old)
    {
        institutionCode: 'wes',
        productID: 'SavLife',
    },
    // Rabobank - High Interest Savings Account
    {
        institutionCode: 'rab',
        productID: 'c84e2ce6-f927-4783-aa0d-84073bc090f7',
    },
    // ING - Savings Maximiser
    {
        institutionCode: 'ing',
        productID: 'b7bb9da4-3913-484c-8d2d-3fc99b9b4a72',
    },
    // AMP Bank - AMP Saver Account
    {
        institutionCode: 'amp',
        productID: 'AMP_SAVER',
    },
    // MyState Bank - Bonus Saver Account
    {
        institutionCode: 'mys',
        productID: 'f2c94853-abb7-4c8e-ae43-66427d186380',
    },
    // 86400 Save
    {
        institutionCode: '86',
        productID: '1',
    },
    // Virgin Money - Boost Saver with Go Account
    {
        institutionCode: 'vir',
        productID: '34F26277-FB82-43BF-A8FA-160AA354D6DB',
    },
    // ME - Online Savings Account
    {
        institutionCode: 'meb',
        productID: 'OSA.RETAIL',
    },
    // IMB Bank - Reward Saver Account
    {
        institutionCode: 'imb',
        productID: 'TSA-22-1',
    },
    // Citi - Online Saver
    {
        institutionCode: 'cit',
        productID: 'AUGCB410AUD',
    },
    // UBank - USave with USpend Transaction Account
    {
        institutionCode: 'uba',
        productID: '5b09d97f-7463-4a51-9e48-f3f04d764d5f',
    },
    // Bank of Queensland - Fast Track Saver Account
    {
        institutionCode: 'boq',
        productID: 'C69E6590-BA09-4B4A-9842-EC66EB8C405A',
    },
    // Bank of Queensland - WebSavings Account
    {
        institutionCode: 'boq',
        productID: 'EAD5402A-7681-4315-9C35-34A451064CE7',
    },
    // RACQ Bank - Bonus Saver
    {
        institutionCode: 'rac',
        productID: '404',
    },
    // WAW Credit Union - Youth Saver
    {
        institutionCode: 'waw',
        productID: 'WAW_Youth_Saver',
    },
    // Summerland Credit Union - iSave Online Deposit Builder Special Offer
    {
        institutionCode: 'sum',
        productID: '3',
    },
    // Rabobank - PremiumSaver
    {
        institutionCode: 'rab',
        productID: 'f5079e11-c89f-43bf-bd15-91df6602699d',
    },
    // Australian Military Bank - Online Saver
    {
        institutionCode: 'ausm',
        productID: '9d1888b8-097d-4c21-849f-730bfaace6c3',
    },
    // QBANK - Bonus Saver
    {
        institutionCode: 'qba',
        productID: 'S70_Bonus',
    },
]

export const popular = [
    {
        code: 'anz',
        icon: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/ANZ_DARK.svg',
    },
    {
        code: 'cba',
        icon: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/CBA_DARK.svg',
    },
    {
        code: 'ing',
        icon: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/ING_DARK.svg',
    },
    {
        code: 'wes',
        icon: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/WESTPAC_DARK.svg',
    },
    {
        code: 'boq',
        icon: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/BOQ_DARK.svg',
    },
    {
        code: 'vir',
        icon: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/VIRGIN_DARK.svg',
    },
    {
        code: 'gsb',
        icon: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/GSB_DARK.svg',
    },
]
