import b from 'bss'
import * as CommonStyles from 'components/common/CommonStyles'

export const row = b`
    clear:both;
    overflow:auto; 
    margin: 8px 0; 
    background-color: #f4f6f8;
    border: 1px solid #333;
    padding:8px 4px;
`.class

export const subrow = b`
    clear:both;
    overflow:auto; 
    margin: 2px 0; 
    background-color: #fff;
    border: 1px solid #333;
    padding:8px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

`.class

export const header = b`
margin: 4px;
    padding: 5px 10px;
    border: 1px solid #333;
    background-color: #ddd;
    font-size: 30px;
    font-weight:bold;
`.class

export const subheader = b`
    font-size: 20px;
`.class

export const leftInfo = b`
    float:left;
    margin-left:60px;
    margin-top:10px;
`.class

export const rightInfo = b`
    float:right;
    margin-top:5px;
    margin-left: 40px;
`.class

export const info = b`
    margin-left: 20px;
    clear:both;
`.class

export const textInput = b`
    width: 100%;
    border: 1px solid #333;
    padding: 4px 6px;
    margin-top: 2px;
`
