import gql from 'graphql-tag'

export default gql`
    query pendingAccounts {
        pendingAccounts {
            items {
                id
                class {
                    type
                    product
                }
                UnableToMap
                businessName
                user {
                    user_id
                    email
                }
                customType
                ProviderAccountID
            }
        }
    }
`
