import { useAuth0 } from './react-auth0-wrapper'
import React from 'react'
import Spinner from 'components/common/Spinner'

const LoginScreen = () => {
    const { loginWithRedirect } = useAuth0()

    React.useEffect(() => {
        loginWithRedirect({})
    }, [])
    return <Spinner />
}
export default LoginScreen
