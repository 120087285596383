import gql from 'graphql-tag'

export default gql`
    query {
        yodleeAccountTypes {
            YodleeProductName
            YodleeBusinessName
            Type
        }
    }
`
