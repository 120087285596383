import * as React from 'react'
import * as GraphTypes from 'graphql/types'
import { prettyInstitutionName } from '../institutions'
import { INSTITUTIONS } from '../institutions/enums'

export default function getLogoUrl(businessName: string, businesses: GraphTypes.Business[]) {
    const matchBusinessToProduct = businesses.filter((x) => x.shortName === businessName)
    const next =
        matchBusinessToProduct.length > 0
            ? matchBusinessToProduct
            : businesses.filter((x) => x.name.includes(businessName))

    return next.length > 0 ? next.shift().logo?.small : ''
}

export const logoType = (logoUrl: string) =>
    logoUrl && logoUrl.toLowerCase().slice(-3) === 'png' ? 'png' : 'svg'

export function getLogoUrlStringFromProduct(
    businessName: string,
    businesses: GraphTypes.Business[]
): string {
    // expects product.Institution.name
    const prettydName = prettyInstitutionName(businessName)

    const matchBusinessToProduct = businesses.filter(
        (x) => x.shortName === businessName || x.shortName === prettydName
    )

    const next =
        matchBusinessToProduct.length > 0
            ? matchBusinessToProduct
            : businesses.filter((x) => x.name.includes(businessName))

    return next.length > 0 ? next.shift().logo?.small : ''
}

export function getLogoUrlStringFromAccount(
    account: GraphTypes.Account,
    businesses: GraphTypes.Business[]
): string {
    const logo = businesses
        .filter((x) => x.name === account.businessName)
        .map((x) => x.logo.small)
        .shift()
    return logo || ''
}

export function getCustomLogoUrl(businessName: string, placeholder: string): string {
    return (
        {
            '86 400':
                'https://s3-ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/86400.svg',
            'Australian Ethical Super':
                'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/full/AustralianEthicalSuper.svg',
            AustralianSuper:
                'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/full/AustralianSuper.svg',
            'Aware Super':
                'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/AwareSuper.svg',
            'Rest Super':
                'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/full/RestSuper.svg',
            Sunsuper:
                'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/full/Sunsuper.svg',
        }[businessName] || placeholder
    )
}

export function getLogo(
    businessName: string,
    businesses: GraphTypes.Business[],
    type: 'square' | 'icon' | 'full',
    theme?: 'light' | 'dark'
): any {
    const custom = getCustomLogo(businessName, type, theme)
    if (custom === undefined || custom === null) {
        return getLogoUrlStringFromProduct(businessName, businesses)
    } else {
        // DEBUGGING
        // console.log('Custom logo used')
        return custom
    }
}

export function getCustomLogo(
    businessName: string,
    type: 'square' | 'icon' | 'full',
    theme?: 'light' | 'dark'
): any {
    const source =
        {
            [INSTITUTIONS.ADELAIDE_BANK]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/ADELAIDE_BANK.svg',
                icon: {
                    light: null,
                    dark: null,
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.AMP]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/AMP.svg',
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/AMP_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.ANZ]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/ANZ.svg',
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/ANZ_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.AMERICAN_EXPRESS]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/AMX.svg',
            },
            [INSTITUTIONS.BANK_AUSTRALIA]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/BAU.svg',
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/BANK_AUSTRALIA_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.BANK_OF_QUEENSLAND]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/BOQ.svg',
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/BOQ_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.BANK_OF_MELBOURNE]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/BANK_OF_MELBOURNE.svg',
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/BANK_OF_MELBOURNE_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.BANK_OF_SOUTH_AUSTRALIA]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/BANK_OF_SOUTH_AUSTRALIA.svg',
                icon: {
                    light: null,
                    dark: null,
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.BANKWEST]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/BANK_WEST.svg',
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/BANKWEST_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.BENDIGO_BANK]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/BEN.svg',
                icon: {
                    light: null,
                    dark: null,
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.CITI]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/CIT.svg',
                icon: {
                    light: null,
                    dark: null,
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            CommSec: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/CBA.svg',
                icon: {
                    light: null,
                    dark: null,
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.CBA]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/CBA.svg',
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/CBA_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.CUA]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/CUA.svg',
                icon: {
                    light: null,
                    dark: null,
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.GREAT_SOUTHERN_BANK]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/GSB.svg',
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/GSB_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.HERITAGE_BANK]: {
                square: null,
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/HERITAGE_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.HSBC]: {
                square: null,
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/HSBC_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.ING]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/ING.svg',
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/ING_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.MACQUARIE_BANK]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/MACQUARIE.svg',
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/MACQUARIE_BANK_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.ME_BANK]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/MEB.svg',
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/ME_BANK_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.MOVE_BANK]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/MOV.svg',
                icon: {
                    light: null,
                    dark: null,
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.MYSTATE_BANK]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/MYS.svg',
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/MYSTATE_BANK_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.NAB]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/NAB.svg',
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/NAB_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.QBANK]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/QBA.svg',
                icon: {
                    light: null,
                    dark: null,
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.QUDOS_BANK]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/QUD.svg',
                icon: {
                    light: null,
                    dark: null,
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.RABOBANK]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/RAB.svg',
                icon: {
                    light: null,
                    dark: null,
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.RACQ]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/RAC.svg',
                icon: {
                    light: null,
                    dark: null,
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.SUNCORP]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/SUN.svg',
                icon: {
                    light: null,
                    dark: null,
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.ST_GEORGE]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/ST_GEORGE.svg',
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/ST_GEORGE_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.VIRGIN]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/VIR.svg',
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/VIRGIN_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.UBANK]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/864.svg',
                icon: {
                    light: null,
                    dark: null,
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.WAW_CREDIT_UNION]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/WAW.svg',
                icon: {
                    light: null,
                    dark: null,
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.WESTPAC]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/WES.svg',
                icon: {
                    light: null,
                    dark: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/icon/WESTPAC_DARK.svg',
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.VOLT]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/VOL.svg',
                icon: {
                    light: null,
                    dark: null,
                },
                full: {
                    light: null,
                    dark: null,
                },
            },
            [INSTITUTIONS.AUS_ETHICAL]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/AustralianEthicalSuper.svg',
            },
            [INSTITUTIONS.AUSTRALIANSUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/AustralianSuper.svg',
            },
            [INSTITUTIONS.AWARE_SUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/AwareSuper.svg',
            },
            [INSTITUTIONS.CARESUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/CareSuper.svg',
            },
            [INSTITUTIONS.CBUS]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/CbusSuper.svg',
            },
            [INSTITUTIONS.HESTA]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/Hesta.svg',
            },
            [INSTITUTIONS.HOSTPLUS]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/HostPlus.svg',
            },
            [INSTITUTIONS.QSUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/QSuper.svg',
            },
            [INSTITUTIONS.REST_SUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/RestSuper.svg',
            },
            [INSTITUTIONS.SUNSUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/SunSuper.svg',
            },
            [INSTITUTIONS.UNISUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/UniSuper.svg',
            },
            [INSTITUTIONS.VICSUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/VicSuper.svg',
            },
            [INSTITUTIONS.PRIME_SUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/PrimeSuper.svg',
            },
            [INSTITUTIONS.FIRST_SUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/FirstSuper.svg',
            },
            [INSTITUTIONS.VISION_SUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/VisionSuper.svg',
            },
            [INSTITUTIONS.SPIRIT_SUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/SpiritSuper.svg',
            },
            [INSTITUTIONS.NGS_SUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/NGSSuper.svg',
            },
            [INSTITUTIONS.AMG_SUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/AMGSuper.svg',
            },
            [INSTITUTIONS.EQUIP_MYFUTURE]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/EquipMyFuture.svg',
            },
            [INSTITUTIONS.CATHOLIC_SUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/CatholicSuper.svg',
            },
            [INSTITUTIONS.INTRUST_SUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/IntrustSuper.svg',
            },
            [INSTITUTIONS.AUSTRALIAN_CATHOLIC_SUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/AustralianCatholicSuper.svg',
            },
            [INSTITUTIONS.LGIA_SUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/LGIAsuper.svg',
            },
            [INSTITUTIONS.SMART_MONDAY]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/smartMonday.svg',
            },
            [INSTITUTIONS.ENERGY_SUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/super/square/EnergySuper.svg',
            },
            [INSTITUTIONS.ELEVATE_SUPER]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/elevate.png',
            },
            // ENERGY
            [INSTITUTIONS.OVO_ENERGY]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/OVO_ENERGY.svg',
            },
            [INSTITUTIONS.DIAMOND_ENERGY]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/DIAMOND_ENERGY2.svg',
                icon: {
                    light: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/icon/DIAMOND_ENERGY.svg',
                },
            },
            [INSTITUTIONS.NECTR]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/NECTR2.svg',
            },
            [INSTITUTIONS.GLO_BIRD]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/GLO_BIRD.svg',
            },
            [INSTITUTIONS.ENERGY_LOCALS]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/ENERGY_LOCALS2.svg',
            },
            [INSTITUTIONS.SUMO]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/SUMO.svg',
            },
            [INSTITUTIONS.AGL]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/AGL2.svg',
            },
            [INSTITUTIONS.ENERGYAUSTRALIA]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/ENERGY_AUSTRALIA.svg',
            },
            [INSTITUTIONS.ALINTA_ENERGY]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/ALINTA_ENERGY.svg',
            },
            [INSTITUTIONS.MOMENTUM_ENERGY]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/MOMENTUM_ENERGY.svg',
            },
            [INSTITUTIONS.POWERSHOP]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/POWERSHOP.svg',
            },
            [INSTITUTIONS.DODO_POWER_AND_GAS]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/DODO.svg',
            },
            [INSTITUTIONS.COVAU]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/COVAU.svg',
            },
            [INSTITUTIONS.ORIGIN_ENERGY]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/ORIGIN.svg',
            },
            [INSTITUTIONS.RED_ENERGY]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/RED_ENERGY2.svg',
            },
            [INSTITUTIONS.KOGAN_ENERGY]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/KOGAN.svg',
            },
            [INSTITUTIONS.REAMPED_ENERGY]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/REAMPED.svg',
            },
            [INSTITUTIONS.ELYSIAN_ENERGY]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/energy/square/ELYSIAN_ENERGY.svg',
            },
            // REWARDS
            [INSTITUTIONS.BETACARBON]: {
                square: 'https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/betacarbon.png',
            },
        }[businessName] || ''

    // If it's square, just return the svg
    if (type === 'square') {
        return source[type]
    }

    // If there's no theme specified, get light
    if ((type === 'icon' || type === 'full') && !theme) {
        // DEBUGGING
        // console.log(businessName + ': No theme specified, returning light')
        return source[type]['light']
    }

    // If the theme you want is not available, get the other one
    if (source[type][theme] === null) {
        if (theme === 'light') {
            // DEBUGGING
            // console.log(businessName + ': Light unavailable, returning dark')
            return source[type]['dark']
        }
        if (theme === 'dark') {
            // DEBUGGING
            // console.log(businessName + ': Dark unavailable, returning light')
            return source[type]['light']
        }
    }

    return source[type][theme]
}

export const GoogleIcon = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2936_850)">
            <path
                d="M11.2505 6.11654C11.2505 5.68488 11.2148 5.36988 11.1374 5.04321H6.10767V6.99152H9.06002C9.00052 7.47571 8.67909 8.20487 7.96479 8.69485L7.95478 8.76008L9.5451 9.96743L9.65528 9.97821C10.6672 9.06237 11.2505 7.71486 11.2505 6.11654"
                fill="#667085"
            />
            <path
                d="M6.10749 11.25C7.55389 11.25 8.76816 10.7833 9.65509 9.97836L7.96461 8.69498C7.51224 9.00416 6.90508 9.21999 6.10749 9.21999C4.69083 9.21999 3.48847 8.30417 3.05986 7.03833L2.99703 7.04356L1.3434 8.29774L1.32178 8.35666C2.20272 10.0717 4.01224 11.25 6.10749 11.25Z"
                fill="#667085"
            />
            <path
                d="M3.05999 7.03836C2.94689 6.7117 2.88144 6.36167 2.88144 6.00002C2.88144 5.63833 2.94689 5.28835 3.05404 4.96168L3.05104 4.89211L1.37668 3.6178L1.3219 3.64333C0.958824 4.35501 0.750488 5.15419 0.750488 6.00002C0.750488 6.84585 0.958824 7.64499 1.3219 8.35667L3.05999 7.03836"
                fill="#667085"
            />
            <path
                d="M6.10751 2.77999C7.11345 2.77999 7.79201 3.20582 8.17893 3.56167L9.69083 2.115C8.76229 1.26917 7.55392 0.75 6.10751 0.75C4.01225 0.75 2.20272 1.92832 1.32178 3.64331L3.05392 4.96166C3.48848 3.69583 4.69085 2.77999 6.10751 2.77999"
                fill="#667085"
            />
        </g>
        <defs>
            <clipPath id="clip0_2936_850">
                <rect width="12" height="12" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export const AppleIcon = () => (
    <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.7185 3.50832C8.66939 3.53697 7.50026 4.1416 7.50026 5.48219C7.55536 7.01106 8.97564 7.54722 9 7.54722C8.97564 7.57587 8.78558 8.27761 8.22258 9.01321C7.77577 9.64686 7.27985 10.2857 6.52679 10.2857C5.81046 10.2857 5.55332 9.86341 4.72679 9.86341C3.83916 9.86341 3.58801 10.2857 2.90841 10.2857C2.15535 10.2857 1.6227 9.61263 1.15153 8.98493C0.539419 8.16338 0.0191429 6.87413 0.000775505 5.63623C-0.0116025 4.98026 0.123358 4.33545 0.46595 3.78775C0.949491 3.02313 1.81276 2.50408 2.75549 2.48697C3.4778 2.46427 4.12066 2.94909 4.56148 2.94909C4.98393 2.94909 5.77373 2.48697 6.66734 2.48697C7.05306 2.48734 8.08163 2.59561 8.7185 3.50832ZM4.50039 2.356C4.37182 1.75695 4.72679 1.15791 5.0574 0.775782C5.47985 0.313661 6.14706 0 6.72244 0C6.75918 0.599045 6.52639 1.18656 6.11033 1.61444C5.73699 2.07657 5.09413 2.42446 4.50039 2.356Z"
            fill="#667085"
        />
    </svg>
)
