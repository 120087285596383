import * as React from 'react'
import * as styles from './styles'
import MuiAccordion from '@material-ui/core/Accordion'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import UserList from 'components/users/UserList'
import { InsightList } from './insightList'
const NgoLandingContent = ({ ngoName, data }) => {
    const [expanded, setExpanded] = React.useState<Array<string>>([])
    const Accordion = withStyles(styles.accordianStyles)(MuiAccordion)
    const AccordionSummary = withStyles(styles.AccordionSummaryStyles)(MuiAccordionSummary)
    const AccordionDetails = withStyles(styles.AccordionDetailStyles)(MuiAccordionDetails)

    const handleChange = (panel) => (event, newExpanded) => {
        if (expanded.indexOf(panel) > -1) {
            setExpanded(expanded.filter((a) => a != panel))
        } else {
            setExpanded([...expanded, panel])
        }
    }

    const nowTime = new Date()
    const twoWeeks = 60 * 60 * 24 * 7 * 2
    const activeUsers = data.Users.filter(
        (a) => Number(a.lastLogin) > nowTime.getTime() / 1000 - twoWeeks,
    )
    const currentInsights = data.CurrentInsights.filter((a) => a.Current === 'true')
    return (
        <div>
            <h1 style={{ fontSize: '16px', fontWeight: 'bold' }}>{ngoName}</h1>
            <Accordion
                square
                expanded={expanded.indexOf('panel1') > -1}
                onChange={handleChange('panel1')}
            >
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <div>
                        <div className={styles.attribute}>
                            <label className={styles.attributeLabel}>Total Users: </label>
                            <span className={styles.attributeValue}>{data.Users.length}</span>
                        </div>
                        <div className={styles.attribute}>
                            <label className={styles.attributeLabel}>Active Users: </label>
                            <span className={styles.attributeValue}>
                                {activeUsers.length} (
                                {(activeUsers.length / data.Users.length) * 100}%)
                            </span>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <UserList users={data.Users} />
                </AccordionDetails>
            </Accordion>
            <Accordion
                square
                expanded={expanded.indexOf('panel8') > -1}
                onChange={handleChange('panel8')}
            >
                <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                    <div>
                        {/* <div className={styles.attribute}>
                            <label className={styles.attributeLabel}>Total Current Insights: </label>
                            <span className={styles.attributeValue}>{currentInsights.length}</span>
                        </div> */}
                        <div className={styles.attribute}>
                            <label className={styles.attributeLabel}>Energy Insights: </label>
                            <span className={styles.attributeValue}>
                                {currentInsights.filter((a) => a.Domain === 'energy').length}
                            </span>
                        </div>
                        <div className={styles.attribute}>
                            <label className={styles.attributeLabel}>Super Insights: </label>
                            <span className={styles.attributeValue}>
                                {currentInsights.filter((a) => a.Domain === 'super').length}
                            </span>
                        </div>
                        <div className={styles.attribute}>
                            <label className={styles.attributeLabel}>Solar Insights: </label>
                            <span className={styles.attributeValue}>
                                {currentInsights.filter((a) => a.Domain === 'solar').length}
                            </span>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <InsightList insights={currentInsights} />
                </AccordionDetails>
            </Accordion>
            <Accordion
                square
                expanded={expanded.indexOf('panel2') > -1}
                onChange={handleChange('panel2')}
            >
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <div>
                        <div className={styles.attribute}>
                            <label className={styles.attributeLabel}>Total Switches: </label>
                            <span className={styles.attributeValue}>{data.Switches.length}</span>
                        </div>
                        <div className={styles.attribute}>
                            <label className={styles.attributeLabel}>
                                Users with at least 1 switch:{' '}
                            </label>
                            <span className={styles.attributeValue}>0</span>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
            </Accordion>
            <Accordion
                square
                expanded={expanded.indexOf('panel3') > -1}
                onChange={handleChange('panel3')}
            >
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <div>
                        <div className={styles.attribute}>
                            <label className={styles.attributeLabel}>Total Redemptions: </label>
                            <span className={styles.attributeValue}>{data.Redeems.length}</span>
                        </div>
                        <div className={styles.attribute}>
                            <label className={styles.attributeLabel}>
                                Total Dollars Redeemed:{' '}
                            </label>
                            <span className={styles.attributeValue}>
                                ${data.Redeems.reduce((a, b) => a + b, 0)}
                            </span>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
            </Accordion>
            <Accordion
                square
                expanded={expanded.indexOf('panel4') > -1}
                onChange={handleChange('panel4')}
            >
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <div>
                        <div className={styles.attribute}>
                            <label className={styles.attributeLabel}>
                                Average Carbon per user:{' '}
                            </label>
                            <span className={styles.attributeValue}>0</span>
                        </div>
                        <div className={styles.attribute}>
                            <label className={styles.attributeLabel}>Total Carbon reduced: </label>
                            <span className={styles.attributeValue}>0</span>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
            </Accordion>
        </div>
    )
}

export default NgoLandingContent
