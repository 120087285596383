import * as React from 'react'
import { ResponsiveLine } from '@nivo/line'
import { formatLineGraphTotalsAsPercentage } from './formatGraph'
import * as styles from '../../styles'
import * as CommonStyles from '../../../common/CommonStyles'
import { Tooltip } from '@material-ui/core'
import { format } from 'date-fns'
import { labeller } from './energyAnalysisPercentageSubmit'

const EnergyAnalysisPercentageUpload = ({ data, period, view, type = 'page' }) => {
    const graphData = formatLineGraphTotalsAsPercentage(data, 'UploadCount', view).map((x) =>
        view === 'usergroup'
            ? {
                  ...x,
                  id: labeller(x.id),
              }
            : x,
    )
    const pointSize = period === '1 Month' || period === '3 Months' || period === '6 Months' ? 6 : 3
    const tickRotation = period === 'All Time' || type === 'dash' ? 32 : 0
    const tickPadding = period === 'All Time' || type === 'dash' ? 4 : 8
    return (
        <div className={styles.container} style={{ height: type === 'dash' ? '500px' : '400px' }}>
            {type !== 'dash' && (
                <Tooltip
                    title={
                        <p style={{ fontSize: 14, margin: 8, lineHeight: 1.2 }}>
                            Total Accurassi Bill Uploads since inception as a percentage of all
                            users
                            {`${view !== 'total' ? ' in given group ' : ' '}`}at that date. Note
                            that staff
                            {`${view === 'usergroup' ? ' ' : ' and alpha users '}`}are not included.
                        </p>
                    }
                    placement="right"
                    arrow
                >
                    <div className={styles.padLeft}>
                        <h2 className={CommonStyles.MainSubHeaderClass}>
                            Bill Uploads (as a % of users
                            {`${view !== 'total' ? ' in given group' : ''}`})
                        </h2>
                    </div>
                </Tooltip>
            )}
            <ResponsiveLine
                data={graphData}
                tooltip={(slice) => {
                    const otherData = graphData
                        .filter((x) => x.id !== slice.point.serieId)
                        .map((x) => ({
                            ...x.data.find(
                                (y) => y.x?.toString() === slice.point.data.x.toString(),
                            ),
                            id: x.id,
                        }))

                    return (
                        <div
                            style={{
                                borderWidth: 1,
                                borderColor: 'rgba(0, 0, 0, 0.4)',
                                borderRadius: '8px',
                                backgroundColor: 'white',
                                padding: '5px 10px',
                                display: 'grid',
                                ...(type === 'dash'
                                    ? { position: 'absolute', width: '380px', top: 12, left: 12 }
                                    : {}),
                            }}
                        >
                            <span
                                style={{
                                    color: 'rgba(0,0,0,0.85)',
                                }}
                            >
                                {format(Number(slice.point.data.x), 'PPPP')}
                                {/* {formatDate(new Date(slice.id), "dd MMMM yyyy").toUpperCase()} */}
                            </span>
                            <div
                                key={slice.point.id}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <svg
                                    style={{
                                        marginRight: 4,
                                        height: '10px',
                                        width: '10px',
                                    }}
                                    viewBox="0 0 10 10"
                                >
                                    <circle cx="50%" cy="50%" r="4" fill={`${slice.point.color}`} />
                                </svg>
                                {Number(slice.point.data.y).toFixed(2)}% of {slice.point.serieId}
                            </div>
                            {otherData.map((d) => (
                                <div
                                    key={d.id}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginRight: 8,
                                    }}
                                >
                                    <svg
                                        style={{ height: '10px', width: '10px', marginRight: 4 }}
                                        viewBox="0 0 10 10"
                                    >
                                        {/* <circle
                                            cx="50%"
                                            cy="50%"
                                            r="4"
                                            fill={`${slice.point.color}`}
                                        /> */}
                                    </svg>
                                    {Number(d.y).toFixed(2)}% of {d.id}
                                </div>
                            ))}
                        </div>
                    )
                }}
                margin={{
                    top: type === 'dash' ? 10 : 50,
                    right: type === 'dash' ? 30 : 50,
                    bottom: 80,
                    left: 60,
                }}
                enablePoints={false}
                pointSize={pointSize}
                xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day' }}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: false,
                    // reverse: false,
                }}
                axisLeft={{
                    legend: 'Users (%)',
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}
                useMesh
                axisBottom={{
                    tickValues: 6,
                    format: (a) => format(a, type == 'dash' ? 'MM-yy' : 'PP'),
                    tickRotation,
                    tickPadding,
                }}
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: type === 'dash' ? 55 : 70,
                        itemWidth: 197,
                        itemHeight: 25,
                        itemsSpacing: 7,
                        symbolSize: 20,
                        symbolShape: 'circle',
                        itemDirection: 'left-to-right',
                        itemTextColor: '#777',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    )
}

export default EnergyAnalysisPercentageUpload
