import gql from 'graphql-tag'

export default gql`
    query mappings {
        mappings {
            items {
                YodleeProductName
                AccountType
                CdrProductCategory
                CdrInstitutionCode
                CdrProductID
                Institution {
                    name
                    institutionCode
                }
                Product {
                    name
                }
            }
        }
    }
`
