import { gql } from '@apollo/client'

export default gql`
    fragment CarbonResultsFragment on CarbonResult {
        # ResultID
        UserID
        # GeneratedAt
        Domain
        # TTL
        Emissions
        # Calculations
        Current
    }
`
