import gql from 'graphql-tag'

export default gql`
    query ($UserID: String!) {
        userEvent(UserID: $UserID) {
            Type
            EventID
            UserID
            CreatedAt
            MetaData
        }
    }
`
