import * as React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { TableHead, Table, TableRow, TableCell, TableBody } from '@material-ui/core'
import {
    Modal,
    Button,
    Paper,
    Snackbar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
} from '@material-ui/core'
import JSONInput from 'react-json-editor-ajrm'
import * as GraphTypes from 'graphql/types'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../../common/CommonStyles'
import products from 'graphql/queries/products'
import updateProductActive from 'graphql/mutations/updateProductActive'

const New = () => {
    const { loading, error, data, refetch } = useQuery(products, {
        fetchPolicy: 'no-cache',
        variables: {
            Category: 'TRANS_AND_SAVINGS_ACCOUNTS',
            IsActive: '',
        },
    })
    const [setProductActive] = useMutation(updateProductActive, {
        onCompleted: () => {
            refetch()
        },
    })
    const [updateModalVisible, setUpdateModalVisible] = React.useState(false)
    const [selectedProduct, setSelectedProduct] = React.useState<any>(null)
    if (loading) return <div>Loading</div>
    if (error) return <div>failure {console.error(error)} </div>
    const approveProduct = (code, id) => {
        setProductActive({
            variables: {
                productID: id,
                institutionCode: code,
                value: 'TRUE',
            },
        })
    }
    const rejectProduct = (code, id) => {
        setProductActive({
            variables: {
                productID: id,
                institutionCode: code,
                value: 'FALSE',
            },
        })
    }
    const sortedData = data.products.sort((a, b) => {
        const totalRateA = a.depositRates
            ? a.depositRates.map((x) => Number(x.rate)).reduce((p, n) => p + n, 0) * 100
            : 0
        const totalRateB = b.depositRates
            ? b.depositRates.map((x) => Number(x.rate)).reduce((p, n) => p + n, 0) * 100
            : 0
        return totalRateB - totalRateA
    })
    const tier1 = sortedData.filter((row) => {
        const totalRate = row.depositRates
            ? row.depositRates.map((x) => Number(x.rate)).reduce((p, n) => p + n, 0) * 100
            : 0
        return totalRate === 0
    })
    const tier2 = sortedData.filter((row) => {
        const totalRate = row.depositRates
            ? row.depositRates.map((x) => Number(x.rate)).reduce((p, n) => p + n, 0) * 100
            : 0
        return totalRate > 0 && totalRate <= 0.5
    })
    const tier3 = sortedData.filter((row) => {
        const totalRate = row.depositRates
            ? row.depositRates.map((x) => Number(x.rate)).reduce((p, n) => p + n, 0) * 100
            : 0
        return totalRate > 0.5 && totalRate <= 1
    })
    const tier4 = sortedData.filter((row) => {
        const totalRate = row.depositRates
            ? row.depositRates.map((x) => Number(x.rate)).reduce((p, n) => p + n, 0) * 100
            : 0
        return totalRate > 1
    })
    return (
        <div>
            <div>
                <BiIcons.BiNews size={35} style={{ display: 'inline-block' }} />
                <h1
                    style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                    className={CommonStyles.MainHeaderClass}
                >
                    New Products
                </h1>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell>InstitutionCode</TableCell>
                        <TableCell>Rates</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                            Total Products: {data.products.length}
                            <br />
                            <b>{tier1.length} blues </b>(0%) |<b>{tier2.length} green </b>(0-0.5%)
                            <br />
                            <b>{tier3.length} yellow </b>(0.5-1%) |<b>{tier4.length} orange </b>
                            (&gt;1%)
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData.map((row) => {
                        const totalRate = row.depositRates
                            ? row.depositRates
                                  .map((x) => Number(x.rate))
                                  .reduce((p, n) => p + n, 0) * 100
                            : 0
                        const tier1 = totalRate === 0
                        const tier2 = totalRate > 0 && totalRate <= 0.5
                        const tier3 = totalRate > 0.5 && totalRate <= 1
                        const tier4 = totalRate > 1
                        const backgroundColor = tier1
                            ? 'rgba(0, 0, 255, 0.3)'
                            : tier2
                            ? 'rgba(0, 255, 0, 0.3)'
                            : tier3
                            ? 'rgba(255, 255, 0, 0.3)'
                            : tier4
                            ? 'rgba(255, 159, 0, 0.3)'
                            : 'inherit'
                        return (
                            <TableRow style={{ backgroundColor }}>
                                <TableCell size="small">{row.name}</TableCell>
                                <TableCell size="small">{row.institutionCode}</TableCell>
                                <TableCell>
                                    {row.depositRates
                                        ? row.depositRates.map((r) => {
                                              return (
                                                  <div
                                                      style={{ width: '15vw', textAlign: 'center' }}
                                                  >
                                                      <div>
                                                          {r.depositRateType}{' '}
                                                          <b>{Math.round(r.rate * 10000) / 100}%</b>{' '}
                                                          - Tiers{' '}
                                                          <b>{r.tiers ? r.tiers.length : 0}</b>
                                                      </div>
                                                  </div>
                                              )
                                          })
                                        : 'None'}
                                </TableCell>
                                <TableCell>
                                    {row.eligibility
                                        ? row.eligibility.map((r) => {
                                              return (
                                                  <div
                                                      style={{ width: '20vw', textAlign: 'center' }}
                                                  >
                                                      <div>
                                                          {r.eligibilityType}:{' '}
                                                          <b>
                                                              {r.additionalValue} -
                                                              {r.additionalInfo}
                                                          </b>
                                                      </div>
                                                  </div>
                                              )
                                          })
                                        : 'None'}
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => {
                                            setSelectedProduct(row)
                                            setUpdateModalVisible(true)
                                        }}
                                    >
                                        View
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() =>
                                            approveProduct(row.institutionCode, row.productID)
                                        }
                                    >
                                        Activate
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            rejectProduct(row.institutionCode, row.productID)
                                        }
                                    >
                                        Reject
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>

            {/* Editing Modal */}
            <Modal
                open={updateModalVisible}
                onClose={() => setUpdateModalVisible(false)}
                onEscapeKeyDown={() => setUpdateModalVisible(false)}
            >
                <div
                    style={{
                        display: 'flex',
                        height: '100vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Paper elevation={3}>
                        {/* JSON Input section */}
                        <h3 style={{ textAlign: 'center' }}>
                            {selectedProduct?.Institution.name} - {selectedProduct?.name}
                        </h3>
                        <div style={{ margin: '8px', gap: '20px', justifyItems: 'center' }}>
                            <div>
                                Original CDR Product
                                <JSONInput
                                    id={selectedProduct ? `${selectedProduct.id}a` : 'a'}
                                    placeholder={
                                        selectedProduct
                                            ? selectedProduct.depositRates
                                                ? selectedProduct.depositRates
                                                : selectedProduct
                                            : {}
                                    }
                                    viewOnly
                                    // style={{
                                    //     container: {
                                    //         height: '91%',
                                    //         maxHeight: '91%',
                                    //     }
                                    // }}
                                />
                            </div>
                            {/* Buttons section */}
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'column',
                                }}
                            >
                                <Button onClick={() => setUpdateModalVisible(false)}>Close</Button>
                            </div>
                        </div>
                    </Paper>
                </div>
            </Modal>
        </div>
    )
}

export default New
