import * as React from 'react'
import {
    Avatar,
    AvatarGroup,
    Badge,
    Box,
    HStack,
    IconButton,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    Switch,
} from '@chakra-ui/react'

import { FiBarChart2, FiActivity, FiClipboard, FiUser, FiEyeOff, FiEye } from 'react-icons/fi'
// import { Rating } from './Rating'
import { identity } from 'ramda'
import { UniqueStringCounter, chakraColorSchemes } from './colorSchemeGenerator'
import SeededGradientAvatar from './Avatar'
import { AppleIcon, GoogleIcon } from 'utils/logo'

export interface Column {
    key: string
    type:
        | 'badge'
        | 'text'
        | 'avatar'
        | 'actions'
        | 'double_text'
        | 'status'
        | 'id_badge'
        | 'badges'
        | 'avatar_group'
        | 'toggle'
    // todo - depending on the type, calculate props should have different types
    calculateProps?: (value: RowData) => any
    format?: (value: RowData) => string
    header?: string
    tooltip?: (value: RowData) => string
}

export interface RowData {
    [key: string]: any
}

interface TableProps {
    columns: Column[]
    data: RowData[]
}

const counter = new UniqueStringCounter()

const getBadgeIcon = (icon: string): JSX.Element =>
    icon === 'analytics' ? (
        <FiBarChart2 />
    ) : icon === 'user' ? (
        <FiUser />
    ) : icon === 'eye' ? (
        <FiEye />
    ) : icon === 'eyeoff' ? (
        <FiEyeOff />
    ) : icon === 'clipboard' ? (
        <FiClipboard />
    ) : (
        <FiActivity />
    )

const renderComponentByType = (column: Column, item: RowData) => {
    const calculateProps = column.calculateProps ?? identity
    const format = column.format ?? ((x) => (column.key in x ? x[column.key] : x))
    const props = calculateProps(item)
    switch (column.type) {
        case 'status':
            return (
                <Badge
                    paddingX="8px"
                    paddingY="2px"
                    borderRadius="6"
                    backgroundColor="white"
                    borderColor="#D0D5DD"
                    border="1px #d0d5dd solid"
                    textTransform="none"
                >
                    <HStack alignContent="center" justifyItems="center" justifyContent="center">
                        <div
                            style={{
                                /* Dot */
                                width: '6px',
                                height: '6px',
                                borderRadius: 99,
                                marginTop: 1,
                                background: props.color,
                            }}
                        />
                        <Text marginLeft="6px !important" fontWeight="medium" fontSize="12px">
                            {props.text}
                        </Text>
                    </HStack>
                </Badge>
            )
        case 'badge':
            const uniqueNumber = counter.getUniqueNumber(props.textSeed)
            const colorScheme = props.color ? props.color : uniqueNumber
            return (
                <Badge
                    paddingX="8px"
                    paddingY="2px"
                    borderRadius="16"
                    size="sm"
                    colorScheme={colorScheme}
                    textTransform="none"
                >
                    {props.text}
                </Badge>
            )
        case 'toggle':
            return (
                <Switch isChecked={props.checked} colorScheme="green" onChange={props.onChange} />
            )
        case 'avatar_group':
            const label = `${props
                .slice(0, 4)
                .map((x) => x.name)
                .join(', ')}${props.length > 4 ? ' and more' : ''}`
            return (
                <Tooltip hasArrow borderRadius={8} textAlign="center" label={label}>
                    <AvatarGroup fontSize="12px" size="sm" max={4} spacing="-2">
                        {props.map((x, i) => (
                            <Avatar key={x.name + i} name={x.name} src={x.src} />
                        ))}
                    </AvatarGroup>
                </Tooltip>
            )
        case 'badges':
            return props.map((x) => (
                <Badge
                    key={x.text}
                    paddingX="8px"
                    paddingY="2px"
                    marginX="1px"
                    marginY="2px"
                    borderRadius="16"
                    size="sm"
                    colorScheme={chakraColorSchemes[counter.getUniqueNumber(x.textSeed ?? x.text)]}
                    textTransform="none"
                >
                    {x.text}
                </Badge>
            ))
        case 'id_badge':
            const userID: string = item[column.key]
            const authProvider = userID.split('|')[0]
            const icon = authProvider.includes('google') ? (
                <GoogleIcon />
            ) : authProvider.includes('apple') ? (
                <AppleIcon />
            ) : (
                ''
            ) // other
            return (
                <Badge
                    paddingX="8px"
                    paddingY="2px"
                    borderRadius="16"
                    size="sm"
                    colorScheme="gray"
                    textTransform="none"
                    cursor="pointer"
                    onClick={props.onClick}
                >
                    <HStack alignContent="center" justifyItems="center" justifyContent="center">
                        {/* <Text marginLeft="6px !important" fontWeight="medium" fontSize="12px"> */}
                        <Box marginBottom="1px" transform="scale(1.2)">
                            {icon}
                        </Box>
                        <Text
                            fontWeight="medium"
                            fontSize="12px"
                            marginInlineStart="5px !important"
                        >
                            {format(item)}
                        </Text>
                    </HStack>
                </Badge>
            )
        case 'avatar':
            return (
                <HStack spacing="2">
                    <SeededGradientAvatar seed={props} size={40} />
                    <div>
                        <Text fontWeight="medium" fontSize="14px">
                            {format(item)}
                        </Text>
                    </div>
                </HStack>
            )

        // icon list - https://react-icons.github.io/react-icons/icons?name=fi
        case 'actions':
            return (
                <HStack spacing="1">
                    {props.map((x, index) => (
                        <IconButton
                            key={x.icon + index}
                            onClick={x.onClick}
                            icon={getBadgeIcon(x.icon)}
                            variant="tertiary"
                            aria-label="Analytics"
                        />
                    ))}
                </HStack>
            )
        case 'double_text':
            return (
                <HStack spacing="2">
                    {props.avatar ? <SeededGradientAvatar seed={props.avatar} size={40} /> : null}
                    <div>
                        <Text fontSize="14px" fontWeight="medium" color={'medium'}>
                            {props.title}
                        </Text>
                        <Text fontSize="14px" fontWeight="medium" color={'blackAlpha.600'}>
                            {props.subtitle}
                        </Text>
                    </div>
                </HStack>
            )
        case 'text':
            return (
                <>
                    <Text fontWeight="normal" fontSize="14px" color="#667085">
                        {format(item)}
                    </Text>
                </>
            )
        default:
            return 'N/A'
    }
}

export const MemberTable = ({ data, columns, ...rest }: TableProps) => {
    return (
        <>
            <Table {...rest}>
                <Thead>
                    <Tr backgroundColor="#F9FAFB">
                        {columns.map((column) => (
                            <Th key={column.key}>
                                <HStack spacing="3">
                                    <Text color="#667085" textTransform="none" fontWeight="medium">
                                        {column.header ?? column.key}
                                    </Text>
                                </HStack>
                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>
                    {data.map((item, rowIndex) => (
                        <Tr key={rowIndex}>
                            {columns.map((column) => (
                                <Td key={column.key + rowIndex}>
                                    {column.tooltip ? (
                                        <Tooltip
                                            hasArrow
                                            borderRadius={8}
                                            textAlign="center"
                                            label={column.tooltip(item)}
                                        >
                                            {renderComponentByType(column, item)}
                                        </Tooltip>
                                    ) : (
                                        renderComponentByType(column, item)
                                    )}
                                </Td>
                            ))}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            {data.length === 0 ? (
                <Text mt={8} align="center" justifyItems="center">
                    No data found.
                </Text>
            ) : null}
        </>
    )
}
