import * as React from 'react'
import { useQuery } from '@apollo/client'
import superEngagement from 'graphql/queries/superEngagement'
import { Grid, Switch, Typography, Select, MenuItem } from '@material-ui/core'
import SuperCount from './superUserEngagementGraph'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../../../common/CommonStyles'
import { formatRFC3339, subMonths } from 'date-fns'

const periods = ['1 Month', '3 Months', '6 Months', '12 Months', 'All Time']
const mo1 = subMonths(new Date(), 1)
const mo3 = subMonths(new Date(), 3)
const mo6 = subMonths(new Date(), 6)
const mo12 = subMonths(new Date(), 12)
const at = new Date('2020-01-01T00:00:00')
const endDate = formatRFC3339(new Date())

export const SuperAnalysis = () => {
    const [period, setPeriod] = React.useState(periods[1])
    const dateToUs =
        period === '1 Month'
            ? mo1
            : period === '3 Months'
            ? mo3
            : period === '6 Months'
            ? mo6
            : period === '12 Months'
            ? mo12
            : at

    const startDate = formatRFC3339(dateToUs)
    const { loading, error, data } = useQuery(superEngagement, {
        fetchPolicy: 'cache-first',
        variables: {
            startDate,
            endDate,
        },
    })
    const [view, setView] = React.useState('total')
    if (loading && !data) return <div>Loading</div>
    if (error) return <div>{console.error(error)}error</div>
    return (
        <div>
            <BiIcons.BiLineChart size={35} style={{ display: 'inline-block' }} />
            <h1
                style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                className={CommonStyles.MainHeaderClass}
            >
                Super Engagement
            </h1>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>Total</Grid>
                        <Grid item>
                            <Switch
                                checked={view == 'group'}
                                onChange={() =>
                                    view == 'total' ? setView('group') : setView('total')
                                }
                                name="checkedC"
                            />
                        </Grid>
                        <Grid item>By Group</Grid>
                    </Grid>
                </Typography>
                <div style={{ justifySelf: 'end' }}>
                    <Select
                        labelId="pick-type"
                        id="pick-type"
                        value={period}
                        onChange={(event) => setPeriod(event.target.value as string)}
                    >
                        {periods.map((p) => (
                            <MenuItem value={p}>{p}</MenuItem>
                        ))}
                    </Select>
                </div>
            </div>
            <div style={{ margin: '0 48px' }}>
                <SuperCount
                    data={data.superAnalysis}
                    view={view}
                    period={period}
                    type="page"
                    // title="Super Engagement Count"
                    title="Personalised Users (%)"
                    selection="SuperEngagementCount"
                />
            </div>
        </div>
    )
}
