import { useMutation, useQuery } from '@apollo/client'
import * as React from 'react'
import appVersion from 'graphql/mutations/appVersion'
import { Button, Input } from '@material-ui/core'

const AppVersion = () => {
    const [version, setVersion] = React.useState('')
    const [m] = useMutation(appVersion, {
        onCompleted(data) {
            if (data.appVersion) setVersion(data.appVersion)
            else alert('DONE!')
        },
        onError(m) {
            alert(m)
        },
    })

    React.useEffect(() => {
        m()
    }, [])

    const submit = () => {
        m({
            variables: {
                version: version,
            },
        })
    }
    if (!version) return <></>
    return (
        <div>
            <span>App Version: </span>
            <Input value={version} onChange={(e) => setVersion(e.target.value)} />
            <Button onClick={() => submit()}>GO</Button>
        </div>
    )
}
export default AppVersion
