import NgoLanding from 'components/ngo'
import NgoAdminLanding from 'components/ngo/ngoAdmin'
import Widgets from 'components/widgets'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

const NgoRoutes = () => (
    <Switch>
        <Route path="/" exact component={NgoAdminLanding} />
        <Route path="/widgets" component={Widgets} />
    </Switch>
)

export default NgoRoutes
