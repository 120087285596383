import { Box, Center } from '@chakra-ui/react'
import * as React from 'react'

const EnergyWidget = () => {
    React.useEffect(() => {
        const script = document.createElement('script')
        script.async = true
        script.src =
            'https://energy-widget.dev.acaciamoney.com/index.js?ts=' + Date.now().toString()
        document.body.appendChild(script)

        const linkElement = document.createElement('link')
        linkElement.setAttribute('rel', 'stylesheet')
        linkElement.setAttribute('type', 'text/css')
        linkElement.setAttribute('href', 'https://energy-widget.dev.acaciamoney.com/index.css')
        document.head.appendChild(linkElement)
    }, [])
    return (
        <Box>
            <Center borderRadius="20">
                <Box
                    id="energy-widget-window"
                    className="acacia-energy-widget"
                    height="100vh"
                    width="550px"
                    maxWidth="550px"
                    maxHeight="850px"
                    borderRadius="24px"
                    overflowY="hidden"
                />
            </Center>
        </Box>
    )
}

export default EnergyWidget
