import * as React from 'react'
import * as GraphTypes from 'graphql/types'
import * as styles from '../../../common/CommonStyles'
import MaterialTable from 'material-table'

type Props = {
    data: any
}

const dp = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
}

const UserSummary = ({ data }: Props) => {
    let tableData1 = [
        {
            Type: '5+ Logins',
            AvgAccounts: `${dp(
                data.Active.TotalAccounts / data.Active.TotalUsersWithProviderAccount,
            )}`,
            Total: `${data.Active.Total} (${dp((data.Active.Total / data.Total) * 100)}%)`,
            AvgBalance: `$${dp(Number(data.Active.TotalBalance) / Number(data.Active.Total))}`,
            AvgNetWorth: `$${dp(Number(data.Active.TotalNetWorh) / Number(data.Active.Total))}`,
            TotalUsersWithProviderAccount: `${dp(
                Number(data.Active.TotalUsersWithProviderAccount) / Number(data.Active.Total),
            )} (${data.Active.TotalUsersWithProviderAccount})`,
            UsersWithLoanCount: `${dp(
                (Number(data.Active.UsersWithLoanCount) / Number(data.Active.Total)) * 100,
            )}% (${data.Active.UsersWithLoanCount})`,
        },
        {
            Type: '10+ Logins',
            AvgAccounts: `${dp(
                data.TenLogins.TotalAccounts / data.TenLogins.TotalUsersWithProviderAccount,
            )}`,
            Total: `${data.TenLogins.Total} (${dp((data.TenLogins.Total / data.Total) * 100)}%)`,
            AvgBalance: `$${dp(
                Number(data.TenLogins.TotalBalance) / Number(data.TenLogins.Total),
            )}`,
            AvgNetWorth: `$${dp(
                parseFloat(data.TenLogins.TotalNetWorh) / Number(data.TenLogins.Total),
            )}`,
            TotalUsersWithProviderAccount: `${dp(
                Number(data.TenLogins.TotalUsersWithProviderAccount) / Number(data.TenLogins.Total),
            )} (${data.TenLogins.TotalUsersWithProviderAccount})`,
            UsersWithLoanCount: `${dp(
                (Number(data.TenLogins.UsersWithLoanCount) / Number(data.TenLogins.Total)) * 100,
            )}% (${data.TenLogins.UsersWithLoanCount})`,
        },
        {
            Type: '15+ Logins',
            AvgAccounts: `${dp(
                data.FifteenLogins.TotalAccounts / data.FifteenLogins.TotalUsersWithProviderAccount,
            )}`,
            Total: `${data.FifteenLogins.Total} (${dp(
                (data.FifteenLogins.Total / data.Total) * 100,
            )}%)`,
            AvgBalance: `$${dp(
                Number(data.FifteenLogins.TotalBalance) / Number(data.FifteenLogins.Total),
            )}`,
            AvgNetWorth: `$${dp(
                parseFloat(data.FifteenLogins.TotalNetWorh) / Number(data.FifteenLogins.Total),
            )}`,
            TotalUsersWithProviderAccount: `${dp(
                Number(data.FifteenLogins.TotalUsersWithProviderAccount) /
                    Number(data.FifteenLogins.Total),
            )} (${data.FifteenLogins.TotalUsersWithProviderAccount})`,
            UsersWithLoanCount: `${dp(
                (Number(data.FifteenLogins.UsersWithLoanCount) / Number(data.FifteenLogins.Total)) *
                    100,
            )}% (${data.FifteenLogins.UsersWithLoanCount})`,
        },
    ]
    let tableData2 = [
        {
            Type: 'Has Linked a Bank',
            AvgAccounts: `${dp(
                data.LinkedBank.TotalAccounts / data.LinkedBank.TotalUsersWithProviderAccount,
            )}`,
            Total: `${data.LinkedBank.Total} (${dp((data.LinkedBank.Total / data.Total) * 100)}%)`,
            AvgBalance: `$${dp(
                Number(data.LinkedBank.TotalBalance) / Number(data.LinkedBank.Total),
            )}`,
            AvgNetWorth: `$${dp(
                parseFloat(data.LinkedBank.TotalNetWorh) / Number(data.LinkedBank.Total),
            )}`,
            TotalUsersWithProviderAccount: `${dp(
                Number(data.LinkedBank.TotalUsersWithProviderAccount) /
                    Number(data.LinkedBank.Total),
            )} (${data.LinkedBank.TotalUsersWithProviderAccount})`,
            UsersWithLoanCount: `${dp(
                (Number(data.LinkedBank.UsersWithLoanCount) / Number(data.LinkedBank.Total)) * 100,
            )}% (${data.LinkedBank.UsersWithLoanCount})`,
        },
        {
            Type: 'Has Personalised Super',
            AvgAccounts: `${dp(
                data.PersSuper.TotalAccounts / data.PersSuper.TotalUsersWithProviderAccount,
            )}`,
            Total: `${data.PersSuper.Total} (${dp((data.PersSuper.Total / data.Total) * 100)}%)`,
            AvgBalance: `$${dp(
                Number(data.PersSuper.TotalBalance) / Number(data.PersSuper.Total),
            )}`,
            AvgNetWorth: `$${dp(
                parseFloat(data.PersSuper.TotalNetWorh) / Number(data.PersSuper.Total),
            )}`,
            TotalUsersWithProviderAccount: `${dp(
                Number(data.PersSuper.TotalUsersWithProviderAccount) / Number(data.PersSuper.Total),
            )} (${data.PersSuper.TotalUsersWithProviderAccount})`,
            UsersWithLoanCount: `${dp(
                (Number(data.PersSuper.UsersWithLoanCount) / Number(data.PersSuper.Total)) * 100,
            )}% (${data.PersSuper.UsersWithLoanCount})`,
        },
        {
            Type: 'Has Uploaded Energy Bill',
            AvgAccounts: `${dp(
                data.UploadedEnergyBill.TotalAccounts /
                    data.UploadedEnergyBill.TotalUsersWithProviderAccount,
            )}`,
            Total: `${data.UploadedEnergyBill.Total} (${dp(
                (data.UploadedEnergyBill.Total / data.Total) * 100,
            )}%)`,
            AvgBalance: `$${dp(
                Number(data.UploadedEnergyBill.TotalBalance) /
                    Number(data.UploadedEnergyBill.Total),
            )}`,
            AvgNetWorth: `$${dp(
                parseFloat(data.UploadedEnergyBill.TotalNetWorh) /
                    Number(data.UploadedEnergyBill.Total),
            )}`,
            TotalUsersWithProviderAccount: `${dp(
                Number(data.UploadedEnergyBill.TotalUsersWithProviderAccount) /
                    Number(data.UploadedEnergyBill.Total),
            )} (${data.UploadedEnergyBill.TotalUsersWithProviderAccount})`,
            UsersWithLoanCount: `${dp(
                (Number(data.UploadedEnergyBill.UsersWithLoanCount) /
                    Number(data.UploadedEnergyBill.Total)) *
                    100,
            )}% (${data.UploadedEnergyBill.UsersWithLoanCount})`,
        },
    ]

    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 50%)', gap: '0px' }}>
            <div style={{ padding: '5px' }}>
                <MaterialTable
                    title="User Analysis"
                    options={{
                        header: true,
                        paging: false,
                        search: false,
                    }}
                    columns={[
                        { title: 'Category', field: 'Type' },
                        { title: 'Total', field: 'Total' },
                        { title: 'AvgAccounts', field: 'AvgAccounts' },
                        { title: 'Average Balance', field: 'AvgBalance' },
                        { title: 'Average NetWorh', field: 'AvgNetWorth' },
                        { title: 'Users With A Loan', field: 'UsersWithLoanCount' },
                    ]}
                    data={tableData1}
                />
                <MaterialTable
                    title="User Analysis"
                    options={{
                        header: true,
                        paging: false,
                        search: false,
                    }}
                    columns={[
                        { title: 'Category', field: 'Type' },
                        { title: 'Total', field: 'Total' },
                        { title: 'AvgAccounts', field: 'AvgAccounts' },
                        { title: 'Average Balance', field: 'AvgBalance' },
                        { title: 'Average NetWorh', field: 'AvgNetWorth' },
                        { title: 'Users With A Loan', field: 'UsersWithLoanCount' },
                    ]}
                    data={tableData2}
                />
            </div>
            <div style={{ padding: '5px' }}>
                <h1 className={styles.DashHeaderClass}>Bank links</h1>
                {data.Active.ProviderAccounts.map((a) => (
                    <div
                        style={{
                            backgroundColor: 'rgba(0,0,0,0.1)',
                            padding: '5px 10px',
                            margin: '2px',
                            float: 'left',
                        }}
                    >
                        <div>
                            <strong>{a.Name}</strong>
                        </div>
                        <div>{a.Count}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default UserSummary
