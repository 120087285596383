import * as React from 'react'
import MaterialTable, { Icons } from 'material-table'
import { Button } from '@material-ui/core'
import { tableIcons } from 'components/common/MaterialIcons'

const UserList = ({ users }) => {
    let userData = users.map((a) => {
        return {
            email: a.email,
            user_id: a.user_id,
            lastLogin: a.lastLogin,
            group: a.group,
            version: a.version,
            loginCount: a.loginCount,
        }
    })

    return (
        <MaterialTable
            icons={tableIcons as Icons}
            title={''}
            columns={[
                {
                    title: 'Link',
                    field: 'link',
                    render: (rowData) => {
                        return (
                            <Button
                                onClick={() =>
                                    window.open(
                                        `https://analytics.amplitude.com/acacia/project/331932/search/${rowData.user_id}`,
                                        '_blank'
                                    )
                                }
                            >
                                !
                            </Button>
                        )
                    },
                },
                { title: 'ID', field: 'user_id' },
                { title: 'Email', field: 'email' },
                {
                    title: 'Last Login',
                    field: 'lastLogin',
                    render: (rowData) => new Date(rowData.lastLogin * 1000).toDateString(),
                    defaultSort: 'desc',
                },
                { title: 'Logins', field: 'loginCount', type: 'numeric' },
                { title: 'Version', field: 'version' },
                { title: 'Group', field: 'group' },
            ]}
            data={userData}
            options={{
                showTitle: false,
                sorting: true,
                filtering: true,
                search: true,
                pageSize: 50,
            }}
            onRowClick={(event, rowData: any) => {
                window.open(`/users/${rowData.user_id}`, '_blank')
            }}
        />
    )
}

export default UserList
