import { gql } from '@apollo/client'

export default gql`
    query products($Category: String, $IsActive: String, $InstitutionCode: String) {
        products(Category: $Category, IsActive: $IsActive, InstitutionCode: $InstitutionCode) {
            productID
            productCategory
            name
            # applicationURI
            # description
            institutionCode
            lastUpdated
            eligibility {
                eligibilityType
                additionalValue
                additionalInfo
            }
            # customEligibility
            depositRates {
                depositRateType
                rate
                calculationFrequency
                additionalInfo
                additionalValue
                tiers {
                    name
                    unitOfMeasure
                    minimumValue
                    maximumValue
                    rateApplicationMethod
                }
            }
            Institution {
                name
            }
        }
    }
`
