import gql from 'graphql-tag'

export default gql`
    mutation mappingType(
        $institutionCode: String
        $basiqProductName: String
        $productDescription: String
        $accountType: String!
    ) {
        mappingType(
            institutionCode: $institutionCode
            basiqProductName: $basiqProductName
            productDescription: $productDescription
            accountType: $accountType
        )
    }
`
