import gql from 'graphql-tag'

export default gql`
    query ($user_id: String) {
        userAttributes(user_id: $user_id) {
            items {
                key
                value
                dateUpdated
            }
        }
    }
`
