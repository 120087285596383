import gql from 'graphql-tag'

export default gql`
    mutation userGroup(
        $Action: String!
        $ID: String
        $UserID: String
        $Name: String
        $Type: String
    ) {
        userGroup(Action: $Action, ID: $ID, UserID: $UserID, Name: $Name, Type: $Type)
    }
`
