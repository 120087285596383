import * as React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { formatBarGraph } from './formatGraph'
import * as CommonStyles from '../../../../common/CommonStyles'
import { format } from 'date-fns'
import * as GraphTypes from 'graphql/types'

type Props = {
    data: GraphTypes.SwitchesResult[]
}

const Graph = ({ data }: Props) => {
    const graphData = formatBarGraph(data)
    const tickRotation = 32
    const tickPadding = 4

    return (
        <div className={CommonStyles.DashGraphContainer}>
            <ResponsiveBar
                data={graphData}
                indexBy="Date"
                groupMode="grouped"
                keys={[
                    'Savings - Users who have linked a bank',
                    'Energy - Users who have uploaded a bill',
                    'Super - Users who have personalised super',
                ]}
                margin={{ top: 10, right: 40, bottom: 35, left: 40 }}
                axisLeft={{
                    legend: 'Switches',
                    legendPosition: 'middle',
                    legendOffset: -32,
                }}
                enableLabel={false}
                tooltipLabel={(d) => `${d.id} - ${format(new Date(d.indexValue), 'PPP')}`}
                axisBottom={{
                    format: (a) => format(new Date(a), 'MM-yy'),
                    tickRotation,
                    tickPadding,
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top-left',
                        direction: 'column',
                        translateX: 5,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 16,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    )
}

export default Graph
