import gql from 'graphql-tag'

export default gql`
    query ignoredProducts {
        ignoredProducts {
            items {
                productDescription
                ignoreMapping
                userID
                timestamp
                accountType
            }
        }
    }
`
