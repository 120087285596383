import gql from 'graphql-tag'

export default gql`
    query features {
        features {
            items {
                key
                feature
                UserGroup
                isHidden
                description
            }
        }
    }
`
