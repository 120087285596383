import * as React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
    Modal,
    Button,
    Paper,
    Snackbar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
} from '@material-ui/core'
import productUpdatesDocument from 'graphql/queries/productUpdates'
import JSONInput from 'react-json-editor-ajrm'
import createProductUpdate from 'graphql/mutations/createProductUpdate'
import updateProductUpdate from 'graphql/mutations/updateProductUpdate'
import { Alert, Autocomplete, createFilterOptions } from '@material-ui/lab'
import deleteProductUpdate from 'graphql/mutations/deleteProductUpdate'

import institutionsDocument from 'graphql/queries/institutions'
import { getUnixTime } from 'date-fns'
import { useAuth0 } from 'components/auth/react-auth0-wrapper'
import { convertFromUnixTime, sortAlphabetically, tryParseJSON } from './utils'
import RenderTable from './Table'
import productsDocument from 'graphql/queries/products'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../../common/CommonStyles'
import { debounce } from 'lodash'
import { Button as ChakraButton, ButtonGroup, HStack, Image } from '@chakra-ui/react'
import { popular } from './data'
import * as GraphTypes from '../../../graphql/types'

const ProductUpdates = () => {
    const [institutionSearch, setInstitutionSearch] = React.useState('ing')
    const [institutionModalVisible, setInstitutionModalVisible] = React.useState(false)

    const { loading, error: iError, data: iData } = useQuery(institutionsDocument)

    const searchHandler = (value) => {
        setInstitutionSearch(value)
    }
    const debouncedChangeHandler = React.useMemo(() => debounce(searchHandler, 1000), [])

    const [snackSuccessVisible, setSnackSuccessVisible] = React.useState(false)
    const [snackErrorVisible, setSnackErrorVisible] = React.useState(false)
    const [snackErrorMessage, setSnackErrorMessage] = React.useState('')
    const [viewModalVisible, setViewModalVisible] = React.useState(false)
    const [updateModalVisible, setUpdateModalVisible] = React.useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = React.useState(false)

    const [newComment, setNewComment] = React.useState('')
    const [commentHistory, setCommentHistory] = React.useState<any[]>([])
    const [viewCommentData, setViewCommentData] = React.useState<any>({})
    const [selectedProduct, setSelectedProduct] = React.useState<any>(null)
    const [fieldToEdit, setFieldToEdit] = React.useState<'DepositRates' | 'Eligibility'>(
        'DepositRates',
    )
    const [updatedValue, setUpdatedValue] = React.useState<any>({})

    const [create] = useMutation(createProductUpdate, {
        refetchQueries: ['productUpdates'],
        onCompleted: () => {
            setSnackSuccessVisible(true)
            setUpdateModalVisible(false)
            setCommentHistory([])
        },
        onError: (e) => {
            setSnackErrorMessage(e.message)
            setSnackErrorVisible(true)
        },
    })

    const [update] = useMutation(updateProductUpdate, {
        refetchQueries: ['productUpdates'],
        onCompleted: () => {
            setSnackSuccessVisible(true)
            setUpdateModalVisible(false)
            setCommentHistory([])
        },
        onError: (e) => {
            setSnackErrorMessage(e.message)
            setSnackErrorVisible(true)
        },
    })

    const [deleteMutation] = useMutation(deleteProductUpdate, {
        refetchQueries: ['productUpdates'],
        onCompleted: () => {
            setDeleteModalVisible(false)
            setSnackSuccessVisible(true)
            setUpdateModalVisible(false)
            setCommentHistory([])
        },
        onError: (e) => {
            setSnackErrorMessage(e.message)
            setSnackErrorVisible(true)
        },
    })

    const {
        loading: puLoading,
        error: puError,
        data: puData,
        refetch: puRefetch,
    } = useQuery(productUpdatesDocument, { fetchPolicy: 'no-cache' })
    const { loading: uLoading, user } = useAuth0()
    const {
        loading: pLoading,
        error: pError,
        data: pData,
        refetch: pRefetch,
    } = useQuery(productsDocument, {
        fetchPolicy: 'no-cache',
        variables: {
            Category: 'TRANS_AND_SAVINGS_ACCOUNTS',
            IsActive: 'TRUE',
            InstitutionCode: institutionSearch,
        },
    })

    // if (uLoading || puLoading || pLoading) return <div>Loading</div>
    if (puError || pError) console.error(puError || pError)
    const products: GraphTypes.Product[] = pData?.products ?? []
    const updates: GraphTypes.ProductUpdate[] = puData?.productUpdates ?? []
    const institutions: GraphTypes.Institution[] = iData?.institutions?.items ?? []
    const sortedInstitutions = sortAlphabetically(institutions, 'name')
    const mutationType =
        selectedProduct &&
        selectedProduct.productUpdate.filter(
            (p) => p.updatedKey === fieldToEdit && !!p.updatedValue,
        ).length > 0
            ? 'edit'
            : 'create'

    const createAndAppendComment = (
        uneditedValue: string,
        updatedValue: string,
        comment?: string,
    ) => {
        return JSON.stringify(
            commentHistory.concat({
                comment: comment ? comment : newComment,
                user: user?.email,
                dateCreated: getUnixTime(new Date()),
                uneditedValue,
                updatedValue,
            }),
        )
    }

    const handleChange = (isApprove = false) => {
        if (newComment === '' && !isApprove) {
            setSnackErrorMessage('Please provide a comment before submitting')
            setSnackErrorVisible(true)
        } else {
            const updated =
                Object.keys(updatedValue).length === 0 ? '' : JSON.stringify(updatedValue)
            const key = fieldToEdit === 'DepositRates' ? 'depositRates' : 'eligibility'
            const uneditedValue = JSON.stringify(selectedProduct[key])
            const variables = {
                institutionCodeProductID: `${selectedProduct.institutionCode}+${selectedProduct.productID}`,
                updatedKey: fieldToEdit,
                updatedValue: updated,
                state: isApprove ? 'APPROVED' : 'EDITED',
                comments: isApprove
                    ? createAndAppendComment(
                          uneditedValue,
                          updated,
                          'Confirmed that data is in line with live product',
                      )
                    : createAndAppendComment(uneditedValue, updated),
                uneditedValue,
            }
            if (mutationType === 'create') {
                create({ variables })
            } else if (mutationType === 'edit') {
                update({ variables })
            }
        }
    }

    const handleDelete = async () => {
        deleteMutation({
            variables: {
                institutionCodeProductID: `${selectedProduct.institutionCode}+${selectedProduct.productID}`,
                updatedKey: fieldToEdit,
            },
        })
    }

    const handleComment = (e) => {
        setNewComment(e.target.value)
    }

    const handleEditOpen = (type: 'Eligibility' | 'DepositRates') => (row) => {
        setSelectedProduct(row)
        setFieldToEdit(type)
        const productUpdate = row?.productUpdate?.find((x) => x.updatedKey === type)
        setUpdatedValue(
            !!productUpdate && !!productUpdate.updatedValue
                ? JSON.parse(productUpdate.updatedValue)
                : {},
        )
        const comments = tryParseJSON(productUpdate.comments)
        if (comments) setCommentHistory(comments.sort((p, n) => n.dateCreated - p.dateCreated))
        else setCommentHistory([])
        setUpdateModalVisible(true)
    }

    return (
        <div>
            <div>
                <BiIcons.BiBulb size={35} style={{ display: 'inline-block' }} />
                <h1
                    style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                    className={CommonStyles.MainHeaderClass}
                >
                    Acacia Product Updates
                </h1>
            </div>

            {puError && <p style={{ color: 'red' }}>{JSON.stringify(puError)}</p>}
            {/* Error/success messages */}
            <Snackbar
                open={snackErrorVisible}
                autoHideDuration={6000}
                onClose={() => setSnackErrorVisible(false)}
            >
                <Alert severity="error">Error: {snackErrorMessage}</Alert>
            </Snackbar>
            <Snackbar
                open={snackSuccessVisible}
                autoHideDuration={6000}
                onClose={() => setSnackSuccessVisible(false)}
            >
                <Alert severity="success">Success!</Alert>
            </Snackbar>

            {/* Delete confirmation modal */}
            <Dialog
                open={deleteModalVisible}
                onClose={() => setDeleteModalVisible(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the {fieldToEdit} value for{' '}
                        {selectedProduct?.name}, and use the original value from the CDR?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteModalVisible(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Proceed to Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {institutionSearch !== '' && pError && (
                <p style={{ color: 'red' }}>{JSON.stringify(pError)}</p>
            )}

            <HStack>
                <Autocomplete
                    id="find-institution"
                    options={sortedInstitutions.filter((x) => x.type == 'Bank')}
                    getOptionLabel={(option) => option.name || ''}
                    getOptionSelected={(option, value) => option.name === value.name}
                    value={{
                        name: sortedInstitutions.filter(
                            (x) => x.institutionCode === institutionSearch,
                        )[0]?.name,
                        institutionCode: null,
                        active: null,
                        cdrUrl: null,
                        type: null,
                    }}
                    onChange={(e, value: GraphTypes.Institution) => {
                        if (value) {
                            debouncedChangeHandler(value.institutionCode)
                        }
                    }}
                    filterOptions={createFilterOptions({
                        stringify: (option) => JSON.stringify(option), // match autocomplete on any field
                    })}
                    style={{ width: 325 }}
                    renderInput={(params) => (
                        <TextField {...params} label="Search" variant="outlined"  />
                    )}
                    clearOnBlur
                />
                <ButtonGroup variant="outline" spacing="4" width="full">
                    {popular.map(({ code, icon }) => (
                        <ChakraButton
                            key={code}
                            onClick={() => setInstitutionSearch(code)}
                            width="16"
                            height="16"
                        >
                            <Image src={icon} alt={code} width="full" />
                        </ChakraButton>
                    ))}
                </ButtonGroup>
            </HStack>

            {/* Table */}
            <RenderTable
                products={products}
                updates={updates}
                handleEditOpen={handleEditOpen}
                loading={pLoading}
            />

            {/* Institution Modal */}
            <Modal
                open={institutionModalVisible}
                onClose={() => setInstitutionModalVisible(false)}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Paper style={{ width: '80%', height: '80%', overflowY: 'scroll' }} elevation={3}>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                            columnGap: 4,
                            // height: '100vh',
                            // justifyContent: 'center',
                            // alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        {iError && JSON.stringify(iError)}
                        {institutions
                            .filter((x) => x.type == 'Bank')
                            .sort((a, b) => (a.name as string).localeCompare(b.name))
                            .map((x) => (
                                <div
                                    key={x.institutionCode}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: 4,
                                    }}
                                >
                                    <Button
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        onClick={() => {
                                            setInstitutionSearch(x.institutionCode)
                                            setInstitutionModalVisible(false)
                                        }}
                                    >
                                        {x.name}
                                    </Button>
                                </div>
                            ))}
                    </div>
                </Paper>
            </Modal>

            {/* Editing Modal */}
            <Modal open={updateModalVisible} onClose={() => setUpdateModalVisible(false)}>
                <div
                    style={{
                        display: 'flex',
                        height: '100vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Paper elevation={3}>
                        {/* JSON Input section */}
                        <h3 style={{ textAlign: 'center' }}>
                            {selectedProduct?.Institution?.name} - {selectedProduct?.name}
                        </h3>
                        <div
                            style={{
                                height: '60vh',
                                margin: '8px',
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gap: '20px',
                                justifyItems: 'center',
                            }}
                        >
                            <div>
                                Original CDR Product
                                <JSONInput
                                    id={selectedProduct ? `${selectedProduct.id}a` : 'a'}
                                    placeholder={
                                        fieldToEdit === 'DepositRates'
                                            ? selectedProduct?.depositRates
                                            : selectedProduct?.eligibility
                                    }
                                    viewOnly
                                    style={{
                                        container: {
                                            height: '91%',
                                            maxHeight: '91%',
                                        },
                                    }}
                                />
                            </div>
                            <div>
                                Current Edit
                                <JSONInput
                                    id={selectedProduct ? `${selectedProduct.id}b` : 'b'}
                                    placeholder={updatedValue}
                                    onBlur={(o) => setUpdatedValue(o.jsObject)}
                                    style={{
                                        container: {
                                            height: '91%',
                                            maxHeight: '91%',
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                margin: '16px',
                                display: 'grid',
                                gridTemplateColumns: '6fr 1fr',
                                gap: '20px',
                                justifyItems: 'center',
                            }}
                        >
                            {/* Comments Section */}
                            <div style={{ width: '100%', height: '30vh', overflowY: 'scroll' }}>
                                Comments
                                <TextField
                                    id="filled-full-width"
                                    style={{ width: '100%', margin: 8 }}
                                    placeholder="Please provide a comment before editing."
                                    fullWidth
                                    multiline
                                    rows={3}
                                    onBlur={handleComment}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                />
                                {commentHistory.map((c, i) => (
                                    <div key={c.user + i}>
                                        <div>
                                            <div
                                                style={{
                                                    paddingTop: 4,
                                                    paddingBottom: 4,
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <h4 style={{ fontWeight: 'bold' }}>
                                                    {c.user} - {convertFromUnixTime(c.dateCreated)}
                                                </h4>
                                                {' - '}
                                                {c.updatedValue || c.uneditedValue ? (
                                                    <Button
                                                        onClick={() => {
                                                            setViewCommentData(c)
                                                            setViewModalVisible(true)
                                                        }}
                                                    >
                                                        View Changes
                                                    </Button>
                                                ) : (
                                                    'No saved data found'
                                                )}
                                            </div>
                                            <p style={{ paddingBottom: 8 }}>{c.comment}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Buttons section */}
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'column',
                                }}
                            >
                                <Button onClick={() => handleChange()}>Submit</Button>
                                <Button onClick={() => handleChange(true)}>Approve</Button>
                                <Button onClick={() => setDeleteModalVisible(true)}>Delete</Button>
                                <Button onClick={() => setUpdateModalVisible(false)}>Close</Button>
                            </div>
                        </div>
                    </Paper>
                </div>
            </Modal>
            <Modal open={viewModalVisible} onClose={() => setViewModalVisible(false)}>
                <div
                    style={{
                        display: 'flex',
                        height: '100vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Paper elevation={3}>
                        <h3 style={{ textAlign: 'center' }}>
                            {selectedProduct?.Institution?.name} - {selectedProduct?.name}
                        </h3>
                        <h5 style={{ textAlign: 'center' }}>
                            {convertFromUnixTime(viewCommentData.dateCreated)}
                        </h5>
                        <div
                            style={{
                                height: '60vh',
                                margin: '8px',
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gap: '20px',
                                justifyItems: 'center',
                            }}
                        >
                            <div>
                                Original CDR Product at
                                <JSONInput
                                    id={selectedProduct ? `${selectedProduct.id}a` : 'a'}
                                    placeholder={
                                        'uneditedValue' in viewCommentData &&
                                        viewCommentData.uneditedValue !== ''
                                            ? JSON.parse(viewCommentData.uneditedValue)
                                            : {}
                                    }
                                    viewOnly
                                    style={{
                                        container: {
                                            height: '91%',
                                            maxHeight: '91%',
                                        },
                                    }}
                                />
                            </div>
                            <div>
                                Current Edit
                                <JSONInput
                                    id={selectedProduct ? `${selectedProduct.id}b` : 'b'}
                                    placeholder={
                                        'updatedValue' in viewCommentData &&
                                        viewCommentData?.updatedValue !== ''
                                            ? JSON.parse(viewCommentData.updatedValue)
                                            : {}
                                    }
                                    onBlur={(o) => setUpdatedValue(o.jsObject)}
                                    viewOnly
                                    style={{
                                        container: {
                                            height: '91%',
                                            maxHeight: '91%',
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'flex-start',
                                flexDirection: 'column',
                            }}
                        >
                            <Button onClick={() => setViewModalVisible(false)}>Close</Button>
                        </div>
                    </Paper>
                </div>
            </Modal>
        </div>
    )
}

export default ProductUpdates
