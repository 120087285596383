import * as React from 'react'
import { chakra, HTMLChakraProps, useColorModeValue, useToken } from '@chakra-ui/react'

export const Logo = (props: HTMLChakraProps<'svg'>) => {
    return (
        <chakra.svg
            aria-hidden
            viewBox="0 0 1557.06 283.04"
            fill="white"
            h="6"
            flexShrink={0}
            {...props}
        >
            <rect width="283.04" height="283.04" />
            <path d="M1532.71,111.93l-15.78,50.26c-2.92,9.54-5.26,19.87-7.79,29.41H1508c-2.92-9.93-4.87-19.48-7.79-29.41l-14.81-50.26h-26.1l36.23,101.49c-1.56,14-8.38,20.46-19.48,20.46a27.45,27.45,0,0,1-10.13-1.56v18.9c6.23,1.36,9.74,1.17,15,1.17,19.87,0,30.2-11.69,41.11-42.67l35.06-97.79Z" />
            <path d="M1449.83,129.85c-7-12.47-19.67-20.65-38.37-20.65-30.4,0-49.29,22.4-49.29,52.6,0,32.92,16.75,53.38,49.29,53.38,24.35,0,40.71-10.72,46.16-32.73h-24.74c-3.31,10.32-10.32,15.58-21.23,15.58-16.36,0-24.74-11.88-25.33-29h71.5C1457.82,153.42,1455.87,140.56,1449.83,129.85Zm-63.9,22c1.37-17.14,11.3-25.71,24.36-25.71,12.66,0,22.4,8.57,23.76,25.71Z" />
            <path d="M1314.52,110c-11.69,0-24,4.28-32,15v-13H1261V212.45h23.38V161.6c0-24,8-33.7,22.79-33.7,16.17,0,18.31,13.44,18.31,28.44v56.11h23.38V154.79c0-12.47-1.37-23.19-6-31C1337.51,115.24,1329.33,110,1314.52,110Z" />
            <path d="M1197.47,109.2c-30.59,0-50.07,21.23-50.07,53s19.29,53,49.87,53c31,0,50.46-21.24,50.46-53S1228.25,109.2,1197.47,109.2Zm-.2,88.05c-16.36,0-25.52-13.83-25.52-35.06,0-21.63,9.35-35.07,25.72-35.07s25.91,13.44,25.91,35.07S1213.83,197.25,1197.27,197.25Z" />
            <path d="M1069.88,150.3c-3.7,11.69-7.21,24.36-9.55,35.46H1059c-3.31-10.91-6-22.4-10.52-35.46L1020.59,71H981.83V212.45h22.79V139.39c0-12.85-.78-32.92-1.37-45.38h1.37c2.34,7.79,24.15,69.93,39,118.44h28.25c14.22-48.7,36.43-110.46,38.76-118.44H1112c-.59,14.8-1,31.36-1,45.38v73.06h23V71H1095.2Z" />
            <path d="M434.9,71h-30l-52,141.43h25.52l13-38.18h56.3l13.44,38.18h26.11Zm-36.62,82.79,8.18-22.21c4.87-13.24,8.77-27.08,12.86-40.32h1.17c3.7,13.24,8.37,27.08,12.85,40.32l7.8,22.21Z" />
            <path d="M581,177H557.28c-1.76,13.06-9.16,20.26-20.85,20.26-14.61,0-23.77-14.22-23.77-35.45,0-21,8.77-34.68,23.77-34.68,11.69,0,18.7,7,20.26,19.68h24.16C579.49,124,563.32,109,536.63,109c-29.81,0-48.12,21.81-48.12,53.18,0,31.95,18.12,53.18,48.12,53.18,26.3,0,42.66-14.22,44.41-38.38" />
            <path d="M679.8,180.89v-28c0-27.08-9.16-43.64-41.11-43.64-26.3,0-43.05,12.47-43.83,33.51h24c.2-10.91,7.21-17,19.87-17,12.47,0,18.31,6.62,18.31,19.09v4.29c-25.52,3.11-65.84,3.31-65.84,35.45,0,18.7,12.47,29.81,34.87,29.81,15.78,0,25.32-4.87,33.7-15a115.75,115.75,0,0,0,1,13h21.23A190,190,0,0,1,679.8,180.89ZM657,169.4c0,21.82-10.32,28.83-24.93,28.83-10.91,0-16.76-5.46-16.76-14,0-17.14,24.16-16.56,41.69-19.09Z" />
            <path d="M786.2,177H762.43c-1.75,13.06-9.16,20.26-20.84,20.26-14.61,0-23.77-14.22-23.77-35.45,0-21,8.77-34.68,23.77-34.68,11.68,0,18.7,7,20.26,19.68H786C784.64,124,768.47,109,741.78,109c-29.8,0-48.12,21.81-48.12,53.18,0,31.95,18.12,53.18,48.12,53.18,26.3,0,42.66-14.22,44.42-38.38" />
            <rect x="801.3" y="71.02" width="23.38" height="23.57" />
            <rect x="800.25" y="111.93" width="23.38" height="100.52" />
            <path d="M904.31,165.11v4.29c0,21.82-10.33,28.83-24.94,28.83-10.91,0-16.75-5.46-16.75-14,0-17.14,24.15-16.56,41.69-19.09m3.7,47.34h21.23a190,190,0,0,1-2.14-31.56v-28c0-27.08-9.16-43.64-41.11-43.64-26.29,0-43,12.47-43.83,33.51h24c.2-10.91,7.21-17,19.87-17,12.47,0,18.32,6.62,18.32,19.09v4.29c-25.52,3.11-65.85,3.31-65.85,35.45,0,18.7,12.47,29.81,34.87,29.81,15.78,0,25.33-4.87,33.7-15a115.75,115.75,0,0,0,1,13" />
            <path
                fill="black"
                d="M235.55,224h-21l-24.7-61.17H165.62a64.58,64.58,0,0,0-60.4,41.09L97.29,224h-21l10.75-27.23a84,84,0,0,1,78.53-53.43H182L156.25,79.68h-.94l-1.23,3L143.89,108h0l-.79,2a84.06,84.06,0,0,1-78.28,52.81H35.36l7.87-19.49H64.82A64.66,64.66,0,0,0,125,102.61l20.07-49.7h21.36Z"
            />
        </chakra.svg>
    )
}
