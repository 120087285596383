import gql from 'graphql-tag'

export default gql`
    query getCampaigns {
        getCampaigns {
            ID
            Name
            DateCreated
            DateCompleted
            DateDrafted
            Notes
            Message
            Title
            Description
            Status
            Logic
        }
    }
`
