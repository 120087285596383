import { fromUnixTime } from 'date-fns'

export function tryParseJSON(jsonString) {
    try {
        var o = JSON.parse(jsonString)

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === 'object') {
            return o
        }
    } catch (e) {}

    return false
}

export const convertFromUnixTime = (time) => {
    return fromUnixTime(time).toString().split(' GMT')[0]
}

export function sortAlphabetically<T>(data: Array<T>, key: string): Array<T> {
    return [...data].sort((a, b) => a[key].localeCompare(b[key]))
}
