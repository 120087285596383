import * as React from 'react'
import * as styles from '../../../../common/CommonStyles'
import { subMonths } from 'date-fns'
import EnergyAnalysisTotal from '../../energy/energyAnalysisTotal'

const mo6 = subMonths(new Date(), 6)

export const EnergyAnalysisTotalDashboard = ({ data }) => {
    return (
        <div className={styles.SmallGraphBox}>
            <h1 className={styles.DashHeaderClass}>Energy - Total Engagement</h1>
            <EnergyAnalysisTotal type="dash" period={mo6} data={data} view="total" />
        </div>
    )
}
