import { Box, Flex, FlexProps, HStack, Img, useMenuButton } from '@chakra-ui/react'
import * as React from 'react'
import { HiSelector } from 'react-icons/hi'
import { useAuth0 } from '../auth/react-auth0-wrapper'

export const AccountSwitcherButton = (props: FlexProps) => {
    const buttonProps = useMenuButton(props)
    const { user } = useAuth0()

    return (
        <Flex
            as="button"
            w="full"
            display="flex"
            alignItems="center"
            rounded="lg"
            bg="gray.700"
            px="3"
            py="2"
            fontSize="sm"
            userSelect="none"
            cursor="pointer"
            outline="0"
            transition="all 0.2s"
            _active={{ bg: 'gray.600' }}
            _focus={{ shadow: 'outline' }}
        >
            <HStack flex="1" spacing="3">
                <Img
                    w="8"
                    h="8"
                    rounded="md"
                    objectFit="cover"
                    src={user.picture}
                    alt="Profile Picture"
                />
                <Box textAlign="start">
                    <Box isTruncated fontWeight="semibold">
                        {user.name}
                    </Box>
                    <Box fontSize="xs" color="gray.400">
                        Acacia Staff
                    </Box>
                </Box>
            </HStack>
            <Box fontSize="lg" color="gray.400">
                <HiSelector />
            </Box>
        </Flex>
    )
}
