import b from 'bss'
import * as CommonStyles from 'components/common/CommonStyles'

export const row = b`
    clear:both;
    overflow:auto; 
    margin: 8px 0; 
    background-color: #f4f6f8;
    border: 1px solid #333;
    padding:8px 4px;
`.class

export const subrow = b`
    clear:both;
    overflow:auto; 
    margin: 2px 0; 
    background-color: #fff;
    border: 1px solid #333;
    padding:8px 4px;
`.class

export const header = b`
    font-size: 30px;
    font-weight:bold;
`.class

export const subheader = b`
    font-size: 20px;
`.class

export const leftInfo = b`
    float:left;
`.class

export const rightInfo = b`
    float:left;
    margin-top:5px;
    margin-left: 40px;
`.class

export const info = b`
    clear:both;
`.class


export const buttons = b`
   background-color: #f4f6f8;
    border: 1px solid #333;
    padding: 8px 4px;
    margin: 8px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
`.class


export const buttonsTest = b`
   background-color: lightblue;
    border: 1px solid #333;
    padding: 8px 13px;
    margin: 8px 0;
`.class

export const buttonsRun = b`
   background-color: lightgreen;
    border: 1px solid #333;
    padding: 8px 13px;
    margin: 8px 0;
`.class


export const buttonsUser = b`
   background-color: lightgrey;
    border: 1px solid #333;
    padding: 8px 13px;
    margin: 8px 0;
`.class