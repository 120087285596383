import { useMutation, useQuery } from '@apollo/client'
import { Button } from '@material-ui/core'

import * as React from 'react'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../common/CommonStyles'
import { tableIcons } from 'components/common/MaterialIcons'
import switchUsers from 'graphql/queries/switchUsers'
import MaterialTable, { Icons } from 'material-table'
import switchUser from 'graphql/mutations/switchUser'

const Switches = () => {
    const { loading, error, data, refetch } = useQuery(switchUsers, { fetchPolicy: 'no-cache' })
    const [switchMutation, _] = useMutation(switchUser, {
        fetchPolicy: 'no-cache',
        onError: (msg) => alert('THIS CALL DIDNT WORK - REFRESH AND TRY AGAIN'),
    })
    if (loading) return <div>Loading</div>
    if (error) return <div>failure {console.error(error)} </div>
    let thisdata
    if (data.switchUsers)
        thisdata = data.switchUsers.map((a) => {
            return {
                ID: a.ID,
                Type: a.Type,
                UserID: a.UserID,
                Email: a.Email,
                Date: a.Date,
                Status: a.Status,
                Description: a.Description,
                Provider: a.Provider,
                History: a.History,
                Action: a.Action ? a.Action + ' @ ' + a.ActionDate : null,
            }
        })
    else {
        thisdata = []
    }

    const mutate = async (UserID: string, ID: string, action: string) => {
        const payload: any = {
            AdminUserID: UserID,
            ID: ID,
            Action: action,
        }
        if (action == 'status' || action == 'history') {
            payload.MetaData = prompt('Input plz')
        }
        await switchMutation({
            variables: payload,
        })
        refetch()
    }

    return (
        <div>
            <div>
                <BiIcons.BiWindow size={35} style={{ display: 'inline-block' }} />
                <h1
                    style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                    className={CommonStyles.MainHeaderClass}
                >
                    Switches
                </h1>
            </div>
            <MaterialTable
                icons={tableIcons as Icons}
                title={''}
                columns={[
                    { title: 'Type', field: 'Type' },
                    {
                        title: 'Email',
                        field: 'Email',
                        render: (rowData) => {
                            return (
                                <div
                                    style={{ pointerEvents: 'stroke', cursor: 'pointer' }}
                                    onClick={() =>
                                        window.open(`/users/${rowData.UserID}`, '_blank')
                                    }
                                >
                                    {rowData.Email}
                                </div>
                            )
                        },
                    },
                    { title: 'DateCreated', field: 'Date', defaultSort: 'desc' },
                    { title: 'Status', field: 'Status' },
                    { title: 'Provider', field: 'Provider' },
                    { title: 'Next Action', field: 'Action' },
                    {
                        title: '',
                        render: (a) => {
                            return (
                                <div>
                                    {a.Status != 'Closed' && (
                                        <Button onClick={() => mutate(a.UserID, a.ID, 'close')}>
                                            Close
                                        </Button>
                                    )}
                                    {a.Status != 'Closed' && (
                                        <Button onClick={() => mutate(a.UserID, a.ID, 'status')}>
                                            Update Status
                                        </Button>
                                    )}
                                    {a.Action && a.Action != '' && (
                                        <Button onClick={() => mutate(a.UserID, a.ID, 'action')}>
                                            Action Now
                                        </Button>
                                    )}
                                    <Button onClick={() => mutate(a.UserID, a.ID, 'history')}>
                                        Add History
                                    </Button>
                                </div>
                            )
                        },
                    },
                    { title: '', field: 'UserID', render: () => null },
                    { title: '', field: 'ID', render: () => null },
                    { title: '', field: 'History', render: () => null },
                ]}
                data={thisdata}
                options={{
                    showTitle: false,
                    sorting: true,
                    filtering: true,
                    search: true,
                    pageSize: 50,
                }}
                // onRowClick={(event, rowData: any) => {
                //     window.open(`/users/${rowData.UserID}`, '_blank')
                // }}
                detailPanel={(rowData) => {
                    if (!rowData.History) return <></>
                    return (
                        <MaterialTable
                            columns={[
                                { title: 'Date', field: 'Date', defaultSort: 'desc' },
                                { title: 'Content', field: 'Content' },
                                { title: 'UserID', field: 'UserID' },
                            ]}
                            options={{
                                showTitle: false,
                                sorting: false,
                                paging: false,
                                filtering: false,
                                search: false,
                                toolbar: false,
                            }}
                            data={rowData.History}
                        />
                    )
                }}
            />
        </div>
    )
}

export default Switches
