import * as React from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import users from 'graphql/queries/users'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../common/CommonStyles'
import NgoLandingContent from './content'
import ngoAnalysis from 'graphql/queries/ngoAnalysis'
import { MenuItem, Select } from '@material-ui/core'
import userGroups from 'graphql/queries/userGroups'
import { AuthContext } from 'App'

const NgoAdminLanding = () => {
    const auth = React.useContext(AuthContext)
    const name = auth.token!['http://acacia.com/orgadmin'].org
    const result = useQuery(ngoAnalysis, {
        variables: {
            NgoCode: name,
        },
    })
    if (result.loading) return <div>Loading</div>
    if (result.error) return <div>{result.error}</div>

    return (
        <div>
            <BiIcons.BiUserCircle size={35} style={{ display: 'inline-block' }} />
            <h1
                style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                className={CommonStyles.MainHeaderClass}
            >
                Impact Partner Desktop
            </h1>
            <NgoLandingContent ngoName={name} data={result.data.ngoAnalysis} />
        </div>
    )
}

export default NgoAdminLanding
