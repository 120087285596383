import * as React from 'react'
import { ResponsiveLine } from '@nivo/line'
import { formatLineGraphTotalsAsPercentage } from './formatGraph'
import * as styles from '../../styles'
import * as CommonStyles from '../../../common/CommonStyles'
import { Tooltip } from '@material-ui/core'
import { format } from 'date-fns'

const ConnectionPercentage = ({ data, period, view }) => {
    const graphData = formatLineGraphTotalsAsPercentage(data, 'ConnectionCount', view)
    const pointSize = period === '1 Month' || period === '3 Months' || period === '6 Months' ? 6 : 3
    const tickRotation = period === '1 Month' || period === '3 Months' ? 0 : 32
    const tickPadding = period === '1 Month' || period === '3 Months' ? 8 : 4
    const tooltip = '% of Total Users that have linked at least 1 account successfully'
    return (
        <div className={styles.container}>
            <div className={styles.padLeft}>
                <Tooltip
                    title={<p style={{ fontSize: 14, margin: 8, lineHeight: 1.2 }}>{tooltip}</p>}
                    placement="right"
                    arrow
                >
                    <h2 className={CommonStyles.MainSubHeaderClass}>Bank Links</h2>
                </Tooltip>
            </div>
            <ResponsiveLine
                data={graphData}
                tooltip={(slice) => {
                    return (
                        <div
                            style={{
                                borderWidth: 1,
                                borderColor: 'rgba(0, 0, 0, 0.4)',
                                borderRadius: '8px',
                                backgroundColor: 'white',
                                padding: '5px 10px',
                                display: 'grid',
                            }}
                        >
                            <span
                                style={{
                                    color: 'rgba(0,0,0,0.85)',
                                }}
                            >
                                {format(Number(slice.point.data.x), 'PPPP')}
                                {/* {formatDate(new Date(slice.id), "dd MMMM yyyy").toUpperCase()} */}
                            </span>
                            <div
                                key={slice.point.id}
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: '10px 130px 45px',
                                    alignItems: 'center',
                                    columnGap: 4,
                                }}
                            >
                                <svg style={{ height: '10px', width: '10px' }} viewBox="0 0 10 10">
                                    <circle cx="50%" cy="50%" r="4" fill={`${slice.point.color}`} />
                                </svg>
                                {Number(slice.point.data.y).toFixed(2)}% of Users
                                {/* {formatDate(new Date(slice.id), "dd MMMM yyyy").toUpperCase()} */}
                            </div>
                        </div>
                    )
                }}
                margin={{ top: 50, right: 50, bottom: 80, left: 60 }}
                enablePoints={false}
                pointSize={pointSize}
                xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day' }}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: false,
                    // reverse: false,
                }}
                axisLeft={{
                    legend: 'Users (%)',
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}
                useMesh
                axisBottom={{
                    tickValues: 16,
                    format: (a) => format(a, 'PP'),
                    tickRotation,
                    tickPadding,
                }}
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 70,
                        itemWidth: 197,
                        itemHeight: 25,
                        itemsSpacing: 7,
                        symbolSize: 20,
                        symbolShape: 'circle',
                        itemDirection: 'left-to-right',
                        itemTextColor: '#777',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    )
}

export default ConnectionPercentage
