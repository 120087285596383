import { Datum, Serie } from '@nivo/line'

export type LendingResultDate = {
    Date: string
    UserCount: UserSplitCount
    SuperEngagementCount: UserSplitCount
}

type UserSplitCount = {
    Total: string
    GroupSplit: [UserGroup]
}

type UserGroup = {
    Name: string
    Count: string
}
const formatDate = (dateStr: string): Date => {
    return new Date(dateStr)
}

export const formatLineGraphCount = (
    data: LendingResultDate[],
    field: string,
    view: string,
): Serie[] => {
    if (view == 'total') {
        const percentage: Datum[] = []
        data.forEach((d) => {
            percentage.push({
                x: formatDate(d.Date),
                y: Number(d[field].Total),
            })
        })
        return [
            {
                id: field,
                data: percentage,
            },
        ]
    } else {
        const results: Serie[] = []
        data.forEach((d) => {
            ;(d[field].GroupSplit as [UserGroup]).forEach((group) => {
                const seriesForThisGroup = results.filter((a) => a.id == group.Name)
                if (seriesForThisGroup.length) {
                    seriesForThisGroup[0].data.push({
                        x: formatDate(d.Date),
                        y: Number(group.Count),
                    })
                } else {
                    results.push({
                        data: [
                            {
                                x: formatDate(d.Date),
                                y: Number(group.Count),
                            },
                        ],
                        id: group.Name,
                    })
                }
            })
        })
        return results
    }
}
