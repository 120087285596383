import * as React from 'react'
import MaterialTable, { Icons } from 'material-table'
import { tableIcons } from 'components/common/MaterialIcons'
import { useQuery } from '@apollo/client'
import getRegos from 'graphql/queries/regos'

const Regos = () => {
    const { loading, error, data } = useQuery(getRegos)
    if (loading || error) return <div></div>

    let userData = data.regos.items.map((a) => {
        return {
            email: a.email,
            Group: a.group,
            timestamp: a.timestamp,
        }
    })
    return (
        <MaterialTable
            icons={tableIcons as Icons}
            title="Users"
            columns={[
                { title: 'Email', field: 'email' },
                { title: 'Group', field: 'Group' },
                {
                    title: 'Time',
                    field: 'timestamp',
                    render: (rowData) => {
                        if (rowData.timestamp)
                            return new Date(Number(rowData.timestamp)).toUTCString()
                        return '-'
                    },
                },
            ]}
            data={userData}
            options={{
                sorting: true,
                filtering: true,
                search: true,
                pageSize: 50,
            }}
        />
    )
}

export default Regos
