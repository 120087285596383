import * as React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import CdrHome from './home'
import * as styles from './styles'
import Inst from './institutions'
import Pending from './pendingAccounts'
import Ignored from './ignoredProducts'
import Maps from './productMappings'
import Updates from './productUpdates'
import New from './new'
import AccountTypes from './accountTypes'
import FailedAccounts from './failedAccounts'
import FailingInstitutions from './failngInstitutions'
const Analysis = () => {
    return (
        <div>
            {/* <div>
                <div className={styles.links}>
                    <Link to={`/cdr`} className={styles.link}>
                        Home
                    </Link>
                    <Link to={`/cdr/new`} className={styles.link}>
                        New
                    </Link>
                    <Link to={`/cdr/pending`} className={styles.link}>
                        Pending
                    </Link>
                    <Link to={`/cdr/ignored`} className={styles.link}>
                        Ignored
                    </Link>
                    <Link to={`/cdr/institutions`} className={styles.link}>
                        Institutions
                    </Link>
                    <Link to={`/cdr/updates`} className={styles.link}>
                        Updates
                    </Link>
                    <Link to={`/cdr/maps`} className={styles.link}>
                        Maps
                    </Link>
                </div>
            </div> */}
            <Switch>
                <Route path={`/cdr`} exact component={CdrHome} />
                <Route path={`/cdr/new`} component={New} />
                <Route path={`/cdr/pending`} component={Pending} />
                <Route path={`/cdr/institutions`} exact component={Inst} />
                <Route path={`/cdr/ignored`} component={Ignored} />
                <Route path={`/cdr/accountTypes`} component={AccountTypes} />
                <Route path={`/cdr/updates`} exact component={Updates} />
                <Route path={`/cdr/maps`} exact component={Maps} />
                <Route path={`/cdr/failed`} exact component={FailedAccounts} />
                <Route path={`/cdr/failingInst`} exact component={FailingInstitutions} />
            </Switch>
        </div>
    )
}

export default Analysis
