import { gql } from '@apollo/client'

export default gql`
    mutation adminUserAttribute(
        $UserID: String!
        $AttributeKey: String!
        $AttributeValue: String!
    ) {
        adminUserAttribute(
            UserID: $UserID
            AttributeValue: $AttributeValue
            AttributeKey: $AttributeKey
        )
    }
`
