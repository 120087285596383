import gql from 'graphql-tag'

export default gql`
    query providerAccounts($UserID: String) {
        providerAccounts(UserID: $UserID) {
            ProviderAccountID
            AdditionalStatus
            LastUpdated
            LastUpdateAttempt
            Status
            IsManual
            UserID
            ProviderID
            ProviderName
            CreatedDate
            ConsecutiveFailures
        }
    }
`
