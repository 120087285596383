import gql from 'graphql-tag'

export default gql`
    query merchantTotals {
        merchantTotals {
            MerchantID
            Total
            Count
        }
    }
`
