// import { Serie } from '@nivo/core'
import { SwitchesResult } from 'graphql/types'

export type ConnectionResultDate = {
    Date: string
    UserCount: UserSplitCount
    ConnectionCount: UserSplitCount
}

type UserSplitCount = {
    Total: string
    GroupSplit: [UserGroup]
}

type UserGroup = {
    Name: string
    Count: string
}

const getValueAsPercentage = (users: number, total: string): number => {
    return Math.round((Number(total) / users) * 100)
}

export const formatBarGraph = (data: SwitchesResult[]): any[] => {
    const results: any[] = []
    data.forEach((d) => {
        results.push({
            Date: d.Date!,
            'Savings - Users who have linked a bank':
                Number(d.BankLink?.Total) == 0
                    ? 0
                    : (Number(d.TotalUsers!.Total!), d.BankLink?.Total!),
            'Energy - Users who have uploaded a bill':
                Number(d.EnergyUploads?.Total) == 0
                    ? 0
                    : (Number(d.TotalUsers!.Total!), d.EnergyUploads?.Total!),
            'Super - Users who have personalised super':
                Number(d.SuperPers?.Total) == 0
                    ? 0
                    : (Number(d.TotalUsers!.Total!), d.SuperPers?.Total!),
        })
    })
    return results
}
