import gql from 'graphql-tag'

export default gql`
    query adminInsights {
        adminInsights {
            InsightID
            UserID
            GeneratedAt
            Domain
            Error
            Notes
            BillHash
            BillId
            Status
            Summary {
                ProviderName
            }
        }
    }
`
