import gql from 'graphql-tag'
import AccountFragment from '../fragments/account/account'

export default gql`
    ${AccountFragment}
    query (
        $userID0: String
        $userID1: String
        $userID2: String
        $userID3: String
        $userID4: String
        $userID5: String
        $userID6: String
        $userID7: String
        $userID8: String
        $userID9: String
    ) {
        userID0: accounts(userID: $userID0) {
            items {
                ...AccountFragment
            }
        }
        userID1: accounts(userID: $userID1) {
            items {
                ...AccountFragment
            }
        }
        userID2: accounts(userID: $userID2) {
            items {
                ...AccountFragment
            }
        }
        userID3: accounts(userID: $userID3) {
            items {
                ...AccountFragment
            }
        }
        userID4: accounts(userID: $userID4) {
            items {
                ...AccountFragment
            }
        }
        userID5: accounts(userID: $userID5) {
            items {
                ...AccountFragment
            }
        }
        userID6: accounts(userID: $userID6) {
            items {
                ...AccountFragment
            }
        }
        userID7: accounts(userID: $userID7) {
            items {
                ...AccountFragment
            }
        }
        userID8: accounts(userID: $userID8) {
            items {
                ...AccountFragment
            }
        }
        userID9: accounts(userID: $userID9) {
            items {
                ...AccountFragment
            }
        }
    }
`
