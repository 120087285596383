import * as React from 'react'
import * as styles from '../../../../common/CommonStyles'
import { subMonths } from 'date-fns'
import LendingUserEngagementGraph from './lendingUserEngagementGraph'

const mo6 = subMonths(new Date(), 6)

export const LendingEngagementDashboard = ({ data }) => {
    return (
        <div className={styles.SmallGraphBox}>
            <h3 className={styles.DashHeaderClass}>Lending - Pre-switch Engagement</h3>
            <LendingUserEngagementGraph
                title="total"
                selection="LendingEngagementCount"
                type="dash"
                period={mo6}
                data={data}
                view="total"
            />
        </div>
    )
}
