import { gql } from '@apollo/client'

export default gql`
    fragment InsightFragment on Insight {
        # GeneratedAt
        # InsightID
        # UserID
        Current
        Domain
        # Coins
        # Status
        # TTL
        # LogGroup
        # LogStream
        # ExecID
        # Error
        # BillId
    }
`
