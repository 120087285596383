import { useMutation, useQuery } from '@apollo/client'
import { Button, MenuItem, Select, TextField } from '@material-ui/core'

import * as React from 'react'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../common/CommonStyles'
import users from 'graphql/queries/users'
import sendCustomPush from 'graphql/mutations/sendCustomPush'
import Pushes from './list'

const PushNotifications = () => {
    const [message, setMessage] = React.useState('')
    const [target, setTarget] = React.useState('none')
    const [navigation, setNavigation] = React.useState('')
    const { loading, error, data } = useQuery(users, { fetchPolicy: 'no-cache' })
    const [send] = useMutation(sendCustomPush)
    if (loading) return <div>Loading</div>
    if (error) return <div>failure {console.error(error)} </div>
    if (!data.users || !data.users.items) throw new Error('WTF')
    const u = data.users.items.filter((a) => a.PushToken && a.PushToken != '')
    const submit = () => {
        send({
            variables: {
                userid: target,
                navigation: navigation,
                message: message,
            },
        })
    }
    return (
        <div>
            <div>
                <BiIcons.BiWindow size={35} style={{ display: 'inline-block' }} />
                <h1
                    style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                    className={CommonStyles.MainHeaderClass}
                >
                    Push Notifications
                </h1>
            </div>
            <div>
                <Select
                    value={target}
                    onChange={(event) => setTarget(event.target.value as string)}
                >
                    <MenuItem value={'none'}>Pick User</MenuItem>
                    {u.map((a) => (
                        <MenuItem value={a.user_id}>{a.email}</MenuItem>
                    ))}
                </Select>
            </div>
            <div>
                <label>Navigation</label>
                <TextField onChange={(val) => setNavigation(val.target.value)} value={navigation} />
            </div>
            <div>
                <label>Message</label>
                <TextField onChange={(val) => setMessage(val.target.value)} value={message} />
            </div>
            <div>
                <Button onClick={() => submit()}>Dewd - send the goods</Button>
            </div>
            <Pushes />
        </div>
    )
}

export default PushNotifications
