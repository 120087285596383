import * as React from 'react'
import * as gql from 'graphql/types'
import { container, rowLayout } from './styles'

type Props = {
    insights: gql.Insight[]
}

export const InsightList = ({ insights }: Props) => {
    return (
        <div className={container}>
            {insights.map((a) => (
                <div
                    className={rowLayout}
                    onClick={() => window.open(`/users/${a.UserID}`, '_blank')}
                >
                    <strong>{a.UserID}</strong>
                    <div>{a.Domain}</div>
                    <div>{a.GeneratedAt.substring(0, 19)}</div>
                </div>
            ))}
        </div>
    )
}
