import * as React from 'react'
import { useQuery } from '@apollo/client'
import {
    TableHead,
    Table,
    TableRow,
    TableCell,
    TableBody,
    MenuItem,
    Select,
} from '@material-ui/core'
import * as GraphTypes from 'graphql/types'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../../common/CommonStyles'
import providerAccounts from 'graphql/queries/providerAccounts'

type Obj = {
    fail: number
    success: number
    failureReasons: string[]
}

const FailingInstitutions = () => {
    const { loading, error, data } = useQuery(providerAccounts, { fetchPolicy: 'no-cache' })

    if (loading) return <div>Loading</div>
    if (error) return <div>failure {console.error(error)} </div>
    // const ignored = d.ignoredProducts.items.map((a) => a.productDescription)
    const accounts = (data.providerAccounts as GraphTypes.ProviderAccounts[]).filter(
        (a) =>
            a.AdditionalStatus !== 'INCORRECT_CREDENTIALS' &&
            a.AdditionalStatus !== 'ACCOUNT_LOCKED' &&
            a.AdditionalStatus !== 'USER_ACTION_NEEDED_AT_SITE' &&
            a.AdditionalStatus !== 'ADDL_AUTHENTICATION_REQUIRED' &&
            a.AdditionalStatus !== 'INVALID_ADDL_INFO_PROVIDED'
    )

    var counts: { [key: string]: Obj } = {}
    accounts.forEach((a) => {
        if (!counts[a.ProviderName!]) {
            counts[a.ProviderName!] = {
                fail: 0,
                success: 0,
                failureReasons: [],
            }
        }
        if (a.Status == 'SUCCESS') counts[a.ProviderName!].success++
        else counts[a.ProviderName!].fail++
        if (
            a.AdditionalStatus != 'AVAILABLE_DATA_RETRIEVED' &&
            !counts[a.ProviderName!].failureReasons.includes(a.AdditionalStatus)
        )
            counts[a.ProviderName!].failureReasons.push(a.AdditionalStatus)
    })

    return (
        <div>
            <BiIcons.BiWater size={35} style={{ display: 'inline-block' }} />
            <h1
                style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                className={CommonStyles.MainHeaderClass}
            >
                Failing Institutions
            </h1>
            <p>NOTE: EXCLUDES ACCOUNTS WITH THE FOLLOWING FAILURE REASONS: </p>
            <p>
                INCORRECT_CREDENTIALS / ACCOUNT_LOCKED / USER_ACTION_NEEDED_AT_SITE /
                ADDL_AUTHENTICATION_REQUIRED / INVALID_ADDL_INFO_PROVIDED
            </p>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Institution</TableCell>
                        <TableCell>Total links</TableCell>
                        <TableCell>Success Rate</TableCell>
                        <TableCell>Failure Reasons</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(counts).map((row) => {
                        return (
                            <TableRow key={'key' + row[0]}>
                                <TableCell>{row[0]}</TableCell>
                                <TableCell>{row[1].success + row[1].fail}</TableCell>
                                <TableCell>
                                    {(row[1].success / (row[1].success + row[1].fail)) * 100} %
                                </TableCell>
                                <TableCell>{row[1].failureReasons.join(', ')}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </div>
    )
}

export default FailingInstitutions
