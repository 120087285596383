import gql from 'graphql-tag'
import InsightFragment from '../fragments/insights/insights'

export default gql`
    ${InsightFragment}
    query (
        $userID0: String
        $userID1: String
        $userID2: String
        $userID3: String
        $userID4: String
        $userID5: String
        $userID6: String
        $userID7: String
        $userID8: String
        $userID9: String
    ) {
        userID0: insights(UserID: $userID0, All: false) {
            ...InsightFragment
        }
        userID1: insights(UserID: $userID1, All: false) {
            ...InsightFragment
        }
        userID2: insights(UserID: $userID2, All: false) {
            ...InsightFragment
        }
        userID3: insights(UserID: $userID3, All: false) {
            ...InsightFragment
        }
        userID4: insights(UserID: $userID4, All: false) {
            ...InsightFragment
        }
        userID5: insights(UserID: $userID5, All: false) {
            ...InsightFragment
        }
        userID6: insights(UserID: $userID6, All: false) {
            ...InsightFragment
        }
        userID7: insights(UserID: $userID7, All: false) {
            ...InsightFragment
        }
        userID8: insights(UserID: $userID8, All: false) {
            ...InsightFragment
        }
        userID9: insights(UserID: $userID9, All: false) {
            ...InsightFragment
        }
    }
`
