import { useMutation, useQuery } from '@apollo/client'
import { Box, Button, Checkbox, Modal, Popover, TextField } from '@material-ui/core'
import MaterialTable, { Icons } from 'material-table'
import { tableIcons } from 'components/common/MaterialIcons'
import * as React from 'react'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../common/CommonStyles'
import * as styles from './styles'
import userEvent from 'graphql/queries/userEvent'

const Events = () => {
    const { loading, error, data } = useQuery(userEvent, { fetchPolicy: 'no-cache' })
    if (loading) return <div>Loading</div>
    if (error) return <div>failure {console.error(error)} </div>
    if (!data.userEvent) throw new Error('WTF')

    let thisdata = data.userEvent.map((a) => {
        return {
            Type: a.Type,
            //EventID: a.EventID,
            UserID: a.UserID,
            CreatedAt: a.CreatedAt,
            MetaData: a.MetaData,
        }
    })
    return (
        <div>
            <div>
                <BiIcons.BiWindow size={35} style={{ display: 'inline-block' }} />
                <h1
                    style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                    className={CommonStyles.MainHeaderClass}
                >
                    User Events
                </h1>
            </div>
            <div>
                <MaterialTable
                    icons={tableIcons as Icons}
                    title={''}
                    columns={[
                        { title: 'Type', field: 'Type' },
                        { title: 'UserID', field: 'UserID' },
                        { title: 'CreatedAt', field: 'CreatedAt', defaultSort: 'desc' },
                        { title: 'MetaData', field: 'MetaData' },
                    ]}
                    data={thisdata}
                    options={{
                        showTitle: false,
                        sorting: true,
                        filtering: true,
                        search: true,
                        pageSize: 50,
                    }}
                />
            </div>
        </div>
    )
}

export default Events
