import * as React from 'react'
import * as GraphTypes from 'graphql/types'
import * as styles from '../../../../common/CommonStyles'
import Graph from './graph'

type Props = {
    data: GraphTypes.SwitchesResult[]
}

const SwitchesGraph = ({ data }: Props) => {
    return (
        <div className={styles.SmallGraphBox}>
            <h3 className={styles.DashHeaderClass}>
                Pre-Switch Engagement <br />
                (Total Users)
            </h3>
            <Graph data={data} />
        </div>
    )
}

export default SwitchesGraph
