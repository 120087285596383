import * as React from 'react'
import { ResponsiveLine, Serie } from '@nivo/line'
import * as styles from '../../../styles'
import * as CommonStyles from '../../../../common/CommonStyles'
import { Tooltip } from '@material-ui/core'
import { format } from 'date-fns'
import { formatLineGraphCount } from './formatGraph'

const SuperUserEngagementGraph = ({
    title,
    data,
    period,
    view,
    type = 'page',
    selection = 'total',
}) => {
    const engagementCount = formatLineGraphCount(data, selection, view)
    const userCount = formatLineGraphCount(data, 'UserCount', view)
    const consolidatedData: Serie[] = userCount.concat(engagementCount)
    const pointSize = period === '1 Month' || period === '3 Months' || period === '6 Months' ? 6 : 3
    const tickRotation = period === 'All Time' || type === 'dash' ? 32 : 0
    const tickPadding = period === 'All Time' || type === 'dash' ? 4 : 8
    const tooltip = 'Users that have fully personalised their super insight'
    return (
        <div className={styles.container} style={{ height: type === 'dash' ? '500px' : '50vh' }}>
            {type === 'page' && (
                <div className={styles.padLeft}>
                    <Tooltip
                        title={
                            <p style={{ fontSize: 14, margin: 8, lineHeight: 1.2 }}>{tooltip}</p>
                        }
                        placement="right"
                        arrow
                    >
                        <h2 className={CommonStyles.MainSubHeaderClass}>{title}</h2>
                    </Tooltip>
                </div>
            )}
            <ResponsiveLine
                data={consolidatedData}
                tooltip={(slice) => {
                    const isEngagementLine = slice.point.serieId === 'SuperEngagementCount'
                    const listToSearch = isEngagementLine ? userCount : engagementCount
                    const otherDataPoint = listToSearch[0].data.find(
                        (y) => y.x?.toString() === slice.point.data.x.toString(),
                    )
                    const percentage = isEngagementLine
                        ? Number(
                              (Number(slice.point.data.y) / Number(otherDataPoint.y)) * 100,
                          ).toFixed(2)
                        : Number(
                              (Number(otherDataPoint.y) / Number(slice.point.data.y)) * 100,
                          ).toFixed(2)
                    return (
                        <div
                            key={slice.point.serieId + 'container'}
                            style={{
                                borderWidth: 1,
                                borderColor: 'rgba(0, 0, 0, 0.4)',
                                borderRadius: '8px',
                                backgroundColor: 'white',
                                padding: '5px 10px',
                                display: 'grid',
                                ...(type === 'dash'
                                    ? { position: 'absolute', width: '380px', top: 12, left: 12 }
                                    : {}),
                            }}
                        >
                            <span
                                key={slice.point.serieId + 'date'}
                                style={{
                                    color: 'rgba(0,0,0,0.85)',
                                }}
                            >
                                {format(Number(slice.point.data.x), 'PPPP')}
                                {/* {formatDate(new Date(slice.id), "dd MMMM yyyy").toUpperCase()} */}
                            </span>
                            <div
                                key={slice.point.serieId + 'users1'}
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: '10px 190px',
                                    alignItems: 'center',
                                    columnGap: 4,
                                }}
                            >
                                <svg style={{ height: '10px', width: '10px' }} viewBox="0 0 10 10">
                                    <circle cx="50%" cy="50%" r="4" fill={`${slice.point.color}`} />
                                </svg>
                                {isEngagementLine ? 'Personalised Users:' : 'Total Users:'}{' '}
                                {slice.point.data.y}
                            </div>
                            <div
                                key={slice.point.serieId + 'users2'}
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: '10px 190px',
                                    alignItems: 'center',
                                    columnGap: 4,
                                }}
                            >
                                <svg style={{ height: '10px', width: '10px' }} viewBox="0 0 10 10">
                                    {/* <circle cx="50%" cy="50%" r="4" fill={`${color}`} /> */}
                                </svg>
                                {!isEngagementLine ? 'Personalised Users:' : 'Total Users:'}{' '}
                                {otherDataPoint.y}
                            </div>
                            <div
                                key={slice.point.serieId + 'users2'}
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: '10px 240px',
                                    alignItems: 'center',
                                    columnGap: 4,
                                }}
                            >
                                <svg style={{ height: '10px', width: '10px' }} viewBox="0 0 10 10">
                                    <circle
                                        cx="50%"
                                        cy="50%"
                                        r="4"
                                        fill="rgba(47, 194, 91, 0.58)"
                                    />
                                </svg>
                                Personalised Users (%): {percentage}%
                            </div>
                        </div>
                    )
                }}
                margin={{
                    top: type === 'dash' ? 10 : 50,
                    right: type === 'dash' ? 30 : 50,
                    bottom: 80,
                    left: 60,
                }}
                enablePoints={false}
                pointSize={pointSize}
                xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day' }}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: false,
                    // reverse: false,
                }}
                axisLeft={{
                    legend: 'Users',
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}
                useMesh
                axisBottom={{
                    tickValues: 6,
                    format: (a) => format(a, type == 'dash' ? 'MM-yy' : 'PP'),
                    tickRotation,
                    tickPadding,
                }}
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: type === 'dash' ? 40 : 0,
                        translateY: type === 'dash' ? 55 : 70,
                        itemWidth: 197,
                        itemHeight: 25,
                        itemsSpacing: type === 'dash' ? -20 : 7,
                        symbolSize: 20,
                        symbolShape: 'circle',
                        itemDirection: 'left-to-right',
                        itemTextColor: '#777',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    )
}

export default SuperUserEngagementGraph
