import * as React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import users from 'graphql/queries/users'
import userAttributes from 'graphql/queries/userAttributes'
import UserDetailsContent from './UserDetailsContent'
import userGroups from 'graphql/queries/userGroups'

const UserDetails = () => {
    const { user_id } = useParams<any>()
    const { loading, error, data, refetch } = useQuery(users, { variables: { user_id: user_id } })
    const { loading: gloading, error: gerror, data: gdata } = useQuery(userGroups)
    const {
        loading: l,
        error: e,
        data: d,
    } = useQuery(userAttributes, { variables: { user_id: user_id } })
    if (loading || l || gloading) return <div>Loading</div>
    if (error) return <div>{console.error(error)}error</div>
    if (e) return <div>{console.error(e)}error</div>
    if (gerror) return <div>{console.error(gerror)}error</div>
    return (
        <UserDetailsContent
            user={data.users.items[0]}
            userAttributes={d.userAttributes.items}
            groups={gdata.userGroups}
            refetch={refetch}
        ></UserDetailsContent>
    )
}

export default UserDetails
