import React from 'react'
import ReactDOM from 'react-dom'

import { Auth0Provider } from 'components/auth/react-auth0-wrapper'
import authConfig from 'config/auth_config'
import App from './App'
import './index.css'
import { RedirectLoginResult } from '@auth0/auth0-spa-js'

// Auth0 Redirect
const onRedirectCallback = (result: RedirectLoginResult): void => {
    const replaceState = (targetUrl: string) => {
        window.history.replaceState({}, document.title, targetUrl)
    }
    if (result && result.appState && result.appState.targetUrl) {
        replaceState(result.appState.targetUrl)
    } else {
        replaceState(window.location.pathname)
    }
}

const ApolloApp = (App: React.ElementType) => {
    return (
        <Auth0Provider
            domain={authConfig.domain}
            client_id={authConfig.clientId}
            redirect_uri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            returnTo={window.location.origin}
        >
            <App />
        </Auth0Provider>
    )
}

ReactDOM.render(ApolloApp(App), document.getElementById('root'))
