import b from 'bss'
import * as CommonStyles from 'components/common/CommonStyles'

export const container = b`
    margin-top: 28px;
`.class

export const links = b`

`.class

export const link = b`
    padding: 5px 10px; 
    border: 1px solid #333;
    border-radius: 3px; 
    margin-right:5px; 
`.class

export const padLeft = b`
    padding-left: 40px
`.class
