import * as React from 'react'
import { useQuery } from '@apollo/client'
import usersAnalysisDocument from 'graphql/queries/usersOverTime'
import { Grid, Select, Switch, Typography, MenuItem } from '@material-ui/core'
import UserCount from './userCount'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../../../common/CommonStyles'
import { formatRFC3339, isAfter, parseISO, subMonths } from 'date-fns'
import UserSummary from './summary'
import dashboardAnalysis from 'graphql/queries/dashboardAnalysis'

const periods = ['1 Month', '3 Months', '6 Months', '12 Months', 'All Time']
const mo1 = subMonths(new Date(), 1)
const mo3 = subMonths(new Date(), 3)
const mo6 = subMonths(new Date(), 6)
const mo12 = subMonths(new Date(), 12)
const at = new Date('2020-01-01T00:00:00')
const endDate = formatRFC3339(new Date())

export const UsersAnalysis = () => {
    const [period, setPeriod] = React.useState(periods[1])
    const dateToUs =
        period === '1 Month'
            ? mo1
            : period === '3 Months'
            ? mo3
            : period === '6 Months'
            ? mo6
            : period === '12 Months'
            ? mo12
            : at

    const startDate = formatRFC3339(dateToUs)
    const { loading, error, data } = useQuery(usersAnalysisDocument, {
        fetchPolicy: 'cache-first',
        variables: {
            startDate,
            endDate,
        },
    })
    const { loading: daLoading, error: daError, data: daData } = useQuery(dashboardAnalysis)
    const [view, setView] = React.useState('total')
    if (daLoading) return <div>Loading</div>
    if (loading && !data) return <div>Loading</div>
    if (error)
        return (
            <div>
                {console.error(error)}
                {error}
            </div>
        )
    const filteredData = data.usersAnalysis.filter((x) => {
        if (period === '1 Month') return isAfter(parseISO(x.Date), mo1)
        if (period === '3 Month') return isAfter(parseISO(x.Date), mo3)
        if (period === '6 Month') return isAfter(parseISO(x.Date), mo6)
        if (period === '12 Month') return isAfter(parseISO(x.Date), mo12)
        return true
    })
    return (
        <div>
            <div>
                <BiIcons.BiUser size={35} style={{ display: 'inline-block' }} />
                <h1
                    style={{
                        display: 'inline-block',
                        verticalAlign: 'sub',
                        marginLeft: '12px',
                    }}
                    className={CommonStyles.MainHeaderClass}
                >
                    Acacia Users
                </h1>
            </div>
            <div>
                <UserSummary data={daData.dashboardAnalysis.UserAnalysis} />
            </div>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>Total</Grid>
                        <Grid item>
                            <Switch
                                checked={view == 'group'}
                                onChange={() =>
                                    view == 'total' ? setView('group') : setView('total')
                                }
                                name="checkedC"
                            />
                        </Grid>
                        <Grid item>By Group</Grid>
                    </Grid>
                </Typography>
                <div style={{ justifySelf: 'end' }}>
                    <Select
                        labelId="pick-type"
                        id="pick-type"
                        value={period}
                        onChange={(event) => setPeriod(event.target.value as string)}
                    >
                        {periods.map((p) => (
                            <MenuItem value={p}>{p}</MenuItem>
                        ))}
                    </Select>
                </div>
            </div>
            <div style={{ margin: '0 48px' }}>
                <UserCount data={filteredData} period={period} title="Total Users" view={view} />
                <UserCount
                    data={filteredData}
                    period={period}
                    view={view}
                    title="Total Active Users"
                    type="Active"
                />
            </div>
        </div>
    )
}
