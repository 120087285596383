import { gql } from '@apollo/client'

export default gql`
    mutation deleteProductUpdate($institutionCodeProductID: String!, $updatedKey: String!) {
        deleteProductUpdate(
            institutionCodeProductID: $institutionCodeProductID
            updatedKey: $updatedKey
        )
    }
`
