import * as React from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import users from 'graphql/queries/users'
import { Alert } from '@material-ui/lab'
import { ChakraTable } from 'components/tableWithSearch'
import { Snackbar, capitalize } from '@material-ui/core'
import {
    differenceInMonths,
    differenceInWeeks,
    format,
    formatDistanceToNow,
    isToday,
    isYesterday,
} from 'date-fns'
import { useToast } from '@chakra-ui/react'
import accountsUserTable from 'graphql/queries/accountsUserTable'
import { Account, CarbonResult, Insight } from 'graphql/types'
import { getCustomLogo } from 'utils/logo'
import { prettyInstitutionName } from 'utils/institutions'
import insightsUserTable from 'graphql/queries/insightsUserTable'
import carbonResultsUserTable from 'graphql/queries/carbonResultsUserTable'

function getRelativeDateLabel(date: Date): string {
    if (isToday(date)) {
        return 'Today'
    } else if (isYesterday(date)) {
        return 'Yesterday'
    } else {
        const daysAgo = formatDistanceToNow(date, { addSuffix: true })
        return daysAgo
    }
}

const noFetch = 'N/A'

const Users = () => {
    const { loading: userLoading, error: userError, data: userData } = useQuery(users)

    const [carbonResults, setCarbonResults] = React.useState<{ [user_id: string]: CarbonResult[] }>(
        {}
    )
    const [q3, result3] = useLazyQuery(carbonResultsUserTable, {
        onCompleted: (data) => {
            // clone current account local state
            const final: { [user_id: string]: CarbonResult[] } = { ...carbonResults } // ...spread necessary to force re-render

            // save data to clone, even if user has no results
            const userIDsFetched = result3.variables ?? {}
            Object.keys(userIDsFetched).forEach((userKey) => {
                const userID = userIDsFetched[userKey]
                // prevents saving "N/A"
                if (userID !== noFetch) {
                    final[userID] = data[userKey].slice().filter((x) => x.Current === 'true')
                }
            })
            setCarbonResults(final)
        },
    })

    // Query users on page and save to local state
    const [insights, setInsights] = React.useState<{ [user_id: string]: Insight[] }>({})
    const [q2, result2] = useLazyQuery(insightsUserTable, {
        onCompleted: (data) => {
            // clone current account local state
            const final: { [user_id: string]: Insight[] } = { ...insights } // ...spread necessary to force re-render

            // save data to clone, even if user has no results
            const userIDsFetched = result2.variables ?? {}
            Object.keys(userIDsFetched).forEach((userKey) => {
                const userID = userIDsFetched[userKey]
                // prevents saving "N/A"
                if (userID !== noFetch) {
                    final[userID] = data[userKey]
                }
            })
            setInsights(final)
        },
    })

    const [accounts, setAccounts] = React.useState<{ [user_id: string]: Account[] }>({})
    const [q, result] = useLazyQuery(accountsUserTable, {
        onCompleted: (data) => {
            // clone current account local state
            const final: { [user_id: string]: Account[] } = { ...accounts } // ...spread necessary to force re-render

            // save data to clone, even if user has no results
            const userIDsFetched = result.variables ?? {}
            Object.keys(userIDsFetched).forEach((userKey) => {
                const userID = userIDsFetched[userKey]
                // prevents saving "N/A"
                if (userID !== noFetch) {
                    final[userID] = data[userKey].items.filter(
                        (x) => x.acaciaAccountStatus === 'active'
                    )
                }
            })
            setAccounts(final)
        },
    })
    const [alertVisible, setAlertVisible] = React.useState(false)
    const toast = useToast()
    if (userLoading) return <div>Loading</div>
    if (userError) return <div>{userError}</div>
    const items = userData.users.items ?? []
    const sortedData = items.slice().sort((a, b) => Number(b.lastLogin) - Number(a.lastLogin))

    const fetchOnTableUpdate = (rows) => {
        // noFetch is a hack to prevent fetching all accounts if undefined
        q({
            variables: {
                userID0: rows[0]?.user_id ?? noFetch,
                userID1: rows[1]?.user_id ?? noFetch,
                userID2: rows[2]?.user_id ?? noFetch,
                userID3: rows[3]?.user_id ?? noFetch,
                userID4: rows[4]?.user_id ?? noFetch,
                userID5: rows[5]?.user_id ?? noFetch,
                userID6: rows[6]?.user_id ?? noFetch,
                userID7: rows[7]?.user_id ?? noFetch,
                userID8: rows[8]?.user_id ?? noFetch,
                userID9: rows[9]?.user_id ?? noFetch,
            },
        })

        q2({
            variables: {
                userID0: rows[0]?.user_id ?? noFetch,
                userID1: rows[1]?.user_id ?? noFetch,
                userID2: rows[2]?.user_id ?? noFetch,
                userID3: rows[3]?.user_id ?? noFetch,
                userID4: rows[4]?.user_id ?? noFetch,
                userID5: rows[5]?.user_id ?? noFetch,
                userID6: rows[6]?.user_id ?? noFetch,
                userID7: rows[7]?.user_id ?? noFetch,
                userID8: rows[8]?.user_id ?? noFetch,
                userID9: rows[9]?.user_id ?? noFetch,
            },
        })

        q3({
            variables: {
                userID0: rows[0]?.user_id ?? noFetch,
                userID1: rows[1]?.user_id ?? noFetch,
                userID2: rows[2]?.user_id ?? noFetch,
                userID3: rows[3]?.user_id ?? noFetch,
                userID4: rows[4]?.user_id ?? noFetch,
                userID5: rows[5]?.user_id ?? noFetch,
                userID6: rows[6]?.user_id ?? noFetch,
                userID7: rows[7]?.user_id ?? noFetch,
                userID8: rows[8]?.user_id ?? noFetch,
                userID9: rows[9]?.user_id ?? noFetch,
            },
        })
    }

    return (
        <div>
            {/* <BiIcons.BiUserCircle size={35} style={{display:"inline-block"}} />
            <h1 style={{display:"inline-block", verticalAlign: "sub",  marginLeft: "12px"}} className={CommonStyles.MainHeaderClass}>Users</h1> */}
            <ChakraTable
                data={sortedData}
                asyncFetchOnTableUpdate={fetchOnTableUpdate}
                fieldsToSearch={['email', 'user_id', 'lastLogin', 'InsightStatus']}
                columns={[
                    {
                        key: 'email',
                        type: 'avatar',
                        header: 'Email',
                        calculateProps: (i) => i.user_id,
                    },
                    {
                        header: 'Status',
                        key: 'lastLogin',
                        type: 'status',
                        tooltip: (i) => {
                            const lastLogin = new Date(i.lastLogin * 1000)
                            const joinDate = new Date(i.created * 1000)
                            const isStaff = i.group === 'staff'
                            const isActive = differenceInMonths(lastLogin, new Date()) === 0
                            const isNew = differenceInWeeks(joinDate, new Date()) === 0
                            const isSuperUser = i.loginCount > 10
                            return isStaff
                                ? 'User is a staff member'
                                : isNew
                                ? 'User has joined this week'
                                : isSuperUser && isActive
                                ? 'User has over 10 total logins and logged in within the last 30 days'
                                : isActive
                                ? 'User has logged in during the last 30 days'
                                : isSuperUser
                                ? 'User has over 10 total logins, but has not logged in during the last 30 days'
                                : 'User has not logged in during the past 30 days'
                        },
                        format: (row) => {
                            const lastLogin = new Date(row.lastLogin * 1000)
                            const joinDate = new Date(row.created * 1000)
                            const isStaff = row.group === 'staff'
                            const isActive = differenceInMonths(lastLogin, new Date()) === 0
                            const isNew = differenceInWeeks(joinDate, new Date()) === 0
                            const isSuperUser = row.loginCount > 10
                            return isStaff
                                ? 'Staff'
                                : isNew
                                ? 'New'
                                : isSuperUser && isActive
                                ? 'Super User'
                                : isActive
                                ? 'Active'
                                : isSuperUser
                                ? 'Inactive Super User'
                                : 'Inactive'
                        },
                        calculateProps: (i) => {
                            const lastLogin = new Date(i.lastLogin * 1000)
                            const joinDate = new Date(i.created * 1000)
                            const isStaff = i.group === 'staff'
                            const isActive = differenceInMonths(lastLogin, new Date()) === 0
                            const isNew = differenceInWeeks(joinDate, new Date()) === 0
                            const isSuperUser = i.loginCount > 10
                            return {
                                color: isStaff
                                    ? '#1658CC'
                                    : isNew
                                    ? '#E9218E'
                                    : isSuperUser && isActive
                                    ? 'yellow'
                                    : isActive
                                    ? '#25B16C'
                                    : isSuperUser
                                    ? 'purple'
                                    : '#626C80',
                                text: isStaff
                                    ? 'Staff'
                                    : isNew
                                    ? 'New'
                                    : isSuperUser && isActive
                                    ? 'Super User'
                                    : isActive
                                    ? 'Active'
                                    : isSuperUser
                                    ? 'Inactive Super User'
                                    : 'Inactive',
                            }
                        },
                    },
                    {
                        key: 'accounts',
                        type: 'avatar_group',
                        header: 'Accounts',
                        calculateProps: (i) => {
                            if (i.user_id in accounts) {
                                return accounts[i.user_id].map((a) => {
                                    const type = a.customType || a.class?.type
                                    const name =
                                        (type === 'super_annuation'
                                            ? a.superInvestment?.Name
                                            : a.name) ?? a.businessName
                                    const logo =
                                        type === 'super_annuation'
                                            ? a.superProduct?.ProductName
                                                ? getCustomLogo(
                                                      prettyInstitutionName(
                                                          a.superProduct?.ShortName
                                                      ),
                                                      'square'
                                                  )
                                                : ''
                                            : a.institution?.name
                                            ? getCustomLogo(
                                                  prettyInstitutionName(
                                                      a.institution?.name || a.businessName
                                                  ),
                                                  'square'
                                              )
                                            : ''
                                    return { name, src: logo }
                                })
                            }
                            return []
                        },
                    },
                    {
                        key: 'CarbonResults',
                        type: 'badges',
                        calculateProps: (i) => {
                            if (i.user_id in carbonResults) {
                                const da = carbonResults[i.user_id]
                                    .filter((x) => x.Emissions !== '' && x.Emissions !== '0.000000')
                                    .map((a) => ({
                                        text: `${capitalize(a.Domain)}: ${Number(
                                            a.Emissions
                                        ).toFixed(1)}tCO2e`,
                                        textSeed: capitalize(a.Domain),
                                    }))
                                if (da.length === 0) return [{ text: 'N/A' }]
                                return da
                            }
                            return []
                        },
                        header: 'Carbon Results',
                    },
                    {
                        key: 'InsightStatus',
                        type: 'badges',
                        calculateProps: (i) => {
                            if (i.user_id in insights) {
                                const da = insights[i.user_id].map((a) => ({
                                    text: capitalize(a.Domain),
                                }))
                                return da
                            }
                            return []
                        },
                        header: 'Insights',
                    },
                    {
                        key: 'lastLogin',
                        type: 'double_text',
                        header: 'Last login',
                        tooltip: (row) =>
                            `Last login: ${format(new Date(row.lastLogin * 1000), 'PPPP')}`,
                        calculateProps: (row) => ({
                            title: `${getRelativeDateLabel(new Date(row.lastLogin * 1000))}`,
                            subtitle: `${row.loginCount} logins`,
                        }),
                    },
                    {
                        key: 'user_id',
                        type: 'id_badge',
                        header: 'ID',
                        tooltip: () => 'Click to copy UserID',
                        calculateProps: (i) => ({
                            onClick: () => {
                                toast({
                                    description: `Copied ${i.user_id} to clipboard`,
                                    status: 'info',
                                    duration: 3000,
                                    isClosable: true,
                                })
                                navigator.clipboard.writeText(i.user_id)
                            },
                        }),
                        format: (i) => i.user_id.slice(-4),
                    },
                    {
                        key: 'actions',
                        type: 'actions',
                        header: '',
                        calculateProps: (i) => [
                            {
                                onClick: () => window.open(`/users/${i.user_id}`, '_blank'),
                                icon: 'user',
                            },
                            {
                                onClick: () =>
                                    window.open(
                                        `https://analytics.amplitude.com/acacia/project/331932/search/${i.user_id}`,
                                        '_blank'
                                    ),
                                icon: 'analytics',
                            },
                        ],
                    },
                ]}
                title="All Users"
                badge={{ colorScheme: 'green', text: `${sortedData.length} Total` }}
                subtitle="Keep track of vendor and their security ratings."
                itemsPerPage={10}
            />
            {/* <UserList users={data.users.items} /> */}
            <Snackbar
                open={alertVisible}
                autoHideDuration={3000}
                onClose={() => setAlertVisible(false)}
            >
                <Alert severity="info">Copied UserID to Clipboard</Alert>
            </Snackbar>
        </div>
    )
}

export default Users
