import b from 'bss'

export const BaseFont = 'Inter'
export const NumberFont = 'GT America Mono'

export const BlueText = '#146eb4'
export const GreyText = '#6a7070'
export const LightGreyText = 'rgb(0, 0, 0, 0.5)'
export const Black = 'rgb(0, 0, 0, 0.85)'
export const GreyBorder = 'rgb(0, 0, 0, 0.1)'
export const White100 = 'rgba(255, 255, 255, 1.0)'
export const White50 = 'rgba(255, 255, 255, 0.5)'
export const Green = '#36B37E'

export const ExtraExtraSmallSize = '10px'
export const ExtraSmallSize = '12px'
export const SmallSize = '14px'
export const MediumSize = '16px'
export const LargeSize = '18px'
export const ExtraExtraExtraLargeSize = '40px'

export const LightWeight = '300'
export const NormalWeight = '400'
export const MediumWeight = '500'

export const gradient1 = 'linear-gradient(#EAE4B2, #BAD4BB);'
export const gradient2 = 'linear-gradient(#D6DFF1, #F4DAD1);'

export const PositiveRateClass = b`
    color: green
`.class
export const NegativeRateClass = b`
    color: red
`.class

export const CallToActionButtonClass = b`
    color: #fff;
    background-color: #007791;
    border: 1px solid transparent;  
    display: inline-block;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    white-space: nowrap;
    padding: 16px 12px;
    font-size: 15px;
    line-height: 1.35135;
    border-radius: 2px;
`.class

export const StandardCardClass = b`
`.class

export const SmallGraphBox = b`
    border:1px solid #ddd;
    border-radius: 7px;
    margin: 10px 7px;
    display: grid;
`.class

export const MainHeaderClass = b`
    color: #333; 
    font-family: 'Raleway',sans-serif; 
    font-size: 32px; 
    font-weight: 800; 
    line-height: 20px; 
    margin: 0 0 24px; 
    text-transform: uppercase; 
`.class

export const MainSubHeaderClass = b`
    color: #333; 
    font-family: 'Raleway',sans-serif; 
    font-size: 20px; 
    font-weight: 600; 
    line-height: 20px; 
    margin: 0 0 5px; 
    text-transform: uppercase; 
    width: fit-content;
`.class

export const DashHeaderClass = b`
    color: #333; 
    font-family: 'Raleway',sans-serif; 
    font-size: 24px; 
    font-weight: 800; 
    line-height: 24px; 
    margin: 20px 0 8px 20px; 
    text-transform: uppercase; 
`.class

export const DashGraphContainer = b`
height: 500px;
padding-left: 20px;
align-self: end;
`.class
