import * as React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import Ignored from 'graphql/queries/ignoredProducts'
import {
    TableHead,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Select,
    MenuItem,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import deleteIgnore from 'graphql/mutations/deleteIgnore'
import mappingType from 'graphql/mutations/mappingType'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../../common/CommonStyles'

const IgnoredProducts = () => {
    const [deleteIgnore1] = useMutation(deleteIgnore)
    const [mappingType1] = useMutation(mappingType)
    const { loading, error, data, refetch } = useQuery(Ignored, { fetchPolicy: 'no-cache' })
    if (loading) return <div>Loading</div>
    if (error) return <div>failure {console.error(error)} </div>
    const goDelete = async (id) => {
        await deleteIgnore1({ variables: { ProductID: id } })
        refetch()
    }
    const handleChange = async (ProductDescription, AccountType) => {
        await mappingType1({
            variables: {
                institutionCode: '',
                basiqProductName: '',
                productDescription: ProductDescription,
                accountType: AccountType,
            },
        })
        refetch()
    }
    return (
        <div>
            <div>
                <BiIcons.BiToggleLeft size={35} style={{ display: 'inline-block' }} />
                <h1
                    style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                    className={CommonStyles.MainHeaderClass}
                >
                    Ignored Products
                </h1>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Product Name</TableCell>
                        {/* <TableCell>ignoreMapping</TableCell> */}
                        <TableCell>UserID</TableCell>
                        <TableCell>Time</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.ignoredProducts.items.map((row) => {
                        return (
                            <TableRow>
                                <TableCell>{row.productDescription}</TableCell>
                                <TableCell>{row.userID}</TableCell>
                                <TableCell>
                                    {new Date(Number(row.timestamp)).toUTCString()}
                                </TableCell>
                                <TableCell>
                                    <Select
                                        labelId="pick-type"
                                        id="pick-type"
                                        value={row.accountType}
                                        onChange={(event) =>
                                            handleChange(row.productDescription, event.target.value)
                                        }
                                    >
                                        <MenuItem value="savings">Savings</MenuItem>
                                        <MenuItem value="transaction">Transaction</MenuItem>
                                        <MenuItem value="super">Super</MenuItem>
                                        <MenuItem value="mortgage">Mortgage</MenuItem>
                                        <MenuItem value="credit">Credit Card</MenuItem>
                                        <MenuItem value="investment">Investment</MenuItem>
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => goDelete(row.productDescription)}>
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </div>
    )
}

export default IgnoredProducts
