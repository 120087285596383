import { gql } from '@apollo/client'

export default gql`
    mutation switchUser(
        $Type: String
        $MetaData: String
        $InsightID: String
        $AdminUserID: String
        $Action: String
        $ID: String
    ) {
        switchUser(
            Type: $Type
            MetaData: $MetaData
            InsightID: $InsightID
            AdminUserID: $AdminUserID
            Action: $Action
            ID: $ID
        )
    }
`
