import gql from 'graphql-tag'

export default gql`
    query partners {
        partners {
            ID
            Name
            Logo
            PartnerID
            AnalysisCache {
                Type       
                GeneratedAt
                Data         
            }
            Sheet
        }
    }
`
