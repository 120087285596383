import gql from 'graphql-tag'

export default gql`
    query savingsAnalysis($startDate: String, $endDate: String) {
        savingsAnalysis(startDate: $startDate, endDate: $endDate) {
            Date
            UserCount {
                Total
                GroupSplit {
                    Name
                    Count
                }
            }
            ConnectionCount {
                Total
                GroupSplit {
                    Name
                    Count
                }
            }
        }
    }
`
